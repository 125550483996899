import _ from "lodash";
import FilterMenuListItem from "common/ui-elements/components/sbFilterMenuList/model/filter_menu_list_item.class";
import moment from "moment";

export default function DashboardCtrl(
    $q,
    Analytics,
    dashboardService,
    $sbCurrentProject,
    $sbComponent,
    $window,
    $sbErrorPresenter,
    $log,
    $state,
    $sbTemplate,
    $stateParams,
    $sbSiteInspections,
    $sbInspectionsApi,
    $rootScope,
    $sbRequestSentry,
    SABLONO_STATES,
    $sbFilterMenu,
    $sbTracking,
    $mdDialog,
    $sbDashboardSelection
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    // schedule chart
    var _unfilteredTemplateOptions;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////
    //

    vm.project;
    // init the data models for the dashboard
    vm.activities = null;
    vm.tilesData = null;

    vm.isLoadingTemplateStats = true;
    vm.isLoadingTilesData = true;
    vm.isLoadingSCurveChart = true;
    vm.isLoadingMenu = true;
    vm.teams = [];

    // scurve chart
    vm.sCurveSelection = [];

    vm.ALL_FILTER_ITEMS_SELECTION = null;

    /**
     * Options to customize appearance of project-level bar chart.
     */
    vm.projectBarChartOptions = {
        legend: { display: false },
        scales: {
            yAxis: { displayLabels: false },
        },
        i18n: {
            xScaleLabel: "_ACTIVITY_NUMBER",
        },
    };

    vm.rightBarChartOptions = {
        i18n: {},
    };

    vm.hasData = hasData;
    vm.onProcessTemplateChanged = onProcessTemplateChanged;
    vm.onDeliverableTypeChanged = onDeliverableTypeChanged;
    vm.onSCurveTemplateChange = selectScurveActivities;
    vm.selectStructure = selectStructure;
    vm.onDeliverableTileCardClick = onDeliverableTileCardClick;
    vm.onDeliverableBarChartClick = onDeliverableBarChartClick;
    vm.selectTeam = selectTeam;
    vm.onDeselectAll = onDeselectAll;
    vm.isBarChartNavigationDisabled = isBarChartNavigationDisabled;

    // A little deceiving since there are 3 filters looking as 1!
    vm.filterMenu = {
        // variables
        isAreaManagerFilterActive: false,
        selectedStructure: vm.ALL_FILTER_ITEMS_SELECTION,
        structures: [],
        structuresComparisonPath: "ID",
        selectedTeam: vm.ALL_FILTER_ITEMS_SELECTION,
        teams: [],
        teamsComparisonPath: "id",
        selectedProcessTemplate: vm.ALL_FILTER_ITEMS_SELECTION,
        processTemplates: [],
        processTemplatesComparisonPath: "id",
        selectedDeliverableType: vm.ALL_FILTER_ITEMS_SELECTION,
        deliverableTypes: [],
        deliverableTypesComparisonPath: "name",

        // functions
        onToggleAreaManagerFilter: onToggleAreaManagerFilter,
        onInitialToggleAreaManagerFilter: onInitialToggleAreaManagerFilter,
        setSelectedStructure: (newValue) => {
            vm.filterMenu.selectedStructure = newValue;
            saveFiltersLatestSelection();
            updateUrlBasedOnSelectedFilters();
        },
        setSelectedTeam: (newValue) => {
            vm.filterMenu.selectedTeam = newValue;
            saveFiltersLatestSelection();
            vm.templateOptions = _getFilteredTemplateOptions();
            updateUrlBasedOnSelectedFilters();
        },
        setSelectedProcessTemplate: (newValue) => {
            vm.filterMenu.selectedProcessTemplate = newValue;
            saveFiltersLatestSelection();
            vm.templateOptions = _getFilteredTemplateOptions();
            updateUrlBasedOnSelectedFilters();
        },
        setSelectedDeliverableType: (newValue) => {
            vm.filterMenu.selectedDeliverableType = newValue;
            saveFiltersLatestSelection();
            updateUrlBasedOnSelectedFilters();
        },
    };

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    $rootScope.$on("$stateChangeStart", function () {
        $sbRequestSentry.abortAllAndClean();
    });

    $rootScope.$on("$stateChangeSuccess", function (event, toState, toParams) {
        if (toState.name === SABLONO_STATES.dashboard) {
            if (toParams.clear) {
                $sbDashboardSelection.clearAll();
            }
        }
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    activate();

    function activate() {
        vm.initialDataFetch = true;
        vm.isLoadingTemplateStats = true;
        vm.isLoadingTilesData = true;
        vm.isLoadingSCurveChart = true;
        vm.isLoadingMenu = true;

        vm.project = $sbCurrentProject.get();
        vm.sCurveSelection =
            $sbDashboardSelection.getLastSelectedActivityTemplates();

        return $sbFilterMenu
            .fetchTeamsAndStructures()
            .then(function (lists) {
                vm.teams = lists.teams;
                vm.filterMenu.teams = lists.teams.map((team) => {
                    return new FilterMenuListItem(team.getDisplayName(), team);
                });

                vm.filterMenu.structures = lists.structures.map((structure) => {
                    return new FilterMenuListItem(
                        structure.PARENT_PATH.join("/"),
                        structure
                    );
                });

                vm.filterMenu.processTemplates = lists.processTemplates.map(
                    (template) => {
                        return new FilterMenuListItem(template.name, template);
                    }
                );

                vm.filterMenu.deliverableTypes = lists.deliverableTypes.map(
                    (type) => {
                        return new FilterMenuListItem(type.name, type);
                    }
                );

                vm.isLoadingMenu = false;
            })
            .then(() => {
                const urlParams = _.pick($stateParams, [
                    "structureId",
                    "teamId",
                    "templateId",
                    "deliverableType",
                ]);
                if (_.values(urlParams).every(_.isNil)) {
                    assignFiltersLatestSelectionToFilterMenu();
                    updateUrlBasedOnSelectedFilters();
                } else {
                    setFiltersBasedOnUrlProperties();
                    saveFiltersLatestSelection();
                }
            })
            .then(() => {
                _reloadCharts();
            });
    }

    function loadBarChartContent(template, deliverableType, isReload = false) {
        vm.isLoadingTemplateStats = true;
        return dashboardService
            .fetchScheduleStats(
                $stateParams.projectId,
                {
                    templateId: _.get(template, "id"),
                    typeName: _.get(deliverableType, "name"),
                },
                _getQueryParams()
            )
            .then((stats) => {
                vm.isReload = isReload;
                const hasTemplateSelected =
                    template !== vm.ALL_FILTER_ITEMS_SELECTION;
                const hasExactlyOneEntryInStats = stats && stats.length === 1;

                if (!hasTemplateSelected && hasExactlyOneEntryInStats) {
                    return loadBarChartContent(stats[0], deliverableType, true);
                }

                vm.rightBarChartOptions.i18n.xScaleLabel =
                    !hasTemplateSelected && !vm.isReload
                        ? "_ACTIVITY_NUMBER"
                        : "_DELIVERABLE_NUMBER";

                return (vm.templateStats = stats.map((stats) => {
                    stats.contentLoadedForId = _.get(template, "id");
                    stats.total = undefined;
                    return stats;
                }));
            })
            .catch((err) => {
                vm.templateStats = [];
                $sbErrorPresenter.catch(err);
            })
            .finally(() => (vm.isLoadingTemplateStats = false));
    }

    function _fetchProjectData() {
        vm.isLoadingTilesData = true;
        // fetch data for deliverable tiles
        return dashboardService
            .fetchDeliverableProgressStats(
                _getProjectId(vm.project),
                {
                    templateId: _.get(_getSelectedProcessTemplate(), "id"),
                    typeName: _.get(_getSelectedDeliverableType(), "name"),
                },
                _getQueryParams()
            )
            .then(function (tilesData) {
                vm.tilesData = tilesData;
            })
            .catch($sbErrorPresenter.catch)
            .finally(function () {
                vm.isLoadingTilesData = false;
            });
    }

    function _fetchTemplateStageSuggestions() {
        var excludeGroups = true;
        vm.isLoadingSCurveChart = true;
        return $sbTemplate
            .getTemplatesStageSuggestions(excludeGroups, _getQueryParams())
            .then(function (suggestions) {
                if (suggestions && suggestions[0]) {
                    _unfilteredTemplateOptions = suggestions;
                }
            })
            .then(() => {
                vm.templateOptions = _getFilteredTemplateOptions();
                return applyChangeOfOptionsToSCurve(
                    vm.templateOptions,
                    vm.sCurveSelection
                );
            })
            .finally(() => (vm.isLoadingSCurveChart = false));
    }

    function onDeliverableTypeChanged(deliverableType) {
        if (
            !isValidFilterSelection(
                deliverableType,
                vm.filterMenu.selectedDeliverableType,
                "name"
            )
        ) {
            return;
        }
        $sbTracking.filterMenu.byDeliverableType("Dashboard");

        vm.filterMenu.setSelectedDeliverableType(deliverableType);

        return $q.all([
            applyChangeOfOptionsToSCurve(
                vm.templateOptions,
                vm.sCurveSelection
            ),
            loadBarChartContent(
                _getSelectedProcessTemplate(),
                _getSelectedDeliverableType()
            ),
            _loadProjectBarChartContent(),
            _fetchProjectData(),
        ]);
    }

    function onProcessTemplateChanged(template) {
        if (
            !isValidFilterSelection(
                template,
                vm.filterMenu.selectedProcessTemplate,
                "id"
            )
        ) {
            return;
        }
        $sbTracking.filterMenu.byProcessTemplate("Dashboard");

        vm.filterMenu.setSelectedProcessTemplate(template);

        return $q.all([
            applyChangeOfOptionsToSCurve(
                vm.templateOptions,
                vm.sCurveSelection
            ),
            loadBarChartContent(
                _getSelectedProcessTemplate(),
                _getSelectedDeliverableType()
            ),
            _loadProjectBarChartContent(),
            _fetchProjectData(),
        ]);
    }

    function onSCurveSelectionChange(selected) {
        vm.isLoadingSCurveChart = true;
        vm.sCurveSelection = selected;

        if (selected) {
            try {
                $sbDashboardSelection.setLastSelectedActivityTemplates(
                    selected
                );
            } catch (err) {
                $log.error(
                    "Last activity template selection could not be saved!",
                    err
                );
            }
            return applyChangeOfOptionsToSCurve(
                vm.templateOptions,
                vm.sCurveSelection
            );
        }
        return $q.resolve(false);
    }

    function hasData() {
        const isDataAvailable =
            _.get(vm, "filterMenu.processTemplates.length") !== 0;

        if (!isDataAvailable) {
            const projectSetupEl = document.getElementById("projectSetup");
            projectSetupEl.projectId = vm.project.id;
        }

        return isDataAvailable;
    }

    function _fetchSCurveChartData(activityTemplates) {
        if (!Array.isArray(activityTemplates)) {
            return Promise.resolve({
                labels: [],
                planned: [],
                finished: [],
                confirmed: [],
            });
        }

        // whole project case + no activity filter + selected team | selected process template
        if (activityTemplates.length === 0) {
            return dashboardService.fetchScurveData(
                $stateParams.projectId,
                {
                    byProcessTemplate: _.get(
                        _getSelectedProcessTemplate(),
                        "id"
                    ),
                    byDeliverableType: _.get(
                        _getSelectedDeliverableType(),
                        "name"
                    ),
                },
                _getQueryParams(),
                []
            );
        }
        // direct activity selection
        const templateIds = activityTemplates.map(({ id }) => id);
        return dashboardService.fetchScurveData(
            $stateParams.projectId,
            {
                byProcessTemplate: _.get(_getSelectedProcessTemplate(), "id"),
                byDeliverableType: _.get(_getSelectedDeliverableType(), "name"),
            },
            _getQueryParams(),
            templateIds
        );
    }

    function _getProjectId(projectObject) {
        return projectObject.id;
    }

    function _getSelectedProcessTemplate() {
        return vm.filterMenu.selectedProcessTemplate;
    }

    function _getSelectedDeliverableType() {
        return vm.filterMenu.selectedDeliverableType;
    }

    function _getSelectedStructure() {
        return vm.filterMenu.selectedStructure;
    }

    function onToggleAreaManagerFilter({ isActive }) {
        setAreaManagerFilter(isActive);
        _reloadCharts();
    }

    function onInitialToggleAreaManagerFilter({ isActive }) {
        setAreaManagerFilter(isActive);
    }

    function setAreaManagerFilter(isActive) {
        if (isActive === vm.filterMenu.isAreaManagerFilterActive) {
            return;
        }
        vm.filterMenu.isAreaManagerFilterActive = isActive;
        $sbTracking.filterMenu.byConstructionManager("Dashboard");
    }

    function _reloadCharts() {
        return $q.all([
            _fetchTemplateStageSuggestions(),
            loadBarChartContent(
                _getSelectedProcessTemplate(),
                _getSelectedDeliverableType()
            ),
            _loadProjectBarChartContent(),
            _fetchProjectData(),
        ]);
    }

    function selectStructure(structure) {
        if (
            !isValidFilterSelection(
                structure,
                vm.filterMenu.selectedStructure,
                "ID"
            )
        ) {
            return;
        }

        vm.filterMenu.setSelectedStructure(structure);
        $sbTracking.filterMenu.byProjectStructure("Dashboard");

        _reloadCharts();
    }

    function _getQueryParams() {
        const queryParams = {
            byOnlyMyArea: vm.filterMenu.isAreaManagerFilterActive,
        };

        if (vm.filterMenu.selectedStructure) {
            queryParams.byStructureId = vm.filterMenu.selectedStructure.ID;
        }

        if (isValidTeamSelected()) {
            queryParams.byTeamId = vm.filterMenu.selectedTeam.id;
        }

        return queryParams;
    }

    function _appendToURLQueryParams(selectedFilters) {
        $state.go("sablono.project.dashboard", selectedFilters, {
            notify: false,
            location: "replace",
        });
    }

    function onDeliverableTileCardClick(card) {
        var params = _mergeChartParamsWithURLParams(card.filters);

        _goToDeliverablesPage(params);
    }

    function collectActivityIdsOfBarSelection(activityOrGroupId, processId) {
        const isActivityId = _unfilteredTemplateOptions.some(
            (option) => option.id === activityOrGroupId
        );
        if (isActivityId) {
            return Promise.resolve(activityOrGroupId);
        }

        return $sbTemplate
            .getTemplateDetails(processId)
            .then(({ components }) => {
                return components.filter(
                    (a) => a.parentId === activityOrGroupId
                );
            })
            .then((activities) => {
                return activities.map(({ id }) => id);
            });
    }

    function onDeliverableBarChartClick(barChart) {
        if (isBarChartNavigationDisabled()) {
            return;
        }
        Analytics.trackEvent("Dashboards", "click", "Bars - " + barChart.label);
        Analytics.trackConversion("bar clicked");
        // barChart.params.stage is a string that contains template url with status (uuid-example,stage)
        const [idOfClickedBar, state] = barChart.params.stage.split(",");
        const isBarChartClickOnProcessTemplate =
            vm.filterMenu.processTemplates.some(
                (item) => item.value.id === idOfClickedBar
            );

        const params = {};

        switch (state) {
            case "work_in_progress":
                params.activityStates = ["started", "rejected"];
                break;
            case "waiting_for_confirmation":
                params.activityStates = ["waiting_for_confirmation"];
                break;
            case "complete":
                params.activityStates = ["done", "confirmed"];
                break;
            case "done":
                params.activityStates = [
                    "done",
                    "confirmed",
                    "waiting_for_confirmation",
                ];
                break;
            case "behind":
                params.activityStates = [
                    "not_started",
                    "started",
                    "waiting_for_confirmation",
                    "rejected",
                ];
                break;
            default:
                params.activityStates = [];
                break;
        }

        if (vm.filterMenu.selectedTeam) {
            params.workTeams = vm.filterMenu.selectedTeam.id;
        }

        if (state === "planned" || state === "behind") {
            params.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        }

        const structureId = _.get(_getSelectedStructure(), "ID");
        if (structureId) {
            params.structures = structureId;
        }

        // determine the correct process template id for the bar
        //
        if (isBarChartClickOnProcessTemplate) {
            params.processTemplate = idOfClickedBar;
        } else if (isValidTemplateSelected()) {
            params.processTemplate = _getSelectedProcessTemplate().id;
        } else {
            // this a bit of an edge case where we show activities or groups
            //  even though no process is selected. Happens if only one process
            //  is there as an option (see bar chart loading code)
            const dataSourceOfClickedBar = _.find(
                vm.templateStats,
                (stats) => stats.id === idOfClickedBar
            );
            params.processTemplate = dataSourceOfClickedBar.contentLoadedForId;
        }

        if (!isBarChartClickOnProcessTemplate) {
            return collectActivityIdsOfBarSelection(
                idOfClickedBar,
                params.processTemplate
            ).then((activityIds) => {
                params.activity = activityIds;
                return _goToTrackerPage(params);
            });
        } else {
            try {
                return _goToTrackerPage(params);
            } catch {
                return _goToDeliverablesPage(
                    _mergeChartParamsWithURLParams(barChart.params)
                );
            }
        }
    }

    function _mergeChartParamsWithURLParams(chartParams = {}) {
        if (isValidTemplateSelected()) {
            chartParams.workflow = _.get(_getSelectedProcessTemplate(), "id");
        }
        if (isValidStructureSelected()) {
            chartParams.structureId = _.get(_getSelectedStructure(), "ID");
        }
        return chartParams;
    }

    function _goToDeliverablesPage(params) {
        $state.go(SABLONO_STATES.deliverablesProgress, params);
    }

    function _goToTrackerPage(params) {
        $state.go(SABLONO_STATES.tracker, params);
    }

    function selectTeam(team) {
        if (team === vm.filterMenu.selectedTeam) {
            return;
        }

        $sbTracking.filterMenu.byTeam("Dashboard");

        vm.filterMenu.setSelectedTeam(team);

        _reloadCharts();
    }

    function isValidTeamSelected() {
        return !!vm.filterMenu.selectedTeam;
    }

    function isValidTemplateSelected() {
        return !!_getSelectedProcessTemplate();
    }

    function isValidDeliverableTypeSelected() {
        return !!_getSelectedDeliverableType();
    }

    function isValidStructureSelected() {
        return !!_getSelectedStructure();
    }

    function applyChangeOfOptionsToSCurve(availableOptions, currentSelection) {
        vm.isLoadingSCurveChart = true;
        if (_.isEmpty(currentSelection) && _.isEmpty(availableOptions)) {
            vm.sCurveSelection = [];
        }
        if (currentSelection === undefined && !_.isEmpty(availableOptions)) {
            vm.sCurveSelection = [];
        }
        return updateSCurveChartData(vm.sCurveSelection);
    }

    function updateSCurveChartData(selection) {
        return _fetchSCurveChartData(selection)
            .then((chartData) => (vm.sCurve = chartData))
            .finally(() => (vm.isLoadingSCurveChart = false));
    }

    function _getFilteredTemplateOptions() {
        let filteredTemplateOptions = _unfilteredTemplateOptions;

        if (isValidTeamSelected()) {
            filteredTemplateOptions = _.filter(filteredTemplateOptions, {
                teamId: vm.filterMenu.selectedTeam.id,
            });
        }

        if (isValidTemplateSelected()) {
            filteredTemplateOptions = _.filter(filteredTemplateOptions, {
                rootId: vm.filterMenu.selectedProcessTemplate.id,
            });
        }

        return filteredTemplateOptions;
    }

    function selectScurveActivities($event) {
        const dialog = $mdDialog
            .selectScurveActivities()
            .templateOptions(vm.templateOptions)
            .projectTeams(vm.teams)
            .initialSelection(vm.sCurveSelection)
            .targetEvent($event);

        $sbTracking.dashboard.sCurve.onSelectionStart();

        return $mdDialog
            .show(dialog)
            .then((selectedItems) => {
                $sbTracking.dashboard.sCurve.onSelectionFinish();
                return onSCurveSelectionChange(selectedItems);
            })
            .catch(() => $sbTracking.dashboard.sCurve.onSelectionCancel());
    }

    function onDeselectAll() {
        return onSCurveSelectionChange([]).then(() =>
            $sbTracking.dashboard.sCurve.onDeselectAll()
        );
    }

    function saveFiltersLatestSelection() {
        $sbDashboardSelection.setLastFilterSelection({
            structure: vm.filterMenu.selectedStructure
                ? vm.filterMenu.selectedStructure.ID
                : vm.filterMenu.selectedStructure,
            team: vm.filterMenu.selectedTeam
                ? vm.filterMenu.selectedTeam.id
                : vm.filterMenu.selectedTeam,
            processTemplate: vm.filterMenu.selectedProcessTemplate
                ? vm.filterMenu.selectedProcessTemplate.id
                : vm.filterMenu.selectedProcessTemplate,
            deliverableType: vm.filterMenu.selectedDeliverableType
                ? vm.filterMenu.selectedDeliverableType.name
                : vm.filterMenu.selectedDeliverableType,
        });
    }

    function assignFiltersLatestSelectionToFilterMenu() {
        let { structure, team, processTemplate, deliverableType } =
            $sbDashboardSelection.getLastFilterSelection();

        _setFiltersBasedOnProperties({
            structureId: structure,
            teamId: team,
            templateId: processTemplate,
            deliverableType: deliverableType,
        });
    }

    function updateUrlBasedOnSelectedFilters() {
        _appendToURLQueryParams({
            structureId: _.get(
                vm,
                "filterMenu.selectedStructure.ID",
                vm.ALL_FILTER_ITEMS_SELECTION
            ),
            teamId: _.get(
                vm,
                "filterMenu.selectedTeam.id",
                vm.ALL_FILTER_ITEMS_SELECTION
            ),
            templateId: _.get(
                vm,
                "filterMenu.selectedProcessTemplate.id",
                vm.ALL_FILTER_ITEMS_SELECTION
            ),
            deliverableType: _.get(
                vm,
                "filterMenu.selectedDeliverableType.name",
                vm.ALL_FILTER_ITEMS_SELECTION
            ),
        });
    }

    function isValidFilterSelection(newChoice, oldChoice, comparisonPath) {
        if (newChoice === oldChoice) {
            return false;
        }

        return !(
            newChoice &&
            oldChoice &&
            _.get(newChoice, comparisonPath) ===
                _.get(oldChoice, comparisonPath)
        );
    }

    function setFiltersBasedOnUrlProperties() {
        _setFiltersBasedOnProperties($stateParams);
    }

    /**
     * Load data for project-level bar chart.
     *
     * @returns {Promise<boolean>}
     */
    function _loadProjectBarChartContent() {
        vm.isLoadingProjectStats = true;
        const queryParams = _getQueryParams();
        const byProcessTemplate = (_getSelectedProcessTemplate() || {}).id;
        const byDeliverableType = (_getSelectedDeliverableType() || {}).name;
        return dashboardService
            .fetchScheduleStatsForProject($stateParams.projectId, {
                ...queryParams,
                byProcessTemplate,
                byDeliverableType,
            })
            .then((stats) => (vm.projectStats = stats))
            .catch((err) => {
                vm.projectStats = [];
                $sbErrorPresenter.catch(err);
            })
            .finally(() => (vm.isLoadingProjectStats = false));
    }

    function isBarChartNavigationDisabled() {
        // Do not allow click in the following two cases
        // 1) Type filter has a selection

        return isValidDeliverableTypeSelected();
    }

    function _setFiltersBasedOnProperties({
        structureId,
        teamId,
        templateId,
        deliverableType,
    }) {
        if (structureId) {
            const selectedStructure = _.find(vm.filterMenu.structures, [
                "value.ID",
                structureId,
            ]);

            vm.filterMenu.selectedStructure = _.get(selectedStructure, "value");
        }

        if (teamId) {
            const selectedTeam = _.find(vm.filterMenu.teams, [
                "value.id",
                Number.parseInt(teamId),
            ]);

            vm.filterMenu.selectedTeam = _.get(selectedTeam, "value");
        }

        if (templateId) {
            const selectedProcessTemplate = _.find(
                vm.filterMenu.processTemplates,
                ["value.id", templateId]
            );

            vm.filterMenu.selectedProcessTemplate = _.get(
                selectedProcessTemplate,
                "value"
            );
        }

        if (deliverableType) {
            const selectedDeliverableType = _.find(
                vm.filterMenu.deliverableTypes,
                ["value.name", deliverableType]
            );

            vm.filterMenu.selectedDeliverableType = _.get(
                selectedDeliverableType,
                "value"
            );
        }
    }
}
