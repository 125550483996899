import ConstantsModule from "./constants.module";
import Note from "./../domain/sb_note.class";
import ActivityState from "../domain/sb_activity_state.class";

ConstantsModule.constant("PROJECT_CONFIG", {
    NAME: {
        LABEL: "_PROJECT_NAME",
        MIN_LENGTH: 2,
        MAX_LENGTH: 128,
    },
    CODE: {
        LABEL: "_PROJECT_CODE",
        MIN_LENGTH: 2,
        MAX_LENGTH: 16,
    },
    DESC: {
        LABEL: "_PROJECT_DESC",
        MIN_LENGTH: 1,
        MAX_LENGTH: 128,
    },
});

ConstantsModule.constant("ROLE_NAMES", {
    ADMIN: "ADMIN",
    MANAGER: "MANAGER",
    CONTROLLER: "CONTROLLER",
    INSPECTOR: "INSPECTOR",
    REVIEWER: "REVIEWER",
});

ConstantsModule.constant("PROGRESS_VALUES_CONFIG", {
    notStarted: {
        CLASS: "sb-todo",
        LABEL: "_PROGRESS_0",
        ICON: "mdi mdi-checkbox-blank-circle-outline",
        PROGRESS_VALUE: 0,
        STATE_VALUE: ActivityState.NOT_STARTED,
    },
    inProgress: {
        CLASS: "sb-in-progress",
        LABEL: "_PROGRESS_50",
        ICON: "mdi mdi-sync mdi-rotate-90",
        PROGRESS_VALUE: 50,
        STATE_VALUE: ActivityState.STARTED,
    },
    done: {
        CLASS: "sb-done",
        LABEL: "_PROGRESS_100",
        ICON: "mdi mdi-check",
        PROGRESS_VALUE: 100,
        STATE_VALUE: ActivityState.DONE,
    },
    waitingForConfirmation: {
        CLASS: "sb-wfc",
        LABEL: "_WAITING_FOR_CONFIRMATION",
        ICON: "mdi mdi-check-all",
        PROGRESS_VALUE: 100,
        STATE_VALUE: ActivityState.WAITING_FOR_CONFIRMATION,
    },
    rejected: {
        CLASS: "sb-rejected",
        LABEL: "_REJECTED",
        ICON: "mdi mdi-close",
        PROGRESS_VALUE: 50,
        STATE_VALUE: ActivityState.REJECTED,
    },
    confirmed: {
        CLASS: "sb-done",
        LABEL: "_CONFIRMED",
        ICON: "mdi mdi-check-all",
        PROGRESS_VALUE: 100,
        STATE_VALUE: ActivityState.CONFIRMED,
    },
});

ConstantsModule.constant("ISSUE_TEMPLATE_CONFIG", {
    TEXT: {
        LABEL: "_ISSUE_TEXT",
        MIN_LENGTH: 3,
        MAX_LENGTH: 64,
    },
    KEY: {
        LABEL: "_ISSUE_KEY",
        MIN_LENGTH: 1,
        MAX_LENGTH: 8,
    },
    TYPES: {
        LABEL: "_ISSUE_TYPE",
        DEFAULT: "CLAIM",
        OPTIONS: {
            INFO: {
                NOTE_DATA_CY: "cy-info-note",
                LABEL: "_INFO",
                ICON: "mdi mdi-information",
                CLASS: "sb-info",
                SERVER_NAME: "INFO",
                DOMAIN_NAME: Note.INFORMATION,
            },
            CLAIM: {
                LABEL: "_CLAIM",
                NOTE_DATA_CY: "cy-quality-note",
                ICON: "mdi mdi-alert",
                CLASS: "sb-claim",
                SERVER_NAME: "CLAIM",
                DOMAIN_NAME: Note.QUALITY_ISSUE,
            },
            OBSTRUCTION: {
                LABEL: "_OBSTRUCTION",
                NOTE_DATA_CY: "cy-obstruction-note",
                ICON: "mdi mdi-texture mdi-rotate-90",
                CLASS: "sb-obstruction",
                SERVER_NAME: "OBSTRUCTION",
                DOMAIN_NAME: Note.OBSTRUCTION,
            },
        },
    },
});

ConstantsModule.constant("TEMPLATE_CONFIG", {
    NAME: {
        LABEL: "_TEMPLATE_NAME",
        MIN_LENGTH: 3,
        MAX_LENGTH: 64,
    },
    CODE: {
        LABEL: "TEMPLATE_CODE",
        MIN_LENGTH: 2,
        MAX_LENGTH: 16,
    },
    DESC: {
        LABEL: "_TEMPLATE_DESC",
        MIN_LENGTH: 1,
        MAX_LENGTH: 1000,
    },
    CATEGORY: {
        LABEL: "_TEMPLATE_CATEGORY",
        OPTIONS: {
            ROOM: {
                LABEL: "_ROOM",
                ICON: "sb-icon-room",
            },
            COMPONENT: {
                LABEL: "_COMPONENT",
                ICON: "mdi mdi-cube-outline",
            },
            TRAIT: {
                LABEL: "_TRADE",
                ICON: "sb-icon-trait",
            },
            DELIVERY: {
                LABEL: "_DELIVERY",
                ICON: "sb-icon-delivery",
            },
            DOCUMENT: {
                LABEL: "_DOCUMENT",
                ICON: "mdi mdi-file-outline",
            },
            GROUP: {
                LABEL: "_OTHERS",
                ICON: "sb-icon-group",
            },
        },
    },
});

ConstantsModule.constant("ACTIVITY_CONFIG", {
    ACTIVITY_NAME: {
        LABEL: "_TEMPLATE_ACTIVITY_NAME",
        MIN_LENGTH: 3,
        MAX_LENGTH: 64,
    },
    CODE: {
        LABEL: "_TEMPLATE_ACTIVITY_CODE",
        MIN_LENGTH: 2,
        MAX_LENGTH: 16,
    },
    STATE_NAME: {
        LABEL: "_TEMPLATE_STATE_NAME",
        MIN_LENGTH: 3,
        MAX_LENGTH: 64,
    },
    LABOUR: {
        LABEL: "_TEMPLATE_LABOUR",
        MIN: 0,
    },
    DESC: {
        LABEL: "INPUT_TEMPLATE_DESCRIPTION_TITLE",
        MIN_LENGTH: 3,
        MAX_LENGTH: 128,
    },
});

ConstantsModule.constant("DELIVERABLE_CONFIG", {
    CODE: {
        LABEL: "_CODE",
        MIN_LENGTH: 1,
        MAX_LENGTH: 64,
        REQUIRED: true,
        PLACEHOLDER: "INPUT_CODE_NEW_DELIVERABLE_PLACEHOLDER",
        DATA_CY: "code",
    },
    NAME: {
        LABEL: "_NAME",
        MIN_LENGTH: 1,
        MAX_LENGTH: 128,
        REQUIRED: true,
        PLACEHOLDER: "INPUT_NAME_NEW_DELIVERABLE_PLACEHOLDER",
        DATA_CY: "name",
    },
    DESC: {
        LABEL: "_DESC",
        MIN_LENGTH: 0,
        MAX_LENGTH: 1000,
        REQUIRED: false,
        DATA_CY: "desc",
    },
});

ConstantsModule.constant("SB_DETAILS_OVERLAY_ID", "sb-details-overlay");

const PROJECT_STATE_REGEX = /sablono.project\./;
const SABLONO_STATE_REGEX = /sablono\./;

ConstantsModule.constant("SABLONO_STATES", {
    profile: "sablono.profile",
    teams: "sablono.project.teams",
    deliverablesProgress: "sablono.project.ngdeliverables",
    issues: "sablono.project.issues",
    noteTemplates: "sablono.project.noteTemplates",
    visualization: "sablono.project.visualizations",
    visualizationSketch: "sablono.project.visualizations.sketch",
    visualizationGenerated: "sablono.project.visualizations.generated",
    visualizationConfiguration: "sablono.project.visualizations.configuration",
    leanboard: "sablono.project.leanboard",
    timeline: "sablono.project.timeline",
    dashboard: "sablono.project.dashboard",
    dashboardPro: "sablono.project.dashboardPro",
    tracker: "sablono.project.tracker",
    processViewer: "sablono.project.processViewer",
    wbs: "sablono.project.wbs",
    projectOverview: "sablono.project.projectOverview",
    projectOverviewPro: "sablono.project.projectOverviewPro",
    freemiumLanding: "sablono.project.freemiumLanding",
    projectSettings: "sablono.project.settings",
    projectSelection: "sablono.projectSelection",
    info: "sablono.info",
    export: "sablono.project.export",
    tools: "sablono.project.tools",
    templates: "sablono.project.templates",
    checklistTemplatesList: "sablono.project.checklistTemplatesList",
    checklistTemplatesEditor: "sablono.project.checklistTemplatesEditor",
    siteDiary: "sablono.project.siteDiary",
    commercialDashboard: "sablono.project.commercial",
    commercialProDashboard: "sablono.project.commercialValuation",
    requestAccess: "sablono.requestAccess",
    requestAccessProjectOverview:
        "sablono.project.requestAccessProjectOverview",
    requestAccessPerformanceDashboard:
        "sablono.project.requestAccessPerformanceDashboard",
    requestAccessPerformanceDashboardPro:
        "sablono.project.requestAccessPerformanceDashboardPro",
    requestAccessDeliverables: "sablono.project.requestAccessDeliverables",
    requestAccessNotes: "sablono.project.requestAccessNotes",
    requestAccessLeanPlanning: "sablono.project.requestAccessLeanPlanning",
    requestAccessCommercialDashboard:
        "sablono.project.requestAccessCommercialDashboard",
    requestAccessVisualTrackers: "sablono.project.requestAccessVisualTrackers",
    requestAccessQAChecklists: "sablono.project.requestAccessQAChecklists",
    requestAccessCommercialProDashboard:
        "sablono.project.requestAccessCommercialValuation",

    isProjectState: (state) => PROJECT_STATE_REGEX.test(state),
    isSablonoState: (state) => SABLONO_STATE_REGEX.test(state),
});
