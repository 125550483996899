import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";

function DeliverableSelection(selectionProvider) {
    this.projectId;
    this.selectionProvider = selectionProvider;
    this.odataFilterFactory;
    this.selection;
    this.size;
}

DeliverableSelection.prototype.getSize = function () {
    if (this.selectionProvider.isSelectAllActive()) {
        return this.size;
    }
    if (this.selectionProvider.hasActiveSelections()) {
        return this.selectionProvider.length;
    }
    return this.size || this.selectionProvider.length;
};

DeliverableSelection.prototype.isSelectAllActive = function () {
    return this.selectionProvider.isSelectAllActive();
};

/**
 * Will return the real selection of the selection provider if select all is NOT active.
 * Will return the local selection (this.selection) if select all is active -> can be undefined if not set
 *
 * @return {Array | undefined}
 */
DeliverableSelection.prototype.getLocalSelection = function () {
    if (this.isSelectAllActive()) {
        return this.selection || [];
    } else {
        return this.selectionProvider.selections() || [];
    }
};

/**
 * Check if getLocalSelection will result in a valid selection state.
 *
 * @return {boolean}
 */
DeliverableSelection.prototype.hasAllSelectionsLocally = function () {
    if (this.isSelectAllActive() && !this.selection) {
        return false;
    } else {
        return true;
    }
};

/**
 * create an odata $filter string that represents the current state of the selection.
 *
 * if the selection is in a select all state
 *      -> return the predefined filter string if available
 * otherwise create a concatenated ID filter string
 *
 * @return {string} the filter condition in OData language
 */
DeliverableSelection.prototype.createOdataFilterString = function () {
    if (this.isSelectAllActive() && this.odataFilterFactory) {
        return this.odataFilterFactory.get();
    } else {
        var quantity = this.getLocalSelection().length;

        // create a chained ID eq odata condition -> use with caution (can overflow)
        var odataFactory = this.getLocalSelection().reduce(function (
            factory,
            deliverable,
            index
        ) {
            factory.eq("ID", deliverable.ID);
            if (index + 1 < quantity) {
                factory.or(); // chain with or
            }
            return factory;
        }, new ODataFilterFactory());

        return odataFactory.get();
    }
};

/**
 * Very often we allow service calls to accept either an array of entities with IDs or a string
 * representing a filter condition in odata syntax. Based on the configuration of this selection
 * model getPreferredCondition will give you one or the other.
 *
 * @return {Array | string}
 */
DeliverableSelection.prototype.getPreferredCondition = function () {
    if (this.isSelectAllActive()) {
        return this.createOdataFilterString();
    } else {
        return this.getLocalSelection();
    }
};

export default DeliverableSelection;
