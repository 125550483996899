import angular from "angular";
import _ from "lodash";

export default class DashboardSelectionStoreService {
    constructor($sbCurrentProject, $window, $sbFilterStore) {
        "ngInject";

        this.$window = $window;
        this.$sbCurrentProject = $sbCurrentProject;
        this.$sbFilterStore = $sbFilterStore;
    }

    getLastFilterSelection() {
        return this.$sbFilterStore.getLastFilterSelection({
            structure: null,
            team: null,
            processTemplate: null,
            deliverableType: null,
        });
    }

    setLastFilterSelection(filters) {
        return this.$sbFilterStore.setLastFilterSelection(filters);
    }

    getLastSelectedActivityTemplates() {
        return this._retrieveValue(
            this._getKeyForLastSelectedActivityTemplatesOfProject(),
            []
        );
    }

    setLastSelectedActivityTemplates(activityTemplates) {
        if (!Array.isArray(activityTemplates)) {
            throw new TypeError(
                "Parameter 'activityTemplates' should be of type array"
            );
        }

        return this._storeValue(
            this._getKeyForLastSelectedActivityTemplatesOfProject(),
            activityTemplates
        );
    }

    _getKeyForLastSelectedActivityTemplatesOfProject() {
        return (
            "sb.dashboard.selection.s-curve" + this.$sbCurrentProject.pick("id")
        );
    }

    _retrieveValue(key, defaultValue) {
        return _.defaultTo(
            angular.fromJson(this.$window.localStorage.getItem(key)),
            defaultValue
        );
    }

    _storeValue(key, value) {
        return this.$window.localStorage.setItem(key, angular.toJson(value));
    }

    clearAll() {
        this.$sbFilterStore.clearSavedSelection();
        this.$window.localStorage.removeItem(
            this._getKeyForLastSelectedActivityTemplatesOfProject()
        );
    }
}
