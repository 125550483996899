export default class ActivityJobsWebService {
    constructor($sbRequest, API_VERSION) {
        "ngInject";
        this.$sbRequest = $sbRequest;
        this.API_VERSION = API_VERSION;

        this.JOB_TYPE = {
            MODIFY_PROPERTIES: "MODIFY_PROPERTIES",
            CHANGE_VISIBILITY: "CHANGE_VISIBILITY",
            BULK_CREATE_QA_PROTOCOLS: "BULK_CREATE_QA_PROTOCOLS",
            BULK_CREATE_NOTES: "BULK_CREATE_NOTES",
        };
    }

    /**
     * This endpoint creates a new activities job in the project.
     *
     * @param projectId {string}    UUID
     *
     * @param body {Object}    Request body
     */
    create(projectId, body) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/activity-jobs`;
        return this.$sbRequest({
            url: endpointUrl,
            method: "POST",
            body: JSON.stringify(body),
            header: {
                "Content-Type": "application/json",
            },
        }).then((resp) => {
            const jobId = resp.getResponseHeader("x-job-id");
            const response = JSON.parse(resp.responseText);
            return { response, jobId };
        });
    }

    /**
     * Create a 'MODIFY_PROPERTIES' activities job
     *
     * @param projectId {string}                UUID
     *
     * @param body {Object}                     Request body
     * @param body.properties {Object}          Definition of the change
     * @param body.deliverable_set {string|string[]}    Filter expression list of deliverable ids to identify a set of deliverables. All those deliverables will be updated
     * @param body.activity_templates {string[]}        List of activity template ids to identify activity "types"
     *
     * @returns {Promise<{}>}
     */
    createModifyPropertiesJob(
        projectId,
        { properties, deliverable_set, activity_templates }
    ) {
        return this.create(projectId, {
            type: this.JOB_TYPE.MODIFY_PROPERTIES,
            definition: {
                properties,
                deliverable_set,
                activity_templates,
            },
        });
    }

    /**
     * Create a 'CHANGE_VISIBILITY' activities job
     *
     * @param projectId {string}                UUID
     *
     * @param body {Object}                     Request body
     * @param body.visible {boolean}            Show (visible: true) or hide (visible: false)
     * @param body.deliverable_set {string|string[]}    Filter expression list of deliverable ids to identify a set of deliverables. All those deliverables will be updated
     * @param body.activity_templates {string[]}        List of activity template ids to identify activity "types"
     *
     * @returns {Promise<{}>}
     */
    createSwitchVisibilityJob(
        projectId,
        { visible, deliverable_set, activity_templates }
    ) {
        return this.create(projectId, {
            type: this.JOB_TYPE.CHANGE_VISIBILITY,
            definition: {
                visible,
                deliverable_set,
                activity_templates,
            },
        });
    }

    /**
     * Create a 'BULK_CREATE_QA_PROTOCOLS' activities job
     *
     * @param projectId {string}                        UUID
     *
     * @param body {Object}                             Request body
     * @param body.deliverable_set {string|string[]}    Filter expression list of deliverable ids to identify a set of deliverables. All those deliverables will be updated
     * @param body.activity_templates {string[]}        List of activity template ids to identify activity "types"
     * @param body.signed_between {object}              Datetime range in which checklists have been signed
     * @param body.signed_between.from {string}         ISO-8601 formatted UTC datetime string
     * @param body.signed_between.to {string}           ISO-8601 formatted UTC datetime string
     *
     * @param options {Object}
     * @param options.language {string}                 The language the report should be printed in.
     *
     * @param extras {Object}
     * @param extras.forwardTo {string[]}               List of email addresses to forward the report to
     * @param extras.text {string}                      Custom message to be included in the email
     *
     * @returns {Promise<{ qaReports: number, requester: { username: string, email: string }}>}
     */
    createBulkQAProtocolsJob(
        projectId,
        { deliverable_set, activity_templates, signed_between },
        { language },
        { forwardTo, text } = {}
    ) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/activity-jobs`;

        const jobBody = {
            type: this.JOB_TYPE.BULK_CREATE_QA_PROTOCOLS,
            definition: {
                deliverable_set,
                activity_templates,
                signed_between,
                language,
            },
        };

        if (forwardTo) {
            jobBody.definition.forward_to = {
                emails: forwardTo,
                custom_message: text,
            };
        }

        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: jobBody,
            header: {
                "Accept-Language": language,
            },
        });
    }

    /**
     * Create a 'BULK_CREATE_NOTES' activities job
     *
     * @param projectId {string}                        UUID
     *
     * @param body {Object}                             Request body
     * @param body.deliverable_set {string|string[]}    Filter expression list of deliverable ids to identify a set of deliverables. All those deliverables will be updated
     * @param body.activity_templates {string[]}        List of activity template ids to identify activity "types"
     * @param body.note {Note}                          The note to create
     *
     * @returns {Promise}
     */
    bulkCreateNotes(projectId, { deliverable_set, activity_templates, note }) {
        const endpointUrl = `/api/${this.API_VERSION}/api/projects/${projectId}/activity-jobs`;
        return this.$sbRequest.json({
            url: endpointUrl,
            method: "POST",
            body: {
                type: this.JOB_TYPE.BULK_CREATE_NOTES,
                definition: {
                    deliverable_set,
                    activity_templates,
                    note,
                },
            },
        });
    }
}
