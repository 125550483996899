import ConstantsModule from "./constants.module";
import ChangeEvent from "./sb_change_event.class";

ConstantsModule.constant("EVENTS", {
    SbChangeEvent: ChangeEvent,

    GLOBAL_SEARCH_CHANGE: "sb.global.search",
    GLOBAL_SEARCH_UNSET: "sb.global.search.unset",

    PLAN_WORK_REVIEW_SECTION_CHANGED: "sb.global.section.changed",

    NOTE_CHANGED: "sb.note.changed",
    DELIVERABLE__DATA_CHANGED: "sb.deliverable.data.changed",

    DELIVERABLES_LIST__TRIGGER_RELOAD: "sb.deliverable.list.reload",
    ODATA_LIST__FILTERS_CLEAR: "sb.odata.list.clear.filters",
    COMPONENT_DETAIL__STATE_CHANGED: "sb.component.state.change",
    COMPONENT_DETAIL__NOTE_ADDED: "sb.component.note.added",
    SVG__UPDATE_ELEMENT: "sb.svg.update",

    DETAILS_OVERLAY_STATE_CHANGED: "sb.overlay.state.change",
    DELIVERABLE_OVERLAY_SELECTED_TAB_DID_CHANGE:
        "sb.deliverable.overlay.selectedTab.didChange",

    AREA_MANAGER_CHANGED: "sb.deliverable.area_manager.change",
    FULLSCREEN_MODE_CHANGE: "sb.fullscreen.change",
});
