import moment from "moment";
import _ from "lodash";

export default function ($sbPerformanceApi) {
    "ngInject";
    /////////////////////
    //
    //      API
    //
    /////////////////////

    return {
        fetchScurveData: fetchScurveData,
        fetchDeliverableProgressStats: fetchDeliverableProgressStats,
        fetchScheduleStats: fetchScheduleStats,
        fetchScheduleStatsForProject: fetchScheduleStatsForProject,
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    /**
     * Fetch scheduling stats for
     * - direct children of deliverables with the given template ID. (these can be activities or groups.)
     * - process templates of that project
     * - deliverable type of that project
     *
     * @param {String} projectId
     * @param {Object} aggregate
     * @param {Object} queryParams
     */
    function fetchScheduleStats(
        projectId,
        { templateId, typeName } = {},
        { byStructureId, byOnlyMyArea, byTeamId } = {}
    ) {
        const templateDimension = _.isString(templateId)
            ? {
                  name: "sb::process-template",
                  filter: {
                      operator: "EXACT",
                      expression: [templateId],
                  },
              }
            : undefined;

        const deliverableDimension = _.isString(typeName)
            ? {
                  name: "sb::deliverable-type",
                  filter: {
                      operator: "EXACT",
                      expression: [typeName],
                  },
              }
            : undefined;

        let dimension;
        if (templateDimension && deliverableDimension) {
            dimension = [templateDimension, deliverableDimension];
        } else if (templateDimension) {
            dimension = templateDimension;
        } else if (deliverableDimension) {
            dimension = deliverableDimension;
        }

        return $sbPerformanceApi
            .getCollection(
                projectId,
                {
                    date_range: {
                        start_date: Number.NEGATIVE_INFINITY.toString(),
                        end_date: moment().endOf("day").format(),
                    },
                    requests: [
                        {
                            metric: {
                                expression: "sb::count-deliverable",
                            },
                            dimension,
                            pivot: {
                                metric: {
                                    expression: "sb::schedule",
                                },
                            },
                        },
                    ],
                },
                { byStructureId, byOnlyMyArea, byTeamId }
            )
            .then((reports) => {
                return reports.metrics.map((metric) => {
                    const stats = {
                        name: metric.name,
                        id: metric.id,
                    };
                    return _.assign(
                        stats,
                        _.pick(metric.num_of_activities, [
                            "total",
                            "behind",
                            "planned",
                            "finished",
                            "confirmed",
                            "completed",
                        ])
                    );
                });
            });
    }

    function fetchScurveData(
        projectId,
        { byProcessTemplate, byDeliverableType },
        { byStructureId, byOnlyMyArea, byTeamId } = {},
        templateIds = []
    ) {
        const pivot = {
            metric: {
                expression: "sb::calendar",
            },
            dimension: {
                name: "sb::7-days-interval",
            },
        };

        const finishDimension = [
            {
                name: "sb::current-state",
                filter: {
                    operator: "IN_LIST",
                    expression: [
                        "done",
                        "waiting_for_confirmation",
                        "confirmed",
                    ],
                },
            },
        ];
        const dueDateDimension = [
            {
                name: "sb::due-date",
            },
        ];
        const confirmDimension = [
            {
                name: "sb::current-state",
                filter: {
                    operator: "EXACT",
                    expression: ["confirmed"],
                },
            },
        ];

        if (!_.isEmpty(byDeliverableType)) {
            const deliverableTypeDimension = {
                name: "sb::deliverable-type",
                filter: {
                    operator: "EXACT",
                    expression: [byDeliverableType],
                },
            };

            finishDimension.push(deliverableTypeDimension);
            dueDateDimension.push(deliverableTypeDimension);
            confirmDimension.push(deliverableTypeDimension);
        }

        if (!_.isEmpty(byProcessTemplate)) {
            const processTemplateDimension = {
                name: "sb::process-template",
                filter: {
                    operator: "EXACT",
                    expression: [byProcessTemplate],
                },
            };

            finishDimension.push(processTemplateDimension);
            dueDateDimension.push(processTemplateDimension);
            confirmDimension.push(processTemplateDimension);
        }

        if (!_.isEmpty(templateIds)) {
            const activityTemplateFilterDimension = {
                name: "sb::activity-template-id",
                filter: {
                    operator: "IN_LIST",
                    expression: templateIds,
                },
            };

            finishDimension.push(activityTemplateFilterDimension);
            dueDateDimension.push(activityTemplateFilterDimension);
            confirmDimension.push(activityTemplateFilterDimension);
        }

        return $sbPerformanceApi.getCollection(
            projectId,
            {
                date_range: {
                    start_date: Number.NEGATIVE_INFINITY.toString(),
                    end_date: moment().endOf("day").format(),
                },
                requests: [
                    {
                        metric: {
                            expression: "sb::count-activity",
                            as: "finished",
                        },
                        dimension: finishDimension,
                        pivot,
                    },
                    {
                        metric: {
                            expression: "sb::count-activity",
                            as: "planned",
                        },
                        dimension: dueDateDimension,
                        pivot,
                    },
                    {
                        metric: {
                            expression: "sb::count-activity",
                            as: "confirmed",
                        },
                        dimension: confirmDimension,
                        pivot,
                    },
                ],
            },
            { byStructureId, byOnlyMyArea, byTeamId }
        );
    }

    /**
     * Download the statistics for the progress of deliverables and reduce it to have just a simple quantity model
     *
     * @param {String} projectId
     * @param {Object} queryParams
     * @returns {Object}
     */
    function fetchDeliverableProgressStats(
        projectId,
        { templateId, typeName } = {},
        { byStructureId, byOnlyMyArea, byTeamId } = {}
    ) {
        const dimensionNote = [
            {
                name: "sb::note-type",
                filter: {
                    operator: "NUMERIC_GREATER_THAN",
                    expression: ["0"],
                },
            },
        ];

        const dimensionSchedule = [
            {
                name: "sb::is-behind",
                filter: {
                    operator: "EXACT",
                    expression: ["true"],
                },
            },
        ];

        if (_.isString(templateId)) {
            dimensionNote.push({
                name: "sb::process-template",
                filter: {
                    operator: "EXACT",
                    expression: [templateId],
                },
            });
            dimensionSchedule.push({
                name: "sb::process-template",
                filter: {
                    operator: "EXACT",
                    expression: [templateId],
                },
            });
        }

        if (_.isString(typeName)) {
            dimensionNote.push({
                name: "sb::deliverable-type",
                filter: {
                    operator: "EXACT",
                    expression: [typeName],
                },
            });
            dimensionSchedule.push({
                name: "sb::deliverable-type",
                filter: {
                    operator: "EXACT",
                    expression: [typeName],
                },
            });
        }

        return $sbPerformanceApi
            .getCollection(
                projectId,
                {
                    date_range: {
                        start_date: Number.NEGATIVE_INFINITY.toString(),
                        end_date: moment().endOf("day").format(),
                    },
                    requests: [
                        {
                            metric: {
                                expression: "sb::count-deliverable",
                            },
                            dimension: dimensionNote,
                        },
                        {
                            metric: {
                                expression: "sb::count-deliverable",
                            },
                            dimension: dimensionSchedule,
                        },
                    ],
                },
                { byStructureId, byOnlyMyArea, byTeamId }
            )
            .then(_parsePermutationOfStatistics);
    }

    /**
     * Fetch metrics aggregated by process template and aggregate further into project level metrics.
     *
     * @param projectId {string}
     * @param queryParams {object}
     * @param queryParams.byOnlyMyArea {boolean}
     * @param queryParams.byStructureId {string}
     * @param queryParams.byTeamId {number}
     * @param [queryParams.byProcessTemplate] {string}
     * @param [queryParams.byDeliverableType] {string}
     *
     * @returns {Promise<{
     *               id: string,
     *               name: string,
     *               total: number,
     *               behind: number,
     *               planned: number,
     *               finished: number,
     *               confirmed: number,
     *               completed: number,
     *           }>}
     */
    function fetchScheduleStatsForProject(projectId, queryParams) {
        const { byProcessTemplate, byDeliverableType, ...remoteQueryParams } =
            queryParams;
        return fetchScheduleStats(
            projectId,
            { typeName: byDeliverableType },
            remoteQueryParams
        ).then((stats) => {
            if (_.isString(byProcessTemplate)) {
                stats = stats.filter((statsByTemplate) => {
                    return statsByTemplate.id === byProcessTemplate;
                });
            }

            if (_.isEmpty(stats)) {
                return [];
            }

            return [
                stats.reduce(
                    (aggregatedStats, statsByTemplate) => {
                        aggregatedStats.total += statsByTemplate.total;
                        aggregatedStats.behind += statsByTemplate.behind;
                        aggregatedStats.planned += statsByTemplate.planned;
                        aggregatedStats.finished += statsByTemplate.finished;
                        aggregatedStats.confirmed += statsByTemplate.confirmed;
                        aggregatedStats.completed += statsByTemplate.completed;
                        return aggregatedStats;
                    },
                    {
                        id: projectId,
                        name: "",
                        total: 0,
                        behind: 0,
                        planned: 0,
                        finished: 0,
                        confirmed: 0,
                        completed: 0,
                    }
                ),
            ];
        });
    }

    function _parsePermutationOfStatistics({ reports }) {
        const byNoteTypeReport = reports[0].data.rows;
        const byIsBehindReport = reports[1].data.rows;

        return {
            behind: {
                all: _pickMetricValueByDimension(byIsBehindReport, "is_behind"),
            },
            claims: _pickMetricValueByDimension(byNoteTypeReport, "claim"),
            obstructions: _pickMetricValueByDimension(
                byNoteTypeReport,
                "obstruction"
            ),
            infos: _pickMetricValueByDimension(byNoteTypeReport, "information"),
        };
    }

    function _pickMetricValueByDimension(rows, dimension) {
        const row = _.find(rows, ["dimensions[0]", dimension]);
        return _.get(row, "metrics[0].values[0]");
    }
}
