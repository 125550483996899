import html from "./sb_timeline.component.html";

export default {
    templateUrl: html,
    bindings: {
        isLoading: "<",
        inspections: "<",
        totalAmountOfInspections: "<",
        showMoreIsVisible: "<",
    },
    controller: function (Analytics) {
        "ngInject";

        var vm = this;
        vm.trackClick = trackClick;

        function trackClick() {
            Analytics.trackConversion("update timeline clicked");
        }
    },
};
