import angular from "angular";
import ViewSelectionProvider from "common/plainModels/ViewSelectionProvider";
import DeliverableSelection from "common/plainModels/deliverable_selection.model";
import _ from "lodash";
import ODataFilterFactory from "../../common/services/oDataService/odata_filter_factory.class";

const EXTENSION_PDF = ".pdf";

export default function DeliverablesCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $sbComponent,
    $sbReportingDeliverablesApi,
    $sbDeliverableJobsApi,
    $sbDeliverables,
    $sbProject,
    $sbDetailsOverlay,
    $sbODataViewFilter,
    $sbODataViewFilterStore,
    $sbErrorPresenter,
    $state,
    $mdSidenav,
    $q,
    $log,
    sbPrintReportDialog,
    $sbDeliverablesFilter,
    $sbDialog,
    $mdToast,
    $sbPermission,
    $mdDialog,
    Analytics,
    $sbTracking,
    EVENTS,
    DELIVERABLE_CONFIG,
    DELIVERABLES_VIEW_FILTER_STORE_KEY,
    SABLONO_STATES,
    ODATA_ORDER,
    $sbDeliverableTemplateAssign,
    sbDeliverableDateChangeService,
    $sbInspectionRequest,
    $sbTeam,
    $sbIssueEditorService,
    $sbIssue,
    $sbViewFiltersService
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var viewFilterStore = $sbODataViewFilterStore.getStore(
        $state.$current.name,
        DELIVERABLES_VIEW_FILTER_STORE_KEY,
        $sbProject.getCurrentProjectId()
    );

    var lastCreatedDeliverable = {
        CODE: "",
        NAME: "",
    };

    var _trackedFilters = []; // Used to enable GA tracking on filters

    var _blackListFilters = ["areaManagerFilter"];

    /**
     *
     * Store the deliverables in a list IF all deliverables in the list are available on the client.
     *  # Array of deliverables
     *  or
     *  # undefined if not ready.
     *
     * As the list of deliverables is provided by a lazy loading approach usually not all items of the list
     *  are available. But if they are all fetched to the client we store them here.
     *
     * Usage examples:
     *  - we use that for smarter "select all" handling.
     *  - we will use it to create suggestions on code and name
     *  - we use it to make suggestions for start and due date
     *
     * @type {Array|undefined}
     * @private
     */
    var _allDeliverablesIfAvailable;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    //Scope variables

    vm.totalDeliverableCount; // no init default value if there is none. If we don't know: it means -> undefined.
    vm.filteredDeliverableCount;

    vm.filters = [];
    vm.filterPanelRef;
    vm.calendar;

    vm.isUniqueTemplateSelection = false; //to determine if a bulk template operation enabled

    /**
     * Holds the selection state
     * @type {ViewSelectionProvider}
     */
    vm.selectionProvider = new ViewSelectionProvider();

    // columns for data table
    vm.columns = {
        name: {
            oDataKey: "NAME",
            criteria: "_NAME", // i18n keys
            order: ODATA_ORDER.UNSORTED, // sort direction
        },
        code: {
            oDataKey: "CODE",
            criteria: "_CODE",
            order: ODATA_ORDER.UNSORTED,
        },
        description: {
            oDataKey: "DESC",
            criteria: "_DESC",
            order: ODATA_ORDER.UNSORTED,
        },
        structure: {
            oDataKey: "STRUCTURE_ID",
            criteria: "_STRUCTURE",
            order: ODATA_ORDER.UNSORTED,
        },
        startDate: {
            oDataKey: "SD",
            criteria: "START_DATE",
            order: ODATA_ORDER.UNSORTED,
        },
        endDate: {
            oDataKey: "CD",
            criteria: "END_DATE",
            order: ODATA_ORDER.UNSORTED,
        },
        process: {
            oDataKey: "TEMPLATE_ID",
            criteria: "_PROCESS",
            order: ODATA_ORDER.UNSORTED,
        },
        progress: {
            oDataKey: "",
            criteria: "_PROGRESS",
            order: ODATA_ORDER.UNSORTED,
        },
        inspection: {
            oDataKey: "STATE_CHANGE_TIME",
            criteria: "_INSPECTION",
            order: ODATA_ORDER.UNSORTED,
        },
    };

    // menu for area manager toggle switch
    //
    vm.filterMenu = {
        lastCallbackEvent: undefined,
        onToggleAreaManagerFilter: onToggleAreaManagerFilter,
        onViewModelSetUpComplete: loadAndSetFilters,
    };

    // default sorting
    var defaultOrder = {
        criteria: "startDate",
        direction: ODATA_ORDER.ASC,
    };

    vm.orderBy;
    vm.clickRow = clickRow;
    vm.showPrintReportsDialog = showPrintReportsDialog;
    vm.goToToolsPage = goToToolsPage;
    vm.createDeliverables = createDeliverables;
    vm.onSortChange = onSortChange;
    vm.onCountChange = onCountChange;
    vm.onAllDeliverablesFetched = onAllDeliverablesFetched;
    vm.changeFilterCondition = changeFilterCondition;
    vm.onAssignTemplate = onAssignTemplate;
    vm.onAssignAreaManager = onAssignAreaManager;
    vm.onAssignType = onAssignType;
    vm.onChangeDates = onChangeDates;
    vm.onDeleteDeliverables = onDeleteDeliverables;
    vm.onChangeStructure = onChangeStructure;
    vm.onPressRequestForProgress = onPressRequestForProgress;
    vm.onBulkUpdate = intercept(
        isNonUniqueTemplateSelection,
        showExplanatoryDialogForDeliverableTypeSelection,
        onBulkUpdate
    );
    vm.onBulkStateChangeClick = intercept(
        isNonUniqueTemplateSelection,
        showExplanatoryDialogForDeliverableTypeSelection,
        onBulkStateChangeClick
    );
    vm.onBulkNoteCreateClick = intercept(
        isNonUniqueTemplateSelection,
        showExplanatoryDialogForDeliverableTypeSelection,
        onBulkNoteCreateClick
    );
    vm.onActivityConfigureClick = intercept(
        isNonUniqueTemplateSelection,
        showExplanatoryDialogForDeliverableTypeSelection,
        onActivityConfigureClick
    );
    vm.onDeliverableConfigureClick = onDeliverableConfigureClick;
    // Selection functions
    vm.toggleSelectAll = toggleSelectAll;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    // Try to open the overlay only if the content is loaded
    $scope.$watch(
        "deliverables.filteredDeliverableCount",
        function (newValue, oldValue) {
            // only open the overlay if filteredDeliverableCount is changed the first time.
            //  from undefined -> to a number
            if (newValue && angular.isUndefined(oldValue)) {
                $sbDetailsOverlay.openFromStateParamsIfNeeded($stateParams);
            }
        }
    );

    $scope.$watch("deliverables.selectionProvider.length", function () {
        vm.isUniqueTemplateSelection = _getUniqueTemplateIdFromSelection(
            _getDeliverablesSelection()
        );
    });

    $rootScope.$on(
        EVENTS.NOTE_CHANGED,
        _updateDeliverablesListAndClearSelection
    );
    $rootScope.$on(
        EVENTS.DELIVERABLES_LIST__TRIGGER_RELOAD,
        _updateDeliverablesListAndClearSelection
    );
    $rootScope.$on(
        EVENTS.DELIVERABLE__DATA_CHANGED,
        _updateDeliverablesListAndClearSelection
    );

    function _updateDeliverablesListAndClearSelection() {
        if (!_.isUndefined(vm.filters)) {
            // clear selection on reload
            //
            if (
                vm.selectionProvider.hasActiveSelections() ||
                vm.selectionProvider.isSelectAllActive()
            ) {
                vm.selectionProvider.clearSelection();
            }

            _updateDeliverablesList(vm.filters);
        }
    }

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    _activate();

    function _activate() {
        fetchFullProjectCalendar();

        $sbComponent
            .countDeliverablesOfProject($stateParams.projectId)
            .then((result) => (vm.totalDeliverableCount = result));
    }

    function loadAndSetFilters($bgMenuEvent) {
        _setLastEvent($bgMenuEvent);

        return _resolveAllViewFilters()
            .then(function (filters) {
                // load sort order or use default
                var order = viewFilterStore.getOrderBy() || defaultOrder;

                // apply
                _setSortConfig(order.criteria, order.direction);

                // save
                viewFilterStore.setOrderBy(order.criteria, order.direction);

                // load filter params from url or local store
                var filterParams = viewFilterStore.getFilterParams();

                // apply filter params
                $sbODataViewFilter.setViewFilterValuesFrom(
                    filterParams,
                    filters
                );

                // store filter params in url and local store
                viewFilterStore.setFilterParams(filters, false);

                // apply area manager filter based on background menu settings
                var areaManagerViewFilter = findFilterByKey(
                    filters,
                    "areaManagerFilter"
                );

                _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter);

                // apply
                vm.filters = filters;

                vm.filters.forEach(function (filter) {
                    if (filter.hasValue()) {
                        _trackedFilters.push(filter);
                    }
                });

                _updateDeliverablesList(filters);
            })
            .catch($sbErrorPresenter.catch);
    }

    function _setLastEvent($bgMenuEvent) {
        vm.filterMenu.lastCallbackEvent = $bgMenuEvent;
    }

    function onToggleAreaManagerFilter($bgMenuEvent) {
        $sbTracking.filterMenu.byConstructionManager("Deliverables");

        var areaManagerViewFilter = findFilterByKey(
            vm.filters,
            "areaManagerFilter"
        );

        _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter);

        changeFilterCondition();
    }

    function _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter) {
        if ($bgMenuEvent.hasAreaManagerRestrictions && $bgMenuEvent.isActive) {
            areaManagerViewFilter.setValue($bgMenuEvent.userName);
        } else {
            areaManagerViewFilter.clear();
        }
    }

    function fetchFullProjectCalendar() {
        return $sbProject.getCalendar().then(function (calendar) {
            $log.debug(
                "DeliverablesCtrl::__activate - Fetched calendar",
                calendar
            );

            vm.calendar = calendar;
            vm.timezone = vm.calendar.TIMEZONE;
        });
    }

    /**
     * Functions for selection management
     */
    function toggleSelectAll() {
        if (
            vm.selectionProvider.isSelectAllActive() ||
            vm.selectionProvider.hasActiveSelections()
        ) {
            vm.selectionProvider.clearSelection();
        } else {
            vm.selectionProvider.selectAll();
        }

        vm.isUniqueTemplateSelection = _getUniqueTemplateIdFromSelection(
            _getDeliverablesSelection()
        );
    }

    function onCountChange(numOfFilteredItems) {
        vm.filteredDeliverableCount = numOfFilteredItems;
    }

    /**
     * If the lazy loader has all deliverables fetched into the client this callback is triggered.
     */
    function onAllDeliverablesFetched(deliverables) {
        _allDeliverablesIfAvailable = deliverables;
    }

    /**
     * Central point to manage changes to the filter state.
     * This is called by filter panel changes.
     *
     */
    function changeFilterCondition() {
        //save filters to URL and local storage
        viewFilterStore.setFilterParams(vm.filters);
        _updateDeliverablesList(vm.filters);
        _trackFilterChanges(vm.filters);
    }

    function showPrintReportsDialog() {
        var reportOdata = $sbODataViewFilter.generateFilterExpress(
            vm.filters,
            vm.columns,
            vm.orderBy
        );

        var deliverableSelection = _getDeliverablesSelection();
        var haveDeliverablesBeenSelected =
            deliverableSelection.selectionProvider.hasActiveSelections();
        var printOptions;

        var templateId = _getUniqueTemplateIdFromSelection(
            _getDeliverablesSelection()
        );

        if (haveDeliverablesBeenSelected) {
            printOptions = ["QA_CHECKLIST_PDFS", "QR_CODES"];
        } else {
            printOptions = [
                "DELIVERABLES",
                "ACTIVITIES",
                "QR_CODES",
                "QA_CHECKLIST_PDFS_BY_DATE",
            ];
        }

        return sbPrintReportDialog.show({
            oDataFilter: reportOdata.query,
            description: reportOdata.activeFilterWithoutHiddenFields,
            types: printOptions,
            templateId: templateId,
            deliverableSelection: deliverableSelection,
        });
    }

    function goToToolsPage() {
        $state.go(SABLONO_STATES.tools, {
            open: "export",
        });
    }

    function createDeliverables() {
        var lastInputSuggestions = {
            CODE: lastCreatedDeliverable.CODE,
            NAME: lastCreatedDeliverable.NAME,
        };
        return $mdDialog.show(
            $mdDialog
                .createNewItems()
                .title("DIALOG_CREATE_NEW_DELIVERABLES_TITLE")
                .onSave(_saveDeliverable)
                .customValidation(_checkIfCodeExistsInProject)
                .customValidationMessage(
                    "DIALOG_CREATE_NEW_DELIVERABLES_ERROR_EXISTING_CODE"
                )
                .itemConfig(DELIVERABLE_CONFIG)
                .inputSuggestionFields(["CODE", "NAME"])
                .lastInputSuggestions(lastInputSuggestions)
                .focusOnField("CODE")
        );
    }

    function _checkIfCodeExistsInProject(item) {
        // check if code exists!
        if (item.CODE) {
            // Check validity here
            return _countDeliverablesWithSameCode(
                item.CODE,
                $sbProject.getCurrentProjectId()
            ).then((count) => count <= 0);
        } else {
            return $q.resolve(true);
        }
    }

    function _countDeliverablesWithSameCode(code, projectId) {
        return $sbReportingDeliverablesApi
            .getCollection(projectId, {
                filter: $sbDeliverablesFilter.codeOdataFilter(code).get(),
                select: "ID",
                top: 2,
            })
            .then(({ meta }) => meta.count_all);
    }

    function _saveDeliverable(deliverable) {
        Analytics.trackEvent("Deliverable creation", "click", "Sablono");
        return $sbDeliverables
            .createDeliverable(deliverable, $sbProject.getCurrentProjectId())
            .then(function (result) {
                lastCreatedDeliverable = result;
                vm.totalDeliverableCount += 1;
                _updateDeliverablesList(vm.filters);
                return $q.resolve(result);
            })
            .catch(function (e) {
                $sbErrorPresenter.catch(e);
                return $q.reject();
            });
    }

    function clickRow(id) {
        $sbDetailsOverlay.toggleView("deliverable", id);
    }

    function onSortChange(columnKey, sortDirection) {
        _setSortConfig(columnKey, sortDirection);

        //save sort in url and local storage
        viewFilterStore.setOrderBy(columnKey, sortDirection);

        // update list
        _updateDeliverablesList(vm.filters);
    }

    /**
     * Create an instance of DeliverableSelection to represent the state of the data selection
     *
     * @return {DeliverableSelection}
     * @private
     */
    function _getDeliverablesSelection() {
        const deliverableSelection = new DeliverableSelection(
            vm.selectionProvider
        );

        deliverableSelection.size = vm.filteredDeliverableCount;
        deliverableSelection.projectId = $sbProject.getCurrentProjectId();
        deliverableSelection.odataFilterFactory = ODataFilterFactory.join(
            [
                $sbODataViewFilter.combinedOData(vm.filters),
                new ODataFilterFactory().eq(
                    "PROJECT_ID",
                    deliverableSelection.projectId
                ),
            ],
            "and"
        );

        // check if all deliverables of the virtual repeater are already available in the client
        if (angular.isArray(_allDeliverablesIfAvailable)) {
            deliverableSelection.selection = _allDeliverablesIfAvailable;
        }

        return deliverableSelection;
    }

    function _showToast(text, content) {
        return $mdToast.show(
            $mdToast
                .simple()
                .content(text)
                .contentValues(content)
                .hideDelay(5000)
                .position("top right")
        );
    }

    function findFilterByKey(viewFilters, key) {
        return _.find(viewFilters, function (viewFilter) {
            return viewFilter.key === key;
        });
    }

    function resetFilterAndUpdate(key, fnAfterReset) {
        fnAfterReset = fnAfterReset || angular.noop;

        var viewFilter = findFilterByKey(vm.filters, key);
        if (viewFilter.hasValue()) {
            viewFilter.value = []; // viewFilter.clear() freezes the UI
            fnAfterReset(viewFilter);
            changeFilterCondition();
            _activate();
            loadAndSetFilters(vm.filterMenu.lastCallbackEvent);
        } else {
            _updateDeliverablesList(vm.filters);
        }
    }

    function _resetWorkflowFilterAndUpdate(newId) {
        resetFilterAndUpdate("workflow", function (workflowFilter) {
            workflowFilter.value.push(newId);
        });
    }

    function _resetStructureFilterAndUpdate() {
        resetFilterAndUpdate("structure");
    }

    function onAssignTemplate($event) {
        $sbTracking.deliverablesList.fromToolbar.onAssignTemplate();

        const deliverableSelection = _getDeliverablesSelection();
        const sizeOfSelection = deliverableSelection.getSize();

        return $sbDeliverableTemplateAssign
            .startAssignWorkflow($event, deliverableSelection)
            .then(function (result) {
                let deliverablesChanged = 0;
                if (
                    result &&
                    result.deliverables &&
                    result.deliverables.length > 0
                ) {
                    _resetWorkflowFilterAndUpdate(result.template.ID);
                    deliverablesChanged = result.deliverables.length;
                }
                return _showToast("ACTION_ASSIGN_TEMPLATE_SUCCESS_N", {
                    changed: deliverablesChanged,
                    total: sizeOfSelection,
                });
            })
            .catch(function (error) {
                if (_.isUndefined(error)) {
                    return;
                }
                $sbErrorPresenter.catch(error);
            });
    }

    function onAssignAreaManager($event) {
        $sbTracking.deliverablesList.fromToolbar.onAssignAreaManager();

        return _showSelectAreaManagerDialog($event).then(function (result) {
            if (result && result.responsibleUser) {
                var deliverableSelection = _getDeliverablesSelection();

                return _updateAreaManagerInDeliverables(
                    result.responsibleUser,
                    deliverableSelection.getPreferredCondition()
                );
            }
        });
    }

    /**
     * Open a dialog to assign/unassign a type to the selected set of deliverables.
     *
     * Show loading indicator while request in process and a success toast when completed
     * with the number of deliverables which have been updated.
     *
     * @param $event {Event}
     * @returns {*}
     */
    function onAssignType($event) {
        $sbTracking.deliverablesList.fromToolbar.onUpdateDeliverableType();
        const deliverableSelection = _getDeliverablesSelection();

        return openAssignDeliverableTypeDialog($event).then(({ type }) => {
            if (_.isEmpty(type)) {
                //unassigned
                type = "sb::type.not-set";
            }
            const task = _updateTypeInDeliverables(
                type,
                deliverableSelection.getPreferredCondition()
            );

            // show blocking loading indicator while updating
            //
            return _showBusyDialogFor(
                task,
                "INFO_UPDATING_DELIVERABLES_MESSAGE"
            )
                .then(() => {
                    // handle success and failure on task separate from busy indicator
                    // -> loading dialog will be closed when failure or success toast is shown
                    //
                    return task.then((numOfDeliverables) => {
                        $sbTracking.deliverablesList.fromToolbar.onUpdateDeliverableTypeSuccess();
                        return _showToast(
                            "INFO_UPDATING_DELIVERABLES_SUCCESSFUL_N",
                            {
                                deliverables: numOfDeliverables,
                            }
                        );
                    });
                })
                .catch($sbErrorPresenter.catch);
        });
    }

    /**
     * Open dialog to fill in the deliverable type.
     *
     * @param $event {Event}
     * @returns {Promise<{type: string}|undefined>}
     */
    function openAssignDeliverableTypeDialog($event) {
        return $mdDialog
            .show(
                $mdDialog
                    .assignType({
                        targetEvent: $event,
                        openFrom: $event.target,
                        closeTo: $event.target,
                        locals: {
                            warningText: false,
                        },
                    })
                    .title("DIALOG_ASSIGN_TYPE_TITLE")
                    .description("DIALOG_ASSIGN_TYPE_TEXT")
                    .action({
                        assign: "DIALOG_ASSIGN_TYPE_ACTION",
                        unassigned: "DIALOG_UNASSIGNED_TYPE_ACTION",
                    })
            )
            .then((result = {}) => {
                return {
                    type: result.type,
                };
            });
    }

    /**
     * Send API request to update deliverable type on set of deliverables.
     *
     * @param type {string}
     * @param deliverables {string|object[]}
     * @returns {Promise<number>} Number of updated deliverables
     */
    function _updateTypeInDeliverables(type, deliverables) {
        const projectId = $sbProject.getCurrentProjectId();

        return $sbComponent
            .updateDeliverablesType(deliverables, projectId, type)
            .then(function (result = {}) {
                return _.get(result, "deliverables.length", 0);
            });
    }

    function _showSelectAreaManagerDialog($event) {
        var dialog = $mdDialog
            .pickProjectMember({
                targetEvent: $event,
                openFrom: $event.target,
                closeTo: $event.target,
                locals: {
                    warningText: false,
                },
            })
            .title("DIALOG_ASSIGN_AREA_MANAGER_TITLE")
            .description("DIALOG_ASSIGN_AREA_MANAGER_TEXT")
            .action("DIALOG_ASSIGN_AREA_MANAGER_ACTION");

        return $mdDialog.show(dialog);
    }

    function onPressRequestForProgress($event) {
        const numOfDeliverables = _getDeliverablesSelection().getSize();

        return _showSelectAddresseeDialog($event, numOfDeliverables).then(
            function (result) {
                if (result && result.responsibleUser) {
                    return _handleRequestForProgress(
                        result.responsibleUser
                    ).then(function () {
                        Analytics.trackEvent(
                            "Request for update",
                            "click",
                            "requested " + numOfDeliverables
                        );
                        return _showSuccessToastForRequestForProgress(
                            numOfDeliverables,
                            result.responsibleUser.displayName
                        );
                    });
                }
            }
        );
    }

    function _showSelectAddresseeDialog($event, numOfDeliverables) {
        var dialog = $mdDialog
            .pickProjectMember({
                targetEvent: $event,
                openFrom: $event.target,
                closeTo: $event.target,
                locals: {
                    warningText:
                        numOfDeliverables > 100
                            ? "DIALOG_REQUEST_FOR_UPDATE_WARNING"
                            : false,
                },
            })
            .title("DIALOG_REQUEST_FOR_UPDATE_TITLE")
            .description("DIALOG_REQUEST_FOR_UPDATE_CONTENT_HEADER")
            .action("ACTION_SEND_REQUEST_FOR_PROGRESS");

        return $mdDialog.show(dialog);
    }

    function _showSuccessToastForRequestForProgress(
        numOfDeliverables,
        userDisplayName
    ) {
        return _showToast("TOAST_REQUEST_FOR_PROGRESS_SUCCESS", {
            deliverables: numOfDeliverables,
            username: userDisplayName,
        });
    }

    function _handleRequestForProgress(responsibleUser) {
        var deliverableSelection = _getDeliverablesSelection();

        return $sbInspectionRequest
            .createRequestForProgress(
                responsibleUser.username,
                deliverableSelection.getPreferredCondition()
            )
            .catch($sbErrorPresenter.catch);
    }

    function onChangeStructure($event) {
        $sbTracking.deliverablesList.fromToolbar.onChangeStructure();

        var deliverableSelection = _getDeliverablesSelection();

        return $sbDialog
            .openAssignStructureDialog($event)
            .then(function (structureLevel) {
                $log.debug("New Structure Assignment:", structureLevel);

                var structureId = structureLevel.id;
                var updateRequest = _updateStructureInDeliverables(
                    structureId,
                    deliverableSelection.getPreferredCondition()
                );

                _showBusyDialogFor(
                    updateRequest,
                    "DIALOG_ASSIGN_STRUCTURE_LOADING_TEXT"
                ).then(function () {
                    _updateDeliverablesListAndClearSelection();
                });
            });
    }

    function onChangeDates() {
        $sbTracking.deliverablesList.fromToolbar.onChangeDates();

        var deliverableSelection = _getDeliverablesSelection();

        return sbDeliverableDateChangeService
            .startChangeWorkflow(
                deliverableSelection,
                _getUniqueTemplateIdFromSelection(deliverableSelection),
                vm.calendar
            )
            .then(function () {
                return _updateDeliverablesList(vm.filters);
            })
            .catch((err) => {
                if (err) {
                    return $sbErrorPresenter.catch(err);
                }
            });
    }

    function onDeleteDeliverables() {
        var deliverableSelection = _getDeliverablesSelection();

        var numDeliverablesToDelete;
        if (deliverableSelection.isSelectAllActive()) {
            numDeliverablesToDelete = vm.filteredDeliverableCount;
        } else {
            numDeliverablesToDelete =
                deliverableSelection.getLocalSelection().length;
        }

        return $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .title("DIALOG_DELETE_DELIVERABLES_TITLE")
                    .content("DIALOG_DELETE_DELIVERABLES_TEXT_N")
                    .contentValues({
                        items: numDeliverablesToDelete,
                    })
                    .ok("DIALOG_DELETE_DELIVERABLES_OK")
            )
            .then(function () {
                var deleteRequest = _deleteDeliverables(
                    deliverableSelection.getPreferredCondition()
                );
                return _showBusyDialogFor(
                    deleteRequest,
                    "DIALOG_DELETE_DELIVERABLES_LOADING_TEXT"
                );
            })
            .catch(function (e) {
                if (!_.isUndefined(e)) {
                    return $sbErrorPresenter.catch(e);
                }
            });
    }

    function intercept(condition, onCheckPassed, otherwise) {
        return function () {
            if (condition.apply(null, arguments)) {
                return onCheckPassed();
            }

            return otherwise.apply(null, arguments);
        };
    }

    function isNonUniqueTemplateSelection() {
        return !vm.isUniqueTemplateSelection;
    }

    function showExplanatoryDialogForDeliverableTypeSelection() {
        Analytics.trackEvent(
            "Known Errors",
            "Error",
            "Batch change blocked due to Select all"
        );

        return $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_DELIVERABLE_TYPE_SELECTION_TITLE")
                .content("DIALOG_DELIVERABLE_TYPE_SELECTION_MESSAGE")
                .ok("DIALOG_ALERT_OK")
        );
    }

    function onBulkUpdate($event) {
        $sbDialog.pickFromBulkUpdateChoices($event, {
            bulkStateChange: function () {
                $sbTracking.deliverablesList.fromToolbar.onBulkStateChange();
                vm.onBulkStateChangeClick($event);
            },
            bulkNoteCreation: function () {
                $sbTracking.deliverablesList.fromToolbar.onBulkNoteCreation();
                vm.onBulkNoteCreateClick($event);
            },
        });
    }

    function onBulkStateChangeClick($event) {
        var deliverableSelection = _getDeliverablesSelection();

        return $sbDialog
            .openBulkStateChangeDialog(
                $event,
                deliverableSelection,
                _getUniqueTemplateIdFromSelection(deliverableSelection)
            )
            .then(function () {
                $sbTracking.progress.change.done(
                    "Details Overlay",
                    "Batch update"
                );

                return _updateDeliverablesListAndClearSelection();
            })
            .catch(function (error) {
                if (error) {
                    return $sbErrorPresenter.catch(error);
                }
                // if no error it was dialog cancel
            });
    }

    /**
     * Called whether the dialog for bulk note creation is created
     *
     * @param extensions Extensions available for attachments
     * @returns {string[]} Override extension collection
     */
    function overrideBulkNoteFileExtensionsCallback(extensions) {
        const extensionsCopy = [...extensions];
        return extensionsCopy.filter(function (x) {
            return x.toLowerCase() !== EXTENSION_PDF;
        });
    }

    function onBulkNoteCreateClick($event) {
        var deliverableSelection = _getDeliverablesSelection();
        var _selectedActivity;

        return $q
            .all({
                selectedActivity: $sbDialog.openBulkCreateNoteDialog(
                    $event,
                    _getUniqueTemplateIdFromSelection(deliverableSelection)
                ),
                teams: $sbTeam.getTeams($sbProject.getCurrentProjectId()),
            })
            .then(function (values) {
                _selectedActivity = values.selectedActivity;

                return $sbIssueEditorService.showForActivity(
                    _selectedActivity.assignedTeam,
                    values.teams,
                    overrideBulkNoteFileExtensionsCallback
                );
            })
            .then(function (issue) {
                let deliverableSet =
                    deliverableSelection.getPreferredCondition();

                if (!deliverableSelection.isSelectAllActive()) {
                    deliverableSet =
                        _getSelectedDeliverableIds(deliverableSelection);
                }

                var components = {
                    deliverableSet,
                    activityTemplateIds: [_selectedActivity.id],
                    projectId: $sbProject.getCurrentProjectId(),
                };

                return $sbIssue.createNoteAndAssignTo(issue.note, components);
            })
            .then(function () {
                $sbTracking.note.creation.start(
                    "Details Overlay",
                    "Created a batch of Notes"
                );
                vm.selectionProvider.clearSelection();
            })
            .catch(function (error) {
                if (!_.isUndefined(error)) {
                    return $sbErrorPresenter.catch(error);
                } else {
                    // dialog was cancelled
                }
            });
    }

    function _getSelectedDeliverableIds(deliverableSelection) {
        return deliverableSelection
            .getLocalSelection()
            .map(function (currentValue) {
                return currentValue.id;
            });
    }

    function onActivityConfigureClick($event) {
        var deliverableSelection = _getDeliverablesSelection();

        return $sbDeliverableTemplateAssign.startBulkActivityConfigurationWorkflow(
            $event,
            deliverableSelection,
            _getUniqueTemplateIdFromSelection(deliverableSelection)
        );
    }

    function onDeliverableConfigureClick($event) {
        return $mdDialog
            .show(
                $mdDialog.deliverableSetConfiguration({
                    targetEvent: $event,
                    openFrom: $event.target,
                    closeTo: $event.target,
                })
            )
            .then(({ key }) => {
                switch (key) {
                    case "BULK_ASSIGN_TEMPLATE": {
                        return vm.onAssignTemplate($event);
                    }
                    case "BULK_ASSIGN_STRUCTURE": {
                        return vm.onChangeStructure($event);
                    }
                    case "BULK_CHANGE_DATES": {
                        return vm.onChangeDates($event);
                    }
                    case "BULK_ASSIGN_DELIVERABLE_TYPE": {
                        return vm.onAssignType($event);
                    }
                    case "BULK_ASSIGN_RESPONSIBLE_MANAGER": {
                        // ADMIN only feature
                        //
                        // - Only visible in dialog for admins and
                        //   can only be triggered if user is an admin
                        //
                        return vm.onAssignAreaManager($event);
                    }
                    case "BULK_DELETE": {
                        return vm.onDeleteDeliverables($event);
                    }
                    default: {
                        $log.debug(
                            `Unsupported operation. Key '${key}' does not exist.`
                        );
                    }
                }
            });
    }

    /**
     * Find the common template of all the selected deliverables
     * Returns false in case of multiple templates selection
     *
     * @param {DeliverableSelection} deliverableSelection
     * @return {String|boolean} - unique template id | false if it's not unique selection
     */
    function _getUniqueTemplateIdFromSelection(deliverableSelection) {
        // if template filter is active -> they are unique
        if (_filterContainsCriteria(vm.filters, "workflow")) {
            const templateFilter = _.find(vm.filters, { key: "workflow" });
            return _.get(templateFilter, "value[0]", false);
        }

        // if there active single selections
        if (deliverableSelection.hasAllSelectionsLocally()) {
            const deliverables = deliverableSelection.getLocalSelection();

            // nothing selected yet
            if (deliverables.length < 1) {
                return false;
            }

            const templateId = deliverables[0].piTemplateId;
            if (!templateId) {
                return false;
            }
            // they should all share the exact same template
            const allTheSame = deliverables.every(
                (deliverable) => deliverable.piTemplateId === templateId
            );
            if (allTheSame) {
                return templateId;
            }
        }

        return false;
    }

    function _showBusyDialogFor(workload, text, title) {
        if (!angular.isArray(workload)) {
            workload = [workload];
        }

        return $mdDialog.show(
            $mdDialog
                .busyIndication()
                .title(title || "DIALOG_MERGE_STRUCTURE_LOADING_TITLE")
                .text(text)
                .allPromises(workload)
        );
    }

    function _deleteDeliverables(deliverables) {
        const projectId = $sbProject.getCurrentProjectId();
        return $sbDeliverables
            .removeDeliverables(deliverables, projectId)
            .then(function (response) {
                $sbProject.refreshCurrent();
                return response;
            })
            .then(function (response) {
                _showToast("TOAST_DELETE_DELIVERABLES_SUCCESS", {
                    items: response.deliverables.length,
                });
                _updateDeliverablesList(vm.filters);
                vm.totalDeliverableCount -= response.deliverables.length;
            })
            .catch(function (e) {
                $log.error(e);
                $sbErrorPresenter.catch(e);
            });
    }

    function _updateStructureInDeliverables(structureId, deliverables) {
        var projectId = $sbProject.getCurrentProjectId();

        return $sbComponent
            .updateComponentsStructure(deliverables, projectId, structureId)
            .then(function (result) {
                _showToast("ACTION_ASSIGN_STRUCTURE_SUCCESS_N", {
                    deliverables:
                        result && result.deliverables
                            ? result.deliverables.length
                            : "-",
                });
            })
            .catch(function (e) {
                $log.error(e);
                $sbErrorPresenter.catch(e);
            });
    }

    function _updateAreaManagerInDeliverables(user, deliverables) {
        var projectId = $sbProject.getCurrentProjectId();

        return $sbComponent
            .updateComponentsAreaManager(deliverables, projectId, user.username)
            .then(function (result) {
                $sbTracking.constructionManager.onDeliverablesAssigned();

                _showToast("ACTION_ASSIGN_AREA_MANAGER_SUCCESS_N", {
                    displayName: user.displayName,
                    deliverables:
                        result && result.deliverables
                            ? result.deliverables.length
                            : "-",
                });
            })
            .then(_initFilterMenuSettings)
            .catch(function (e) {
                if (
                    e.message ===
                    "ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED"
                ) {
                    e.title =
                        "ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED_TITLE";

                    $sbTracking.constructionManager.onKnownIssue(
                        "Tried to assign CM from different team"
                    );
                }
                return $sbErrorPresenter.showDialog(e);
            });
    }

    function showUrlLimitExceededAlert() {
        Analytics.trackEvent(
            "Known Errors",
            "Error",
            "Structure Filter URL to long"
        );

        return $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_TITLE")
                .content("DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_CONTENT")
        );
    }

    function closeFilterPanel() {
        if (vm.filterPanelRef) {
            vm.filterPanelRef.close();
        }
    }

    function handleUrlLimitExceededException() {
        closeFilterPanel();

        return showUrlLimitExceededAlert().then(_resetStructureFilterAndUpdate);
    }

    /**
     * Create a new loader from the current view filters and update view model.
     *
     * @param {ViewFilter[]} filters - An array with view filter objects. Representing current filter state.
     * @private
     */
    function _updateDeliverablesList(filters) {
        var orderDefinition = {
            direction: vm.orderBy.direction,
            criteria: vm.columns[vm.orderBy.criteria].oDataKey,
        };

        try {
            const filterFactory = $sbODataViewFilter.combinedOData(filters);
            vm.resource = "DELIVERABLES_PROGRESS";
            vm.filterFactory = filterFactory;
            vm.order = orderDefinition;

            vm.selectionProvider.clearSelection();

            // unset the lazy loading state
            _allDeliverablesIfAvailable = undefined;
        } catch (e) {
            if (e.name === "UrlLimitExceededException") {
                handleUrlLimitExceededException(filters);
            } else {
                throw e;
            }
        }
    }

    /**
     * Check if the filter with name "key" is active.
     *
     * @param {Array.<Object>} filters - An array with view filter objects. Representing current filter state.
     * @returns {Boolean} If "key" filter is Active
     * @private
     */
    function _filterContainsCriteria(filters, key) {
        return filters.some(function (vf) {
            return vf.key === key && vf.hasValue();
        });
    }

    /**
     * Lazily resolves all view filter configurations.
     *
     * @returns {Promise} Array with view filters.
     * @private
     */
    function _resolveAllViewFilters() {
        return $q.all([
            $sbDeliverablesFilter.nameCodeDescFilter(),
            $sbDeliverablesFilter.nameFilter(),
            $sbDeliverablesFilter.codeFilter(),
            $sbDeliverablesFilter.descriptionFilter(),
            $sbDeliverablesFilter.structureFilter(),
            $sbDeliverablesFilter.templateFilter(),
            $sbDeliverablesFilter.workScheduledDateFilter(),
            $sbDeliverablesFilter.startDateFilter(),
            $sbDeliverablesFilter.endDateFilter(),
            $sbDeliverablesFilter.progressFilter(),
            $sbDeliverablesFilter.typeFilter(true),
            $sbDeliverablesFilter.scheduleFilter(),
            $sbDeliverablesFilter.noteFilter(),
            $sbDeliverablesFilter.inspectionDateFilter(),
            $sbDeliverablesFilter.areaManagerFilter(),
        ]);
    }

    /**
     * Set the current orderBy configuration.
     *
     * @param {String} criteria - The currently sorted criteria.
     * @param {Number} direction - The sort direction (ascending or descending).
     * @private
     */
    function _setSortConfig(criteria, direction) {
        vm.orderBy = {
            criteria: criteria,
            direction: direction,
        };
        // update columns data based
        vm.columns[criteria].order = direction;
    }

    function _initFilterMenuSettings() {
        $rootScope.$broadcast(EVENTS.AREA_MANAGER_CHANGED);
    }

    function _trackFilterChanges(filters) {
        _trackedFilters = $sbViewFiltersService.trackFilterChanges(
            filters,
            _trackedFilters,
            _blackListFilters,
            $sbTracking.deliverablesList.filters.changeFilterCondition
        );
    }
}
