import moment from "moment";
import Chart from "chart";
import _ from "lodash";
import html from "./s_curve_chart.html";
import {
    actual_work_finished,
    actual_completed,
    planed_as_completed,
    actual_confirmed,
    activity_scheduling_unscheduled,
} from "../../../../colorpalette";

export default {
    templateUrl: html,
    bindings: {
        chartData: "<", // object with array of labels, array of planned numbers, array of actual numbers
        isLoading: "<",
    },
    controllerAs: "vm",
    controller: function SCurveChart(
        $element,
        $rootScope,
        $filter,
        $sbColor,
        $sbLocale
    ) {
        "ngInject";

        const MAX_DATA_POINTS_WITH_VISIBLE_POINT = 50;
        const VISIBLE_POINT_SIZE = 3;

        var vm = this;

        // find the canvas to draw onto
        var context = $element.children()[0].getContext("2d");
        var translate = $filter("translate");
        var DATASET_COLOR = {
            PLANNED: planed_as_completed,
            FINISHED: actual_work_finished,
            CONFIRMED: actual_confirmed,
            COMPLETED: actual_completed,
            UNPLANNED: activity_scheduling_unscheduled,
        };
        var OPACITY = 10;

        vm.chart = undefined;
        vm.isLoading = true;
        vm.isEmpty = false;

        vm.$onInit = $onInit;
        vm.$onChanges = $onChanges;

        // private for testing
        vm._limitDataSetByDate = limitDataSetByDate;

        /////
        //  watcher
        //
        $rootScope.$on("$translateChangeSuccess", updateTranslation);

        /////
        //  impl
        //
        function $onInit() {
            // create the chart
            vm.chart = createDefaultChart(context);
        }

        function $onChanges(changeObject) {
            // only if we have chart data
            //
            if (_isChartData(changeObject) && _areDataPoints(changeObject)) {
                var chartData = changeObject.chartData.currentValue;

                vm.chart.data = {
                    labels: chartData.labels,
                    datasets: [],
                };

                const pointSize =
                    chartData.labels.length > MAX_DATA_POINTS_WITH_VISIBLE_POINT
                        ? 0
                        : VISIBLE_POINT_SIZE;

                const numberOfUnplanned = _.get(chartData, "unplanned[0]", 0);

                // append confirm if applicable
                //
                /* hide confirm line: https://sablono.atlassian.net/browse/SAB-4568
                if (chartData.confirmed) {
                    vm.chart.data.datasets.push({
                        type: "line",
                        data: limitDataSetByDate(
                            chartData.labels,
                            chartData.confirmed,
                            moment()
                        ),
                        tension: 0,
                        borderColor: DATASET_COLOR.CONFIRMED,
                        pointRadius: pointSize,
                        backgroundColor: $sbColor.hexToRgba(
                            DATASET_COLOR.CONFIRMED,
                            OPACITY
                        ),
                        label: translate("DASHBOARD_CHARTS_LEGEND_CONFIRMED"),
                    });
                }*/

                if (chartData.completed) {
                    vm.chart.data.datasets.push({
                        type: "line",
                        data: limitDataSetByDate(
                            chartData.labels,
                            chartData.completed,
                            moment()
                        ),
                        tension: 0,
                        borderColor: DATASET_COLOR.COMPLETED,
                        pointRadius: pointSize,
                        backgroundColor: $sbColor.hexToRgba(
                            DATASET_COLOR.COMPLETED,
                            OPACITY
                        ),
                        label: translate("DASHBOARD_CHARTS_LEGEND_COMPLETED"),
                    });
                }

                vm.chart.data.datasets.push(
                    {
                        type: "line",
                        data: limitDataSetByDate(
                            chartData.labels,
                            chartData.finished,
                            moment()
                        ),
                        tension: 0,
                        borderColor: DATASET_COLOR.FINISHED,
                        pointRadius: pointSize,
                        backgroundColor: $sbColor.hexToRgba(
                            DATASET_COLOR.FINISHED,
                            OPACITY
                        ),
                        label: translate("DASHBOARD_CHARTS_LEGEND_FINISHED"),
                    },
                    {
                        type: "line",
                        data: chartData.planned.map(
                            (r) => r + numberOfUnplanned
                        ),
                        tension: 0,
                        borderColor: DATASET_COLOR.PLANNED,
                        pointRadius: pointSize,
                        backgroundColor: $sbColor.hexToRgba(
                            DATASET_COLOR.PLANNED,
                            OPACITY
                        ),
                        label: translate("DASHBOARD_CHARTS_LEGEND_PLANNED"),
                    }
                );

                if (numberOfUnplanned > 0) {
                    vm.chart.data.datasets.push({
                        type: "line",
                        data: chartData.unplanned,
                        tension: 0,
                        borderColor: DATASET_COLOR.UNPLANNED,
                        borderWidth: 2,
                        pointRadius: pointSize,
                        backgroundColor: $sbColor.hexToRgba(
                            DATASET_COLOR.UNPLANNED,
                            OPACITY
                        ),
                        label: translate("DASHBOARD_CHARTS_LEGEND_UNPLANNED"),
                    });
                }

                updateTranslation();

                vm.isLoading =
                    (changeObject.isLoading &&
                        changeObject.isLoading.currentValue) ||
                    false;
                vm.isEmpty = false;
            }

            // if data has been loaded but there are no data points
            //
            if (_isChartData(changeObject) && !_areDataPoints(changeObject)) {
                vm.isEmpty = true;
            }
        }

        /**
         * Remove points from the data sets if the label (time) is after the given date
         *
         * @param {Array} labels
         * @param {Array<number>} data
         * @param {moment} dateLimit
         * @returns {Array<number>}
         */
        function limitDataSetByDate(labels, data, dateLimit) {
            var indexOfToday = _.findIndex(labels, function (label) {
                return moment(label).isAfter(dateLimit);
            });
            if (indexOfToday > -1) {
                return data.slice(0, indexOfToday + 1);
            } else {
                return data;
            }
        }

        /**
         * Update all labels and strings that need translation
         */
        function updateTranslation() {
            const i18nWeek = translate("_WEEK_OF_YEAR");
            const dateFormat = $sbLocale.is($sbLocale.de_DE) ? "w - YYYY" : "L";

            // translate the scales
            //
            vm.chart.options.scales.xAxes[0].scaleLabel = {
                display: true,
                labelString: i18nWeek,
            };
            vm.chart.options.scales.yAxes[0].scaleLabel = {
                display: true,
                labelString: translate("_ACTIVITY_NUMBER"),
            };

            // change the tooltip title from "40 - 2017" (that's the xLabel) to "Week 40 - 2017"
            //
            vm.chart.options.tooltips.callbacks.title = function (dataPoint) {
                return (
                    i18nWeek +
                    " " +
                    moment(dataPoint[0].xLabel).format(dateFormat)
                );
            };

            vm.chart.options.scales.xAxes[0].time.displayFormats = {
                week: dateFormat,
            };

            vm.chart.update();
        }

        /**
         * Create the chart with default setup
         * @param {Element} context
         * @returns {Chart}
         */
        function createDefaultChart(context) {
            return new Chart(context, {
                type: "line",
                options: {
                    fontSize: 14,
                    fontFamily: "Work Sans, Helvetica Neue, sans-serif",
                    scales: {
                        xAxes: [
                            {
                                type: "time",
                                bounds: "ticks",
                                time: {
                                    unit: "week",
                                },
                            },
                        ],
                    },
                    responsive: true,
                    title: {
                        display: false,
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false,
                    },
                    legend: {
                        display: true,
                        fullWidth: true,
                        position: "top",
                        onClick: angular.noop,
                        labels: {
                            boxWidth: 14,
                        },
                    },
                },
            });
        }

        function _isChartData(changeObject) {
            return _.get(changeObject, "chartData.currentValue");
        }

        function _areDataPoints(changeObject) {
            return (
                _.get(changeObject, "chartData.currentValue.planned.length") > 0
            );
        }
    },
};
