import angular from "angular";
import _ from "lodash";
import ODataFilterFactory from "common/services/oDataService/odata_filter_factory.class";
import {
    DocumentAttachment,
    ImageAttachment,
} from "../../common/ui-elements/components/sbAttachmentGallery/gallery_attachment.model";

export default function IssuesCtrl(
    $sbProject,
    $scope,
    $rootScope,
    $sbDates,
    $sbIssue,
    $sbIssuesFilter,
    $sbODataViewFilter,
    $sbODataViewFilterStore,
    $sbErrorPresenter,
    EVENTS,
    Analytics,
    $q,
    $stateParams,
    ODATA_ORDER,
    $state,
    $sbDetailsOverlay,
    $mdDialog,
    $sbTracking,
    DELIVERABLE_OVERLAY_TAB,
    ISSUES_VIEW_FILTER_STORE_KEY,
    $sbViewFiltersService,
    $sbNotesApi,
    $mdToast,
    $sbIssueEditorService,
    SbNote,
    $sbTeam,
    $sbPermission
) {
    "ngInject";
    var vm = this;

    var oDataCurrentProjectFilter = new ODataFilterFactory().eq(
        "PROJECT_ID",
        $sbProject.getCurrentProjectId()
    );

    var viewFilterStore = $sbODataViewFilterStore.getStore(
        $state.$current.name,
        ISSUES_VIEW_FILTER_STORE_KEY,
        $sbProject.getCurrentProjectId()
    );

    var _trackedFilters = []; // Used to enable GA tracking on filters

    var _blackListFilters = ["areaManagerFilter"];

    vm.timezone;
    vm.workTimes;

    vm.filters = [];

    vm.issueCount = {
        total: 0,
        filtered: undefined,
    };

    var defaultOrder = {
        criteria: "lastUpdateDate",
        direction: ODATA_ORDER.DESC,
    };

    // columns for data table
    vm.columns = {
        status: {
            oDataKey: "ISSUE_STATUS",
            criteria: "_ISSUE_STATUS", // i18n keys
            order: ODATA_ORDER.UNSORTED, // sort direction
        },
        text: {
            oDataKey: "ISSUE_TEXT",
            criteria: "_NOTE_TEXT",
            order: ODATA_ORDER.UNSORTED,
        },
        code: {
            oDataKey: "ISSUE_NAME",
            criteria: "_CODE", // i18n keys
            order: ODATA_ORDER.UNSORTED, // sort direction
        },
        rootComponentName: {
            oDataKey: "ROOT_COMPONENT_NAME",
            criteria: "_NAME",
            order: ODATA_ORDER.UNSORTED,
        },
        rootComponentCode: {
            oDataKey: "ROOT_COMPONENT_CODE",
            criteria: "_CODE",
            order: ODATA_ORDER.UNSORTED,
        },
        rootComponentDesc: {
            oDataKey: "ROOT_COMPONENT_DESC",
            criteria: "_DESC",
            order: ODATA_ORDER.UNSORTED,
        },
        lastUpdateDate: {
            oDataKey: "LAST_UPDATE_TIME",
            criteria: "_DATE",
            order: ODATA_ORDER.UNSORTED,
        },
    };

    // menu for area manager toggle switch
    //
    vm.filterMenu = {
        lastCallbackEvent: undefined,
        onToggleAreaManagerFilter: onToggleAreaManagerFilter,
        onViewModelSetUpComplete: loadAndSetFilters,
    };

    vm.userSelectedOrderCriteriaKey;
    vm.userSelectedOrderDirection;

    vm.orderBy;

    vm.resource = "ISSUES";
    vm.filterFactory;
    vm.shouldAllowEditing = false;

    vm.onSortChange = onSortChange;
    vm.onClickOnIssueRow = onClickOnIssueRow;
    vm.onCountChange = onCountChange;
    vm.changeFilterCondition = changeFilterCondition;
    vm.onClickOnPrintReport = onClickOnPrintReport;
    vm.onEditIssue = onEditIssue;
    vm.onDeleteIssue = onDeleteIssue;

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    // Try to open the overlay only if the content is loaded
    $scope.$watch("issues.issueCount.filtered", function (newValue, oldValue) {
        // only open the overlay if filteredDeliverableCount is changed the first time.
        //  from undefined -> to a number
        if (newValue && oldValue === undefined) {
            $sbDetailsOverlay.openFromStateParamsIfNeeded($stateParams);
        }
    });

    $rootScope.$on(EVENTS.NOTE_CHANGE, reloadIssues);

    /////////////////////
    //
    //      Lifecycle Hooks
    //          docu: https://toddmotto.com/angular-1-5-lifecycle-hooks
    //
    /////////////////////

    vm.$onInit = $onInit;

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function $onInit() {
        loadTotalIssueCount();

        $sbProject.getCalendar().then(function (result) {
            vm.timezone = result.TIMEZONE;
            vm.workTimes = $sbDates.parseCalendarToWorkTimes(result);
        });

        $sbTeam.getTeams($sbProject.getCurrentProjectId()).then((teams) => {
            vm.teams = teams;
        });

        if (
            $sbPermission.hasAdminPermissions(
                $sbProject.getCurrent().privileges
            )
        ) {
            vm.shouldAllowEditing = true;
        }
    }

    function loadAndSetFilters($bgMenuEvent) {
        _setLastEvent($bgMenuEvent);

        return _resolveAllViewFilters()
            .then(function (filters) {
                // load sort order
                var order = viewFilterStore.getOrderBy() || defaultOrder;

                // apply
                setOrder(order.criteria, order.direction);

                // save
                viewFilterStore.setOrderBy(order.criteria, order.direction);

                // load filter params from url or local store
                var filterParams = viewFilterStore.getFilterParams();

                // apply filter params
                $sbODataViewFilter.setViewFilterValuesFrom(
                    filterParams,
                    filters
                );

                // apply area manager filter based on background menu settings
                var areaManagerViewFilter = findFilterByKey(
                    filters,
                    "areaManagerFilter"
                );
                var removedIssuesViewFilter = findFilterByKey(
                    filters,
                    "removed"
                );

                _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter);
                //exclude deleted issues by default
                _updateRemovedIssuesFilter(removedIssuesViewFilter);

                // store filter params in url and local store
                viewFilterStore.setFilterParams(filters, false);

                vm.filters = filters;

                vm.filters.forEach(function (filter) {
                    if (filter.hasValue()) {
                        _trackedFilters.push(filter);
                    }
                });

                vm.filterFactory = getODataFilterFactory();
            })
            .catch($sbErrorPresenter.catch);
    }

    function _setLastEvent($bgMenuEvent) {
        vm.filterMenu.lastCallbackEvent = $bgMenuEvent;
    }

    function onToggleAreaManagerFilter($bgMenuEvent) {
        $sbTracking.filterMenu.byConstructionManager("Issues");

        _setLastEvent($bgMenuEvent);

        var areaManagerViewFilter = findFilterByKey(
            vm.filters,
            "areaManagerFilter"
        );

        _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter);

        changeFilterCondition();
    }

    function _updateAreaManagerFilter($bgMenuEvent, areaManagerViewFilter) {
        if ($bgMenuEvent.hasAreaManagerRestrictions && $bgMenuEvent.isActive) {
            areaManagerViewFilter.setValue($bgMenuEvent.userName);
        } else {
            areaManagerViewFilter.clear();
        }
    }

    function _updateRemovedIssuesFilter(removedIssuesViewFilter) {
        if (!removedIssuesViewFilter.hasValue()) {
            removedIssuesViewFilter.setValue(["notRemoved"]);
        }
    }

    function loadTotalIssueCount() {
        return $sbIssue
            .getIssueCount(oDataCurrentProjectFilter)
            .then(function (issueCount) {
                vm.issueCount.total = issueCount;
                return issueCount;
            });
    }

    function onCountChange(numOfFilteredItems) {
        vm.issueCount.filtered = numOfFilteredItems;
    }

    function setOrder(criteria, direction) {
        if (angular.isUndefined(vm.columns[criteria])) {
            return;
        }
        vm.userSelectedOrderCriteriaKey = criteria;
        vm.userSelectedOrderDirection = direction;
        vm.orderBy = getODataOrder(criteria, direction);
        var column = vm.columns[criteria];
        if (column) {
            column.order = direction;
        }
    }

    function getODataOrder(columnKey, sortDirection) {
        var column = vm.columns[columnKey];
        return {
            criteria: column.oDataKey,
            direction: sortDirection,
        };
    }

    function findFilterByKey(viewFilters, key) {
        return _.find(viewFilters, function (viewFilter) {
            return viewFilter.key === key;
        });
    }

    function _resetStructureFilterAndUpdate() {
        var structureFilter = findFilterByKey(vm.filters, "structure");
        structureFilter.value = [];

        changeFilterCondition();
        $onInit();
        loadAndSetFilters(vm.filterMenu.lastCallbackEvent);
    }

    function showUrlLimitExceededAlert() {
        return $mdDialog.show(
            $mdDialog
                .alert()
                .title("DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_TITLE")
                .content("DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_CONTENT")
        );
    }

    function handleUrlLimitExceededException() {
        if (vm.filterPanelRef) {
            vm.filterPanelRef.close();
        }

        return showUrlLimitExceededAlert().then(_resetStructureFilterAndUpdate);
    }

    function getODataFilterFactory() {
        try {
            return $sbODataViewFilter.combinedOData(vm.filters);
        } catch (e) {
            if (e.name === "UrlLimitExceededException") {
                handleUrlLimitExceededException(vm.filters);
            } else {
                throw e;
            }
        }
    }

    function onSortChange(criteriaKey, sortDirection) {
        Analytics.trackEvent("Issue Sort", "Change", criteriaKey);
        setOrder(criteriaKey, sortDirection);
        viewFilterStore.setOrderBy(criteriaKey, sortDirection);
    }

    /**
     * Central point to manage changes to the filter state.
     * This is called by filter panel changes.
     *
     */
    function changeFilterCondition() {
        vm.filterFactory = getODataFilterFactory();
        viewFilterStore.setFilterParams(vm.filters, true);
        _trackFilterChanges(vm.filters);
    }

    function reloadIssues() {
        // we change an attribute of the odata list directive to trigger a change event
        vm.filterFactory = getODataFilterFactory();

        // reload issue count
        loadTotalIssueCount();
    }

    function onClickOnIssueRow(deliverableId) {
        $sbDetailsOverlay.toggleView(
            "deliverable",
            deliverableId,
            DELIVERABLE_OVERLAY_TAB.NOTES
        );
    }

    function onClickOnPrintReport($event) {
        var reportOdata = $sbODataViewFilter.generateFilterExpress(
            vm.filters,
            vm.columns,
            {
                criteria: vm.userSelectedOrderCriteriaKey,
                direction: vm.userSelectedOrderDirection,
            }
        );

        $mdDialog.show(
            $mdDialog
                .issueReportDialog({
                    targetEvent: $event,
                })
                .issueCount(vm.issueCount)
                .filter(vm.filterFactory)
                .oDataFilter(reportOdata.query)
                .filterDescription(reportOdata.activeFilterWithoutHiddenFields)
        );
    }

    /**
     * Lazily resolves all view filter configurations.
     *
     * @returns {Promise} Array with view filters.
     * @private
     */
    function _resolveAllViewFilters() {
        const viewFilters = [
            $sbIssuesFilter.issueTypeFilter(true),
            $sbIssuesFilter.issueStatusFilter(true),
            $sbIssuesFilter.issueTextFilter(false),
            $sbIssuesFilter.issueKeyFilter(false),
            $sbIssuesFilter.issueTemplateKeyFilter(false),
            $sbIssuesFilter.removedIssuesFilter(false),
            $sbIssuesFilter.responsibleTeamFilter(
                $sbProject.getCurrentProjectId(),
                true
            ),
            $sbIssuesFilter.confirmationTeamFilter(
                $sbProject.getCurrentProjectId(),
                true
            ),
            $sbIssuesFilter.structureFilter(true),
            $sbIssuesFilter.deliverableNameCodeDescFilter(true),
            $sbIssuesFilter.deliverableNameFilter(false),
            $sbIssuesFilter.deliverableCodeFilter(false),
            $sbIssuesFilter.deliverableDescFilter(false),
            $sbIssuesFilter.deliverableProcessFilter(false),
            $sbIssuesFilter.deliverableTypeFilter(false),
            $sbIssuesFilter.activityNameDescFilter(true),
            $sbIssuesFilter.activityNameFilter(false),
            $sbIssuesFilter.activityDescFilter(false),
            $sbIssuesFilter.issueAuthorFilter(true),
            $sbIssuesFilter.issueCreatedDateFilter(true),
            $sbIssuesFilter.lastIssueUpdateDateFilter(true),

            $sbIssuesFilter.areaManagerFilter(false), // in side menu
        ];

        return $q.all(viewFilters);
    }

    function _trackFilterChanges(filters) {
        _trackedFilters = $sbViewFiltersService.trackFilterChanges(
            filters,
            _trackedFilters,
            _blackListFilters,
            $sbTracking.note.filters.changeFilterCondition
        );
    }

    function onEditIssue({ issue }) {
        const noteToEdit = new SbNote();

        $sbNotesApi
            .get($sbProject.getCurrentProjectId(), issue.ISSUE_ID)
            .then((apiNote) => {
                noteToEdit.id = apiNote.id;
                noteToEdit.text = apiNote.text;
                noteToEdit.setResponsibleTeam(issue.RESPONSIBLE_TEAM);
                noteToEdit.setConfirmationTeam(issue.CONFIRMATION_TEAM);
                noteToEdit.setType(apiNote.type);

                noteToEdit.images = apiNote.image_ids.map(
                    (id) => new ImageAttachment(id)
                );

                noteToEdit.attachments = apiNote.attachments.map(
                    (attachment) =>
                        new DocumentAttachment(
                            attachment.id,
                            attachment.file.name
                        )
                );
                return noteToEdit;
            })
            .then((note) => {
                return $sbIssueEditorService.showForExistingNote(
                    _.cloneDeep(note),
                    vm.teams
                );
            })
            .then(({ note }) => {
                trackNoteChanges(noteToEdit, note);
                const payload = {
                    type: note.type,
                    text: note.text,
                    imageIds: note.images.map((image) => image.id),
                    attachmentIds: note.attachments.map(
                        (attachment) => attachment.id
                    ),
                };

                const responsibleTeamId = _.get(note, "responsibleTeam.id");
                if (_.isNumber(responsibleTeamId)) {
                    payload.responsibleTeamId = responsibleTeamId;
                }
                const confirmationTeamId = _.get(note, "confirmationTeam.id");
                if (_.isNumber(confirmationTeamId)) {
                    payload.confirmationTeamId = confirmationTeamId;
                }

                return $sbNotesApi.update(
                    $sbProject.getCurrentProjectId(),
                    note.id,
                    payload
                );
            })
            .then(() => {
                // This can be handled better with the EVENTS.NOTE_CHANGED emmiter
                // It already seems somehow broken though, so I went with the cheap
                // Option but this would be a nice followup
                reloadIssues();

                return $mdToast.show(
                    $mdToast
                        .simple()
                        .content("DIALOG_EDIT_ISSUE_SUCCESS")
                        .position("top right")
                );
            })
            .catch((err) => {
                if (err) {
                    $sbErrorPresenter.catch(err);
                }
            });
    }

    function onDeleteIssue({ row }) {
        $mdDialog
            .show(
                $mdDialog
                    .confirm()
                    .titleIcon("mdi mdi-alert")
                    .title("ACTION_TEMPLATE_DELETE_NOTE")
                    .content("CONFIRM_DELETE_NOTE_MESSAGE")
            )
            .then(function () {
                vm.loading = true;
                $sbTracking.note.edit("Delete", "Note");
                return $sbNotesApi
                    .delete(
                        $sbProject.getCurrentProjectId(),
                        row.issue.ISSUE_ID
                    )
                    .then(() => {
                        vm.loading = false;
                        reloadIssues();
                    });
            })
            .catch((err) => {
                vm.loading = false;
                if (err) {
                    $sbErrorPresenter.catch(err);
                }
            });
    }

    function trackNoteChanges(initialNote, editedNote) {
        if (initialNote.text !== editedNote.text) {
            $sbTracking.note.edit("Change", "Text");
        }

        if (initialNote.type !== editedNote.type) {
            $sbTracking.note.edit("Change", "Type");
        }

        if (
            initialNote.findResponsibleTeamId() !==
            editedNote.findResponsibleTeamId()
        ) {
            $sbTracking.note.edit("Change", "responsible team");
        }

        if (
            initialNote.findConfirmationTeamId() !==
            editedNote.findConfirmationTeamId()
        ) {
            $sbTracking.note.edit("Change", "confirmation team");
        }

        if (
            !_.isEqual(
                initialNote.images.map((image) => image.id).sort(),
                editedNote.images.map((image) => image.id).sort()
            )
        ) {
            $sbTracking.note.edit("Change", "Image(s)");
        }

        if (
            !_.isEqual(
                initialNote.attachments
                    .map((attachment) => attachment.id)
                    .sort(),
                editedNote.attachments.map((attachment) => attachment.id).sort()
            )
        ) {
            $sbTracking.note.edit("Change", "File(s)");
        }
    }
}
