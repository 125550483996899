import PresentableError from "../../../errors/PresentableError";

class LookAheadProgramPagingSource {
    /**
     * Used to create an instance of LookAheadProgramPagingSource.
     *
     * @param store {Object} Remote endpoint to query.
     * @param store.getCollection {Function}
     *
     * @param config {Object} Configuration to be send as query parameters.
     *
     * @return {LookAheadProgramPagingSource}
     */
    constructor(store, config) {
        this.store = store;
        this.config = config;
    }

    async load(cursor) {
        try {
            const { records, meta } = await this.store.getCollection(
                this.config.projectId,
                {
                    ...this.config,
                    cursor,
                }
            );

            const {
                has_previous_page,
                has_next_page,
                previous_page,
                next_page,
            } = meta.page;

            const total = meta.count_all;

            // next page object
            return {
                total,
                data: records || [],
                key: cursor,
                prevKey: has_previous_page ? previous_page : null,
                nextKey: has_next_page ? next_page : null,
            };
        } catch (e) {
            throw new PresentableError(
                "PAGINATION_FETCH_FROM_SOURCE_ERROR",
                "ERROR_REQUEST_TITLE",
                "PAGINATION_FETCH_FROM_SOURCE_ERROR_MESSAGE"
            );
        }
    }
}

export default LookAheadProgramPagingSource;
