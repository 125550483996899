import { isEmpty } from "lodash";
import html from "./sb_progress_stats.component.html";

export default {
    templateUrl: html,
    bindings: {
        // select data
        onSelect: "&",
        onDeselectAll: "&",
        selected: "<",
        options: "<",
        // chart data
        chartData: "<",
        isLoading: "<",
    },
    controller: function ProgressStatsCtrl() {
        "ngInject";
        var vm = this;

        vm.$onChanges = $onChanges;

        vm.selection = makeSelectionModel();
        vm.onOptionSelected = onOptionSelected;

        function $onChanges({ selected }) {
            if (selected) {
                vm.selection = makeSelectionModel(selected.currentValue);
            }
        }

        function onOptionSelected(select) {
            vm.onSelect({
                selected: select,
            });
        }

        function makeSelectionModel(selectedActivities) {
            if (!Array.isArray(selectedActivities)) {
                return {
                    text: "DASHBOARD_S_CURVE_CHART_SELECTION_NO_MATCH",
                    tooltipText: "DASHBOARD_S_CURVE_CHART_SELECTION_NO_MATCH",
                };
            }

            if (isEmpty(selectedActivities)) {
                return {
                    text: "DASHBOARD_S_CURVE_CHART_SELECTION_EVERYTHING",
                    tooltipText: "DASHBOARD_S_CURVE_CHART_SELECTION_EVERYTHING",
                };
            }

            if (selectedActivities.length === 1) {
                return {
                    text: selectedActivities[0].name,
                    tooltipText: selectedActivities[0].name,
                };
            }

            let tooltipText = selectedActivities
                .map((activity) => activity.name)
                .join("\n");
            if (selectedActivities.length > 10) {
                tooltipText =
                    selectedActivities
                        .slice(0, 10)
                        .map((activity) => activity.name)
                        .join("\n") + "\n ... ";
            }

            return {
                text: "_ACTIVITIES_N",
                tooltipText: tooltipText,
                values: {
                    activities: selectedActivities.length,
                },
            };
        }
    },
};
