import angular from "angular";
import projectOverviewProCtrl from "./project-overview-pro.controller";
import projectOverviewProRoute from "./project-overview-pro.route";
import "angular-ui-router";
import "common/intercom/intercomModule";

export default angular
    .module("sbApp.projectOverviewProModule", [
        "ngMaterial",
        "ui.router",
        "sbApp.intercom",
        "sbApp.directives",
    ])
    .config(projectOverviewProRoute)
    .controller("ProjectOverviewProCtrl", projectOverviewProCtrl);
