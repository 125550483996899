import angular from "angular";
import TemplateBarChartService from "./template_bar_chart.service";
import TemplateBarChartCtrl from "./template_bar_chart.controller";
import html from "./template_bar_chart.component.html";

export default angular
    .module("sbApp.sbTemplateBarChart", [])
    .factory("$sbBarChart", TemplateBarChartService)
    .component("sbTemplateBarChart", {
        templateUrl: html,
        bindings: {
            model: "<",
            options: "<?",
            onBarChartClick: "&",
            isLoading: "<",
            isClickDisabled: "<",
        },
        controller: TemplateBarChartCtrl,
    });
