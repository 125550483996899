import PresentableError from "../../../common/errors/PresentableError";

export default function IssueReportDialogCtrl(
    $mdDialog,
    $scope,
    $sbErrorPresenter,
    Analytics,
    $sbCsv,
    $sbReportingNotesApi,
    $sbCurrentProject,
    $sbLocale,
    ISSUE_REPORT_TYPE
) {
    "ngInject";
    var vm = this;

    vm.hide = $mdDialog.hide.bind($mdDialog, true);
    vm.onChoiceClicked = onChoiceClicked;
    vm.requestNotesReport = requestNotesReport;
    vm.hasReportInProgress = hasReportInProgress;

    var translateValues = {
        filteredIssueCount: 0,
        totalIssueCount: 0,
    };

    $scope.issueCount = this.issueCount;
    $scope.$watch(
        "issueCount",
        function () {
            updateIssueCount();
        },
        true
    );

    function updateIssueCount() {
        translateValues.filteredIssueCount = vm.issueCount.filtered;
        translateValues.totalIssueCount = vm.issueCount.total;
    }

    vm.pdfReportWithImages = {
        key: ISSUE_REPORT_TYPE.NOTES_AND_IMAGES,
        gaLabel: "Issues with photos",
        icon1: "mdi mdi-file-document",
        icon2: "mdi mdi-image",
        customClass: "sb-issue-report__pdf-with-images-choice",
        title: "SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TITLE",
        titleValues: translateValues,
        text: "SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TEXT",
        textValues: translateValues,
        loading: false,
    };
    vm.csvReport = {
        key: ISSUE_REPORT_TYPE.CSV,
        gaLabel: "Issues-CSV",
        icon1: "mdi mdi-file-delimited",
        title: "SECTION_PRINT_CSV_ISSUE_REPORT_TITLE",
        titleValues: translateValues,
        text: "SECTION_PRINT_CSV_ISSUE_REPORT_TEXT",
        textValues: translateValues,
        loading: false,
    };

    vm.reports = [vm.pdfReportWithImages, vm.csvReport];
    vm.isPdfBeingGenerated = false;
    vm.userEmail = "";
    /**
     * Handle the selection of one the defined reports on vm.reports
     *
     * @param {object} report
     * @returns {*}
     */
    function onChoiceClicked(report) {
        if (vm.hasReportInProgress()) {
            return;
        }
        Analytics.trackEvent("PDF Reports", "click", report.gaLabel);
        switch (report.key) {
            case ISSUE_REPORT_TYPE.NOTES_AND_IMAGES:
                vm.pdfReportWithImages.loading = true;
                vm.requestNotesReport({
                    oDataQuery: vm.oDataFilter,
                    filterDescription: vm.filterDescription,
                })
                    .then((response) => {
                        vm.userEmail = response.requester.email;
                    })
                    .catch(_errorHandling)
                    .finally(() => {
                        vm.pdfReportWithImages.loading = false;
                        vm.isPdfBeingGenerated = true;
                    });
                break;
            case ISSUE_REPORT_TYPE.CSV:
                vm.csvReport.loading = true;
                return $sbCsv
                    .downloadAllNotesInCSV()
                    .then(function () {
                        vm.hide();
                    })
                    .catch(function (error) {
                        $sbErrorPresenter.catch(error);
                    })
                    .finally(function () {
                        vm.csvReport.loading = false;
                    });
        }
    }

    function requestNotesReport({ oDataQuery, filterDescription = [] } = {}) {
        return $sbReportingNotesApi.printCollection(
            $sbCurrentProject.pick("id"),
            {
                filter: oDataQuery.filter,
                language: $sbLocale.current(),
                i18n: filterDescription
                    .filter(({ value }) => !!value)
                    .map(({ key, value }) => key + ":" + value),
            }
        );
    }

    function hasReportInProgress() {
        return vm.csvReport.loading || vm.pdfReportWithImages.loading;
    }

    function _errorHandling(err) {
        if (
            err.message === "REQUEST_TIMEOUT" ||
            err.message === "REQUEST_ENTITY_TOO_LARGE_NAME"
        ) {
            err.title = "ERROR_PDF_CREATION_MESSAGE";
            err.message = "ERROR_REQUEST_SIZE_EXCEEDED_MESSAGE";
        }
        if (err.message === "ERROR_NOTE_REPORT_SIZE_EXCEEDS_MAX_SIZE") {
            err.title = "ERROR_PDF_CREATION_MESSAGE";
            err.message = "ERROR_NOTE_REQUEST_SIZE_EXCEEDED_MESSAGE";
        }
        return $sbErrorPresenter.catch(
            err,
            PresentableError.presentationStyle.DIALOG
        );
    }
}
