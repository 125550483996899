import angular from "angular";
import dashboardRouteConfig from "./dashboard.route";
import dashboardCtrl from "./dashboard.controller";
import dashboardService from "./services/dashboard.service";
import DashboardSelectionStoreService from "./services/dashboard-selection-store.service";
import sbCountInfoCard from "./directives/sbCountInfoCard/sb_count_info.directive";
import sbSCurveChart from "./components/sbSCurveChart/s_curve_chart.component";
import sbProductivityBarChart from "./components/sbProductivityBarChart/productivity_bar_chart.component";
import sbTilesChart from "./components/sbTilesChart/tiles_chart.component";
import sbProgressStats from "./components/sbProgressStats/sb_progress_stats.component";
import sbTemplateBarChartModule from "./components/sbTemplateBarChart/template_bar_chart.module";
import "angular-ui-router";
import "common/intercom/intercomModule";
import "common/services/services";
import SelectScurveActivitiesConfig from "./dialogs/select-scurve-activities/select_scurve_activities.config";

export default angular
    .module("sbApp.dashboardModule", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        "sbApp.intercom",
        "sbApp.directives",
        sbTemplateBarChartModule.name,
    ])
    .config(dashboardRouteConfig)
    .config(SelectScurveActivitiesConfig)
    .controller("DashboardCtrl", dashboardCtrl)
    .factory("dashboardService", dashboardService)
    .service("$sbDashboardSelection", DashboardSelectionStoreService)
    .directive("sbCountInfoCard", sbCountInfoCard)
    .component("sbSCurveChart", sbSCurveChart)
    .component("sbProductivityBarChart", sbProductivityBarChart)
    .component("sbTilesChart", sbTilesChart)
    .component("sbProgressStats", sbProgressStats);
/* declaration */
