import _ from "lodash";
import moment from "moment";

export default class ProjectOverviewService {
    constructor($sbPerformanceApi) {
        "ngInject";

        this.$sbPerformanceApi = $sbPerformanceApi;
    }

    /**
     * Fetch metrics aggregated by deliverable type for complete project.
     *
     * @param projectId {string}
     *
     * @returns {Promise<Array<{
     *               id: string,
     *               name: string,
     *               total: number,
     *               behind: number,
     *               planned: number,
     *               finished: number,
     *               confirmed: number,
     *               completed: number,
     *           }>>}
     */
    fetchScheduleStatsGroupedByDeliverableType(projectId) {
        return this.$sbPerformanceApi
            .getCollection(projectId, {
                date_range: {
                    start_date: Number.NEGATIVE_INFINITY.toString(),
                    end_date: moment().endOf("day").format(),
                },
                requests: [
                    {
                        metric: {
                            expression: "sb::count-deliverable",
                        },
                        dimension: {
                            name: "sb::deliverable-type",
                        },
                        pivot: {
                            metric: {
                                expression: "sb::schedule",
                            },
                        },
                    },
                ],
            })
            .then(this._mapReportsToStats);
    }

    /**
     * Fetch metrics aggregated by process template for complete project.
     *
     * @param projectId {string}
     *
     * @returns {Promise<Array<{
     *               id: string,
     *               name: string,
     *               total: number,
     *               behind: number,
     *               planned: number,
     *               finished: number,
     *               confirmed: number,
     *               completed: number,
     *           }>>}
     */
    fetchScheduleStatsGroupedByProcessTemplate(projectId) {
        return this.$sbPerformanceApi
            .getCollection(projectId, {
                date_range: {
                    start_date: Number.NEGATIVE_INFINITY.toString(),
                    end_date: moment().endOf("day").format(),
                },
                requests: [
                    {
                        metric: {
                            expression: "sb::count-deliverable",
                        },
                        pivot: {
                            metric: {
                                expression: "sb::schedule",
                            },
                        },
                    },
                ],
            })
            .then(this._mapReportsToStats);
    }

    _mapReportsToStats(reports) {
        return reports.metrics.map((metric) => {
            const stats = {
                name: metric.name,
                id: metric.id,
                total: 0,
                behind: 0,
                planned: 0,
                finished: 0,
                confirmed: 0,
                completed: 0,
            };
            return _.assign(
                stats,
                _.pick(metric.num_of_activities, [
                    "total",
                    "behind",
                    "planned",
                    "finished",
                    "confirmed",
                    "completed",
                ])
            );
        });
    }

    /**
     * Aggregate template-level metrics further into project level metrics.
     * @param stats {Array<{
     *               id: string,
     *               name: string,
     *               total: number,
     *               behind: number,
     *               planned: number,
     *               finished: number,
     *               confirmed: number,
     *               completed: number,
     *           }>}
     * @returns {{
     *               total: number,
     *               behind: number,
     *               planned: number,
     *               finished: number,
     *               confirmed: number,
     *               completed: number,
     *           }}
     */
    aggregateScheduleStatsForProject(stats) {
        return stats.reduce(
            (aggregatedStats, statsByTemplate) => {
                aggregatedStats.total += statsByTemplate.total;
                aggregatedStats.behind += statsByTemplate.behind;
                aggregatedStats.planned += statsByTemplate.planned;
                aggregatedStats.finished += statsByTemplate.finished;
                aggregatedStats.confirmed += statsByTemplate.confirmed;
                aggregatedStats.completed += statsByTemplate.completed;
                return aggregatedStats;
            },
            {
                total: 0,
                behind: 0,
                planned: 0,
                finished: 0,
                confirmed: 0,
                completed: 0,
            }
        );
    }
}
