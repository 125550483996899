import angular from "angular";
import _ from "lodash";
import htmlTemplate from "./sb_deliverable_overlay.html";

export default angular
    .module("sbApp.sbDeliverableOverlay", ["sbApp.sbDeliverableDependencies"])
    .constant("DELIVERABLE_OVERLAY_TAB", {
        ACTIVITIES: 0,
        NOTES: 1,
        CHECKLISTS: 2,
        DEPENDENCIES: 3,
    })
    .directive("sbDeliverableOverlay", function DeliverableOverlayDirective() {
        "ngInject";
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "$ctrl",
            templateUrl: htmlTemplate,
            controller: DeliverableOverlayCtrl,
        };
    });

function DeliverableOverlayCtrl(
    $q,
    $scope,
    $translate,
    $window,
    $sbDetailsOverlay,
    Analytics,
    $state,
    $rootScope,
    EVENTS,
    $sbDialog,
    $mdDialog,
    $mdToast,
    DELIVERABLE_OVERLAY_TAB,
    loadingToast,
    $sbCurrentProject,
    $sbDetailsOverlayData,
    $sbIssueEditorService,
    $sbDeliverableJobsApi,
    $sbStateChangesApi,
    $sbNotesApi,
    $sbResetActivityDialog,
    $sbErrorPresenter,
    $sbTracking,
    $sbDeliverables,
    $sbMembership,
    $timeout,
    EnvironmentFlags
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    var previousSelectedTab = 0;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.selectedTab = 0;
    vm.closeFillChecklistPanel = closeFillChecklistPanel;
    vm.closeChecklistViewerPanel = closeChecklistViewerPanel;
    vm.closeOverlay = closeOverlay;
    vm.data = $sbDetailsOverlayData.current;
    vm.isLoading = $sbDetailsOverlayData.loading;
    vm.EnvironmentFlags = EnvironmentFlags;
    vm.previousViewType = "";

    vm.assignProcessTo = onAssignProcess;
    vm.updateActivityWorkflowIcon = updateActivityWorkflowIcon;
    vm.onStateChange = onStateChange;
    vm.onCreateNote = onCreateNote;
    vm.onFillChecklist = onFillChecklist;
    vm.selectedTeamChange = selectedTeamChange;
    vm.onRequestActivityUpdate = onRequestActivityUpdate;
    vm.onResetActivity = onResetActivity;

    vm.selectActivitiesTab = $sbTracking.detailsOverlay.tabs.openActivities;
    vm.selectNotesTab = $sbTracking.detailsOverlay.tabs.openNotes;
    vm.fillChecklist = {
        isOpen: false,
        activity: undefined,
        activityId: undefined,
        activityName: undefined,
        projectId: undefined,
        activityState: undefined,
        toState: undefined,
    };

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    $scope.$on(EVENTS.DETAILS_OVERLAY_STATE_CHANGED, onOverlayStateChanged);

    function onOverlayStateChanged($event, { current }) {
        vm.viewType = current.viewType;
        if (!current.id) {
            $sbDetailsOverlayData.unset();
            return;
        }

        switch (current.viewType) {
            case "deliverable":
                vm.previousViewType = "deliverable";
                openDeliverablePanel(current.id, current.selectedTab);
                break;
            case "checklist":
                openChecklistPanel(current.id);
                break;
            case "activity":
                vm.previousViewType = "activity";
                openActivityPanel(current.id);
                break;
            default:
                throw new Error("Invalid view type");
        }
    }

    $rootScope.$on(EVENTS.NOTE_CHANGED, function ($event, { before, after }) {
        $sbDetailsOverlayData.handleNoteModified(after);
    });

    $sbDeliverables.createChangeWatcherFor(
        [$sbDeliverables.DELIVERABLE_CHANGE_TYPES.BASELINE],
        function ($event, deliverable) {
            if ($sbDetailsOverlayData.isCurrent(deliverable.id)) {
                _handleDeliverableChange($event, deliverable);
            }
        }
    );

    $rootScope.$on(EVENTS.DELIVERABLE__DATA_CHANGED, _handleDeliverableChange);

    $scope.$watch("$ctrl.selectedTab", function () {
        if (vm.selectedTab !== previousSelectedTab) {
            var previous = previousSelectedTab;
            var current = vm.selectedTab;
            previousSelectedTab = current;
            $rootScope.$broadcast(
                EVENTS.DELIVERABLE_OVERLAY_SELECTED_TAB_DID_CHANGE,
                {
                    previousSelectedTab: previous,
                    currentSelectedTab: current,
                }
            );
        }
    });

    /////////////////////
    //
    //       IMPL
    //
    /////////////////////

    function closeFillChecklistPanel() {
        if (vm.fillChecklist.isOpen) {
            $timeout(() => {
                vm.fillChecklist.isOpen = false;
            }, 1);
        }
    }

    function closeOverlay() {
        $sbDetailsOverlay.close();
        vm.selectedTab = 0;
        vm.closeFillChecklistPanel();
    }

    function selectedTeamChange(team) {
        vm.data.selectedTeam = team;
    }

    function openActivityPanel(activityId) {
        vm.activityId = activityId;
        $timeout(() => {
            const panel = document.getElementById(
                "sb-app-activity-details-panel"
            );
            panel.activityId = activityId;
        });
    }

    function openDeliverablePanel(
        deliverableId,
        selectedTab = DELIVERABLE_OVERLAY_TAB.ACTIVITIES
    ) {
        vm.closeFillChecklistPanel();
        vm.selectedTab = selectedTab;

        if (!$sbDetailsOverlayData.isCurrent(deliverableId)) {
            $sbDetailsOverlayData
                .load(deliverableId)
                .catch($sbErrorPresenter.catch);
        }
    }

    function openChecklistPanel(openToken) {
        const [deliverableId, activityId, checklistId] = openToken.split("/");
        let deliverableLoad;

        if (!$sbDetailsOverlayData.isCurrent(deliverableId)) {
            // fill in the deliverable already because it will show up on "close"
            vm.selectedTab = DELIVERABLE_OVERLAY_TAB.CHECKLISTS;
            deliverableLoad = $sbDetailsOverlayData.load(deliverableId);
        } else {
            deliverableLoad = $q.resolve();
        }

        vm.openChecklist = undefined;
        deliverableLoad
            .then(() =>
                $sbDetailsOverlayData.pickActivityChecklistFor(
                    deliverableId,
                    activityId,
                    Number.parseInt(checklistId, 10)
                )
            )
            .then((checklist) => {
                checklist.activity = vm.data.deliverable.find(activityId);
                return checklist;
            })
            .then((checklist) => (vm.openChecklist = checklist))
            .catch($sbErrorPresenter.catch);
    }

    function closeChecklistViewerPanel() {
        void $sbDetailsOverlay.toggleView(
            "deliverable",
            vm.data.deliverable.id
        );
    }

    /**
     * Trigger UI refresh in case that a deliverable property change occurred that is currently
     * displayed in this UI element.
     *
     * @param $event
     * @param {Object|String} identifier - something to identify a deliverable
     * @private
     */
    function _handleDeliverableChange($event, identifier) {
        if (angular.isObject(identifier)) {
            identifier = identifier.id || identifier.ID;
        }

        if ($sbDetailsOverlayData.isCurrent(identifier)) {
            $sbDetailsOverlayData
                .load(identifier)
                .catch($sbErrorPresenter.catch);
        }
    }

    function onAssignProcess($event, deliverable) {
        return $sbDialog
            .openAssignTemplateDialog($event)
            .then(({ selectedTemplate }) => {
                const busyWhileAssignDialog = $mdDialog
                    .busyIndication()
                    .title("DIALOG_MERGE_STRUCTURE_LOADING_TITLE")
                    .text("ACTION_ASSIGN_TEMPLATE_LOADING")
                    .allPromises([
                        $sbDeliverableJobsApi.createAssignProcessJob(
                            deliverable.projectId,
                            {
                                strategy:
                                    $sbDeliverableJobsApi.ASSIGN_STRATEGY
                                        .ASSOCIATE,
                                deliverable_set: [deliverable.id],
                                process_template_id: selectedTemplate.ID,
                            }
                        ),
                    ]);

                return $mdDialog.show(busyWhileAssignDialog).catch((err) => {
                    const isCanceled = _.isUndefined(err);
                    if (!isCanceled) {
                        $sbErrorPresenter.catch(err);
                    }
                });
            })
            .then(() => _handleDeliverableChange($event, deliverable));
    }

    function onFillChecklist(activity, fromState, toState) {
        Analytics.trackConversion("activity status changed");

        vm.fillChecklist.activity = activity;
        vm.fillChecklist.activityId = activity.id;
        vm.fillChecklist.activityName = activity.name;
        vm.fillChecklist.projectId = activity.projectId;
        vm.fillChecklist.isOpen = true;
        vm.fillChecklist.activityState = activity.state.current;
        vm.fillChecklist.toState = toState;
    }

    function updateActivityWorkflowIcon(activityId, activityState) {
        // The icons in the workflow dropdown needs updating to reflect the
        // new state once a fill checklist action has been preformed. We do
        // also emit an event similar as for the "onStateChange" method. It
        // is unclear why this is needed and it is done for consistency and
        // to be precautious.

        // This functions is called from Angular. The activity states in Angular have been refactored to lowercase but
        // in AngularJs are Uppercase.
        activityState = activityState.toUpperCase();

        const overlayActivity = vm.data.deliverable.find(activityId);

        overlayActivity.setCurrentState(activityState);
        $timeout(() => {
            vm.fillChecklist.activity.setCurrentState(activityState);
        }, 1);

        $scope.$emit(
            EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
            overlayActivity.id,
            overlayActivity.state.current,
            vm.data.deliverable.id
        );
    }

    function onRequestActivityUpdate(activity) {
        $sbTracking.detailsOverlay.activity.requestUpdate();

        let projectName = $sbCurrentProject.pick("name");
        let deliverableCode = vm.data.deliverable.code;
        let activityName = activity.name;
        let deliverableUrl = `${activity.projectId}/ngdeliverables?open=${vm.data.deliverable.id}`;
        let userName = $sbMembership.currentUser().displayName;

        var translations = $translate.instant(
            [
                "REQUEST_ACTIVITY_UPDATE_EMAIL_SUBJECT",
                "REQUEST_ACTIVITY_UPDATE_EMAIL_BODY",
            ],
            {
                projectName,
                deliverableCode,
                activityName,
                deliverableUrl,
                userName,
            }
        );

        $window.location.href =
            "mailto:?subject=" +
            encodeURIComponent(
                translations.REQUEST_ACTIVITY_UPDATE_EMAIL_SUBJECT
            ) +
            "&body=" +
            encodeURIComponent(translations.REQUEST_ACTIVITY_UPDATE_EMAIL_BODY);
    }

    function onResetActivity(activity) {
        const projectId = $sbCurrentProject.pick("id");

        return $sbResetActivityDialog
            .show({
                title: "RESET_ACTIVITY_TITLE",
                description: "RESET_ACTIVITY_DESCRIPTION",
                projectId,
                activityIds: [activity.id],
            })
            .then(function () {
                $sbTracking.detailsOverlay.activity.reset(
                    $state.current.gaPageTrack
                );

                $rootScope.$emit(EVENTS.DELIVERABLE__DATA_CHANGED, {
                    id: vm.data.deliverable.id,
                });
            });
    }

    /**
     * Optimistic state change handler with rollback functionality.
     *
     * Emits 'COMPONENT_DETAIL__STATE_CHANGED' event with new state on successful state change.
     *
     * @param activity
     * @param toState
     *
     * @returns {Promise<undefined>}
     */
    function onStateChange(activity, toState) {
        Analytics.trackConversion("activity status changed");

        const fromState = activity.state.current;
        const overlayActivity = vm.data.deliverable.find(activity.id);

        if (fromState === toState) {
            return $q.resolve();
        }

        loadingToast.show("_UPDATING_STATUS");
        overlayActivity.setCurrentState(toState);
        activity.setCurrentState(toState);

        return $sbStateChangesApi
            .create(activity.id, {
                state: toState.toLowerCase(),
            })
            .then(function () {
                _showSuccessToast();

                $scope.$emit(
                    EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
                    overlayActivity.id,
                    overlayActivity.state.current,
                    vm.data.deliverable.id
                );
            })
            .catch(function (error) {
                overlayActivity.setCurrentState(fromState);
                activity.setCurrentState(fromState);
                $sbErrorPresenter.catch(error);
            });
    }

    function onCreateNote(component) {
        $sbTracking.note.creation.start("Details Overlay");

        const isDeliverable = $sbDetailsOverlayData.isCurrent(component.id);

        const showDialog = isDeliverable
            ? $sbIssueEditorService.showForDeliverable(vm.data.teams)
            : $sbIssueEditorService.showForActivity(
                  component.assignedTeam,
                  vm.data.teams
              );

        return showDialog
            .then(function ({ note }) {
                return $sbNotesApi
                    .create(component.projectId, {
                        type: pickType(note),
                        text: note.text,
                        assigned_team_id: pickResponsibleTeam(note),
                        activity_id: isDeliverable ? undefined : component.id,
                        deliverable_id: isDeliverable
                            ? component.id
                            : undefined,
                        image_ids: note.getImages().map((image) => image.id),
                        attachment_ids: note
                            .getAttachments()
                            .map((attachment) => attachment.id),
                        confirmation_team_id: pickConfirmationTeam(note),
                    })
                    .then((noteResponse) => {
                        noteResponse.deliverable_id = vm.data.deliverable.id;

                        $sbDetailsOverlayData.handleNoteAdded(noteResponse);

                        return $sbTracking.note.creation.done(
                            "Details Overlay",
                            {
                                type: noteResponse.type,
                                photos: noteResponse.image_ids,
                                attachments: noteResponse.attachments,
                            }
                        );
                    });
            })
            .catch((err) => {
                if (err) {
                    throw err;
                }
            });
    }

    function pickType(note) {
        if (note.type === "claim") {
            return "quality_issue";
        } else {
            return note.type;
        }
    }

    function pickResponsibleTeam(note) {
        const team = _.get(note, "responsibleTeam.id");
        if (!_.isNil(team)) {
            return team;
        }
    }

    function pickConfirmationTeam(note) {
        const team = _.get(note, "confirmationTeam.id");
        if (!_.isNil(team)) {
            return team;
        }
    }

    function _showSuccessToast() {
        $mdToast.show(
            $mdToast
                .simple()
                .content("_STATUS_UPDATED")
                .hideDelay(2000)
                .position("top right")
        );
    }
}
