import _, { isNull } from "lodash";
import Chart from "chart";
import moment from "moment";
import html from "./productivity_bar_chart.html";
import {
    activity_scheduling_behind,
    actual_completed,
    actual_confirmed,
    actual_work_finished,
    planed_as_completed,
} from "../../../../colorpalette";

export default {
    templateUrl: html,
    bindings: {
        chartData: "<",
        isLoading: "<",
    },
    controllerAs: "vm",
    controller: function ProductivityBarChart(
        $log,
        $element,
        $sbColor,
        $filter
    ) {
        "ngInject";

        var vm = this;
        var translate = $filter("translate");
        var canvas = $element.children()[0].getContext("2d");

        var OPACITY = 80;
        var BORDER_COLORS = {
            PLANNED: planed_as_completed,
            FINISHED: actual_work_finished,
            CONFIRMED: actual_confirmed,
            COMPLETED: actual_completed,
            BEHIND: activity_scheduling_behind,
        };
        var COLORS = {
            PLANNED: $sbColor.hexToRgba(BORDER_COLORS.PLANNED, OPACITY),
            FINISHED: $sbColor.hexToRgba(BORDER_COLORS.FINISHED, OPACITY),
            CONFIRMED: $sbColor.hexToRgba(BORDER_COLORS.CONFIRMED, OPACITY),
            COMPLETED: $sbColor.hexToRgba(BORDER_COLORS.COMPLETED, OPACITY),
            BEHIND: $sbColor.hexToRgba(BORDER_COLORS.BEHIND, OPACITY),
        };

        var chartColors = {
            completed: {
                border: BORDER_COLORS.COMPLETED,
                background: COLORS.COMPLETED,
            },
            finished: {
                border: BORDER_COLORS.FINISHED,
                background: COLORS.FINISHED,
            },
            planned: {
                border: BORDER_COLORS.PLANNED,
                background: COLORS.PLANNED,
            },
            behind: {
                border: BORDER_COLORS.BEHIND,
                background: COLORS.BEHIND,
            },
        };

        var barChartTopValuesPlugin = {
            id: "barChartTopValues",
            afterDatasetsDraw(chart) {
                const ctx = chart.ctx;
                const options = chart.config.options;
                const chartArea = chart.chartArea;
                const topBuffer = 1;

                chart.data.datasets.forEach((dataset, index) => {
                    const datasetMeta = chart.getDatasetMeta(index);

                    if (!datasetMeta.hidden) {
                        datasetMeta.data.forEach((bar, index) => {
                            const value = dataset.data[index];

                            if (value !== 0) {
                                ctx.fillStyle = options.defaultFontColor;
                                ctx.textAlign = "center";
                                ctx.textBaseline = "bottom";

                                const position = bar.tooltipPosition();
                                let valueYPosition = position.y - topBuffer;

                                if (position.y <= chartArea.top + topBuffer) {
                                    ctx.textBaseline = "top";
                                    valueYPosition = position.y + topBuffer;
                                }

                                ctx.fillText(value, position.x, valueYPosition);
                            }
                        });
                    }
                });
            },
        };

        vm.$onInit = $onInit;
        vm.$onChanges = $onChanges;
        vm.chart = undefined;
        vm.isLoading = true;
        vm.isEmpty = false;

        function $onInit() {
            vm.chart = _createDefaultChart(canvas);
        }

        function _findValidIndices(currentWeekIndex, sortedDates) {
            const indexOffsets = [-5, -4, -3, -2, -1, 0, 1];
            return indexOffsets
                .map((indexOffset) => {
                    const index = currentWeekIndex + indexOffset;
                    return sortedDates[index] !== undefined && index;
                })
                .filter((label) => !!label);
        }

        function $onChanges(changes) {
            if (changes.chartData && changes.chartData.currentValue) {
                const sCurveData = changes.chartData.currentValue;
                const currentWeekIndex = _findIndexOfEndOfCurrentWeek(
                    sCurveData.labels
                );

                const validIndices = _findValidIndices(
                    currentWeekIndex,
                    sCurveData.labels
                );

                const datasets = _getDatasets(sCurveData, validIndices);
                const labels = _getLabels(
                    currentWeekIndex,
                    sCurveData.labels,
                    validIndices
                );
                vm.chart.data = {
                    labels: labels,
                    datasets: [
                        {
                            label: translate("_COMPLETED"),
                            borderColor: chartColors.completed.border,
                            backgroundColor: chartColors.completed.background,
                            data: datasets.completed,
                        },
                        {
                            label: translate(
                                "DASHBOARD_CHARTS_LEGEND_FINISHED"
                            ),
                            borderColor: chartColors.finished.border,
                            backgroundColor: chartColors.finished.background,
                            data: datasets.finished,
                        },
                        {
                            label: translate("_PLANNED"),
                            borderColor: chartColors.planned.border,
                            backgroundColor: chartColors.planned.background,
                            data: datasets.planned,
                        },
                    ],
                };

                vm.chart.update();

                vm.isLoading =
                    (changes.isLoading && changes.isLoading.currentValue) ||
                    false;

                vm.isEmpty = false;

                if (
                    changes.chartData.currentValue &&
                    !changes.chartData.currentValue.planned.length > 0
                ) {
                    vm.isEmpty = true;
                }
            }
        }

        function _findIndexOfEndOfCurrentWeek(sortedDates) {
            const today = moment().format("YYYY-MM-DD HH:mm:ss");
            return _.sortedIndex(sortedDates, today) - 1;
        }

        function _getLabels(currentWeekIndex, sortedDates, validIndices) {
            const today = moment();
            const isDateInSameWeek = sortedDates.some((date) =>
                moment(date).isSame(today, "week")
            );

            return validIndices.map((index) => {
                if (isDateInSameWeek) {
                    if (index === currentWeekIndex - 1) {
                        return translate("_LAST_WEEK");
                    }

                    if (index === currentWeekIndex) {
                        return translate("_THIS_WEEK");
                    }

                    if (index === currentWeekIndex + 1) {
                        return translate("_NEXT_WEEK");
                    }
                }

                return moment(sortedDates[index]).format("DD/MM/YYYY");
            });
        }

        function _getDatasets(data, validIndices) {
            const planned = _generateDatasetArray(validIndices, data.planned);
            const finished = _generateDatasetArray(validIndices, data.finished);
            const completed = _generateDatasetArray(
                validIndices,
                data.completed
            );

            return {
                planned,
                finished,
                completed,
            };
        }

        function _generateDatasetArray(indices, data) {
            if (isNull(data)) {
                return indices.map((_) => 0);
            }
            return indices.map((index) => data[index] - data[index - 1] ?? 0);
        }

        function _createDefaultChart(canvas) {
            const config = {
                type: "bar",
                options: {
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: translate("_ACTIVITY_NUMBER"),
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: translate("_WEEK_OF_YEAR"),
                                },
                                ticks: {
                                    maxRotation: 0,
                                    minRotation: 0,
                                },
                            },
                        ],
                    },
                },
                plugins: [barChartTopValuesPlugin],
            };

            return new Chart(canvas, config);
        }
    },
};
