import angular from "angular";
import _ from "lodash";

export default function loginCtrl(
    $resolvedIdentityFromStore,
    $scope,
    $log,
    $sbAuthStore,
    $sbPasswordPolicy,
    $stateParams,
    $state,
    $sbAuth,
    $sbBootstrapOverlay,
    Analytics,
    PasswordResetRequiredError,
    $mdDialog,
    $sbEulaService
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    // stores the current password as entered by the user before
    // sending a login request. Currently needed for when a user
    // is creating a new password. With the current set up 'vm.password'
    // gets automatically set by the browser as the users old password
    // rather than the temp password they enter in the input field.
    // TODO - refactor the form HTML and model bindings to avoid this situation
    //
    var _currentPassword;

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    // ng-model for the password change page - contains newPassword if used.
    vm.passwordChange = {};
    vm.passwordChangeInProgress = false;

    // busy indicator
    vm.loginInProgress = false;

    // login or change password error to be displayed
    vm.authError = null;
    vm.isPasswordResetRequiredError = () =>
        vm.authError instanceof PasswordResetRequiredError;

    vm.identity = null;
    vm.username = "";
    vm.password = "";

    vm.onFormSubmit = onFormSubmit;
    vm.removeCurrentIdentity = removeCurrentIdentity;

    activate();

    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    // Watch change password events and trigger the validation
    $scope.$watch("loginForm.newPassword.$$rawModelValue", _passwordWatcher);

    $scope.$on("$destroy", function () {
        _passwordWatcher();
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function activate() {
        vm.identity = $resolvedIdentityFromStore;
        vm.username = _.get($resolvedIdentityFromStore, "mail");

        return $sbAuth
            .currentSession()
            .then(function (session) {
                $log.info("Cognito:: Current Session: ", session);
                return _goToNextState().catch(_goToDefaultState);
            })
            .catch(function (error) {
                $log.info("Cognito:: Current Session: Error", error);
                $sbBootstrapOverlay.hide();
            });
    }

    function _passwordWatcher(newPassword) {
        if (!angular.isString(newPassword) || newPassword.length === 0) {
            return;
        }

        vm.passwordPatternError = "";

        if (!angular.isObject(vm.passwordPolicy)) {
            return;
        }

        $sbPasswordPolicy
            .getPasswordErrorTranslated(newPassword, vm.passwordPolicy)
            .then(function (translatedError) {
                vm.passwordPatternError = translatedError.join(", ");
            });
    }

    function onFormSubmit($event, form) {
        _validateAndSubmitLogin($event, form);
    }

    /**
     * Check the validity of form and let it call the server in case it's valid
     * @param {Object} $event - submit event
     * @param {Object} form - form object containing validity information
     */
    function _validateAndSubmitLogin($event, form) {
        if (form.$valid) {
            _login($event);
        }
    }

    /**
     * Submit login form and try to authenticate on the system.
     *
     * - is handling authentication errors
     * - is handling password change requests
     *
     * @param {Object} $event - submit event
     */
    function _login($event) {
        $event.stopPropagation();
        $event.preventDefault();

        _currentPassword = vm.password;

        vm.authError = null;
        vm.loginInProgress = true;

        $sbAuth
            .signIn(vm.username, vm.password)
            .then(function (session) {
                return $sbAuthStore.storeIdentity(
                    _.pick(session, [
                        "username",
                        "mail",
                        "name",
                        "company",
                        "displayName",
                        "initials",
                    ])
                );
            })
            .then(() => $sbEulaService.getEula())
            .then((eula) => {
                if (eula.userMustBePromptedForEula) {
                    return $mdDialog.show(
                        $mdDialog
                            .eula()
                            .eulaAcceptKey(eula.eulaAcceptKey)
                            .activeDeadlineToAccept(
                                eula.activeDeadlineToAccept.format("L")
                            )
                            .shouldBlockUser(!eula.userCanUseSablono)
                    );
                }
                return Promise.resolve();
            })
            .then(function () {
                return _goToNextState().catch(_goToDefaultState);
            })
            .catch(function (error) {
                vm.authError = error;
            })
            .finally(function () {
                vm.loginInProgress = false;
            });
    }

    /**
     * Remove all hints to the last session from the scope.
     *
     * @param {Object} $event - ng-click event in the form
     */
    function removeCurrentIdentity($event) {
        $event.preventDefault();
        vm.identity = null;
        vm.username = "";
        vm.password = "";
        vm.authError = null;
        $sbAuthStore.wipe();
    }

    /**
     * Validate the $stateParams and trigger a navigation to this state
     * @return {Promise|void|IPromise<any>} - Returns a Promise representing the state of the transition.
     */
    function _goToNextState() {
        var nextStateName = $stateParams.name;

        if (nextStateName) {
            var nextStateParams = angular.fromJson($stateParams.params);

            return $state.go(nextStateName, nextStateParams, {
                location: "replace",
            });
        } else {
            // default state -> project overview
            //
            return _goToDefaultState();
        }
    }

    /**
     * go the the default state after successful login
     * @returns {Promise}
     */
    function _goToDefaultState() {
        return $state.go(
            "sablono.projectSelection",
            {},
            {
                location: "replace",
            }
        );
    }
}
