import _ from "lodash";

export default function BarChartService($filter) {
    "ngInject";
    var translate = $filter("translate");
    var numberFilter = $filter("number");

    var service = {
        breakIntoMultiline: _breakIntoMultiline,
        getChartHeightByLabels: _getChartHeightByLabels,
        drawCountsOnBar: _drawCountsOnBar,
        updateTranslations: _updateTranslations,
        transformModelToData: _transformModelToData,
    };

    return service;

    function _transformModelToData(model, mapping) {
        return Object.keys(mapping).reduce(function (data, key) {
            data[key] = _.map(model, mapping[key]);
            return data;
        }, {});
    }

    function _updateTranslations(i18n, mapping) {
        return Object.keys(mapping).reduce(function (map, key) {
            map[key] = translate(mapping[key]);
            return map;
        }, i18n);
    }

    function _breakIntoMultiline(text = "") {
        var MAX_CHARS_FOR_YSCALE_LABEL = 20;
        var EMPTY_SPACE_LENGTH = 1;

        if (text && text.length < MAX_CHARS_FOR_YSCALE_LABEL) {
            return text;
        }

        var words = text.split(" ");
        var curWord = words[0];
        var lineLength = curWord.length;

        var lines = [];

        for (var i = 1; i < words.length; i++) {
            var word = words[i];
            lineLength += word.length + EMPTY_SPACE_LENGTH;

            if (lineLength <= MAX_CHARS_FOR_YSCALE_LABEL) {
                // append to current line
                //
                curWord += " " + word;
            } else {
                // start a new line
                //
                lines.push(curWord);
                curWord = word;
                lineLength = word.length;
            }
        }

        // save last segment
        //
        lines.push(curWord);
        return lines;
    }

    function _getChartHeightByLabels(labels) {
        /*
       Each label represents a section ( -> a barchart) that is part of a "multi-barchart"
       Each of those labels is split in multiline (modelled as an array) lines
       in order to fit in the chart.
       Former approach was taking into account this fact but quite some tweeking resulted
       in a better approach without.
       If you are reading this have a look at the old implementation.

        The elements that need to be balanced are:
        - The legend height that can vary based on screen width
        - The length of the bar chart label -> the lines it needs to display
        - The number of sections to display

        Edge cases:
        - Bar chart with no label or legend -> should not display huge bars
        - Barchart with 4 line legend and one line labels
        - Barchart with 1-4 line legend and very big labels
        - Barchart with 1-4 line legend and one very big label and one/some one line labels
         */
        const overallSections = labels.length;

        if (overallSections === 1) {
            return 40;
        }

        return overallSections * 90;
    }

    function _drawCountsOnBar(chart) {
        var ctx = chart.ctx;
        var datasets = chart.config.data.datasets;
        var options = chart.config.options;

        ctx.fillStyle = options.defaultFontColor;

        datasets.forEach(function (dataset, index) {
            const meta = chart.controller.getDatasetMeta(index);

            meta.data.forEach(function (bar, index) {
                const count = dataset.data[index];
                const hasLegend = bar._chart.legend.options.display;
                let yCoord = bar._model.y;

                if (!hasLegend) {
                    yCoord += 4;
                }

                if (count !== 0) {
                    const margin = 5;
                    const labelWidth = ctx.measureText(count).width + margin;
                    let xCoord;
                    let barWidth;

                    if (count < 0) {
                        barWidth =
                            bar._xScale.maxWidth / 2 -
                            (bar._model.x - bar._xScale.left);
                        if (barWidth > labelWidth) {
                            xCoord = bar._model.x + margin;
                        } else {
                            xCoord = bar._model.x - labelWidth;
                        }
                    } else {
                        barWidth =
                            (bar._xScale.right - bar._xScale.left) / 2 -
                            (bar._xScale.right - bar._model.x);
                        if (barWidth > labelWidth) {
                            xCoord = bar._model.x - labelWidth;

                            const contrast = calculateContrast(
                                convertRGBAtoRGB(bar._model.backgroundColor),
                                convertHexToRgb(options.defaultFontColor)
                            );
                            if (contrast < 6) {
                                ctx.fillStyle = "white";
                            }
                        } else {
                            xCoord = bar._model.x + margin;
                            ctx.fillStyle = options.defaultFontColor;
                        }
                    }

                    ctx.fillText(numberFilter(Math.abs(count)), xCoord, yCoord);
                    ctx.fillStyle = options.defaultFontColor;
                } else {
                    ctx.fillText(numberFilter(count), bar._model.x + 5, yCoord);
                    ctx.fillStyle = options.defaultFontColor;
                }
            });
        });
    }

    /**
     * Convert an RGBA color to an RGB color given an RGBA color string like "rgba(255, 255, 255, 0.5)"
     *
     * @param rgba
     * @returns {number[]}
     */
    function convertRGBAtoRGB(rgba) {
        const parts = rgba.match(/[\\.\d]+/g);

        const alpha = parseFloat(parts[3]);
        const [r, g, b] = parts.slice(0, 3).map(function (part) {
            return parseInt(part, 10);
        });

        return [
            Math.round((1 - alpha) * 255 + alpha * r),
            Math.round((1 - alpha) * 255 + alpha * g),
            Math.round((1 - alpha) * 255 + alpha * b),
        ];
    }

    /**
     * Convert a hex color to an RGB color given a hex color string like "#FFFFFF"
     *
     * @param hex
     * @returns {number[]|null}
     */
    function convertHexToRgb(hex) {
        let parts = /^#?([a-f0-9]{2})?([a-f0-9]{2})?([a-f0-9]{2})$/gi.exec(hex);

        if (parts === null) {
            parts = /^#?([a-f0-9])?([a-f0-9])?([a-f0-9])$/gi.exec(hex);
        }

        return parts
            ? [
                  parseInt(parts[1], 16),
                  parseInt(parts[2], 16),
                  parseInt(parts[3], 16),
              ]
            : null;
    }

    function calculateContrast(rgb1, rgb2) {
        const RED = 0.2126;
        const GREEN = 0.7152;
        const BLUE = 0.0722;

        const GAMMA = 2.4;

        function luminance(r, g, b) {
            var a = [r, g, b].map((v) => {
                v /= 255;
                return v <= 0.03928
                    ? v / 12.92
                    : Math.pow((v + 0.055) / 1.055, GAMMA);
            });
            return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
        }

        var lum1 = luminance(...rgb1);
        var lum2 = luminance(...rgb2);
        var brightest = Math.max(lum1, lum2);
        var darkest = Math.min(lum1, lum2);
        return (brightest + 0.05) / (darkest + 0.05);
    }
}
