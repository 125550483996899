const _get = require("lodash/get");
const _isString = require("lodash/isString");
import Auth from "@aws-amplify/auth";

export default function AuthenticationProvider() {
    "ngInject";

    const provider = this;

    provider.init = function (cognitoCredentials) {
        // https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-import-using-lambda.html
        Auth.configure({
            authenticationFlowType: "USER_PASSWORD_AUTH",
            mandatorySignIn: true,
            region: cognitoCredentials.REGION,
            userPoolId: cognitoCredentials.USER_POOL_ID,
            userPoolWebClientId: cognitoCredentials.APP_CLIENT_ID,
        });
    };

    provider.$get = function (
        $log,
        $q,
        $rootScope,
        $state,
        AuthError,
        PasswordResetRequiredError,
        NewPasswordRequiredError,
        toJson,
        User
    ) {
        "ngInject";

        var user;

        var ERROR_MESSAGES = {
            NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
        };

        var service = {
            signIn: signIn,
            currentSession: currentSession,
            getCurrentUser: getCurrentUser,
            signOut: signOut,
            completeNewPassword: completeNewPassword,
            requestResetPassword: requestResetPassword,
            resetPassword: resetPassword,
            signUp: signUp,
            confirmSignUp: confirmSignUp,
            goToLoginState: goToLoginState,
            currentAuthenticatedUser: currentAuthenticatedUser,
            updateUserAttributes: updateUserAttributes,
            changePassword: changePassword,
            resendVerificationEmail: resendVerificationEmail,
        };

        return service;

        function signIn(username, password) {
            return $q(function (resolve, reject) {
                Auth.signIn(username, password)
                    .then(function (user) {
                        return Auth.currentUserInfo().then(function (userInfo) {
                            user.attributes = _get(userInfo, "attributes");
                            return user;
                        });
                    })
                    .then(_setUser)
                    .then(_checkIfNewPasswordRequired)
                    .then(resolve)
                    .catch(function (error) {
                        if (error.code === "PasswordResetRequiredException") {
                            return reject(
                                new PasswordResetRequiredError(error.message)
                            );
                        }
                        return reject(new AuthError(error.message));
                    });
            });
        }

        function _setUser(_user) {
            user = new User(_user);
            return user;
        }

        function _checkIfNewPasswordRequired(user) {
            if (
                user._amplify.challengeName ===
                ERROR_MESSAGES.NEW_PASSWORD_REQUIRED
            ) {
                $state.go("account.verify");
                throw new NewPasswordRequiredError();
            } else {
                return user;
            }
        }

        /**
         * Get the current CognitoUserSession
         *  having accessToken, idToken and refreshToken.
         *  This method will automatically refresh the accessToken and idToken if tokens are expired and a valid
         *  refreshToken presented. So you can use this method to refresh the session if needed.
         *
         * @return {Promise<CognitoUserSession>}
         */
        function currentSession() {
            return $q(function (resolve, reject) {
                return Auth.currentSession().then(resolve, reject);
            });
        }

        function getCurrentUser() {
            if (user) {
                return $q.resolve(user);
            }
            return $q
                .all([Auth.currentSession(), Auth.currentAuthenticatedUser()])
                .then(([session, user]) =>
                    _setUser(Object.assign(session, user))
                );
        }

        function signOut() {
            return Auth.signOut()
                .then(function () {
                    // introduce a logout event to let child states listen on logout and trigger needed actions.
                    $rootScope.$broadcast("sb.global.logout");
                })
                .then(goToLoginState);
        }

        function completeNewPassword(newPassword) {
            return $q(function (resolve, reject) {
                const requiredName =
                    user.name ||
                    // if user was created from the Cognito console
                    // the name will be missing during FORCE_CHANGE_PASSWORD
                    // workflow. Use email as name for temp workaround
                    //
                    _get(user, "_amplify.challengeParam.userAttributes.email");
                return Auth.completeNewPassword(user._amplify, newPassword, {
                    name: requiredName,
                }).then(resolve, reject);
            });
        }

        function goToLoginState(nextStateName, nextStateParams) {
            if ($state.is("account.login")) {
                return false;
            }

            $state.go(
                "account.login",
                {
                    name: nextStateName,
                    params: toJson(nextStateParams),
                },
                {
                    location: "replace",
                }
            );
        }

        function requestResetPassword(username) {
            return $q(function (resolve, reject) {
                return Auth.forgotPassword(username).then(resolve, reject);
            });
        }

        function resetPassword(username, code, newPassword) {
            return $q(function (resolve, reject) {
                return Auth.forgotPasswordSubmit(
                    username,
                    code,
                    newPassword
                ).then(resolve, reject);
            });
        }

        function capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        function signUp(user) {
            return $q(function (resolve, reject) {
                if (!user) {
                    return reject(new Error("MissingRequiredParameter:: user"));
                }

                /*
                 * Lately we are having more and more users who are signing up
                 * without first name (or sometimes even without last name).
                 *   -> https://sablono.atlassian.net/browse/SAB-3062
                 *
                 * We don't know how this is possible because the UI form validation is
                 * not allowing that. But if it slips through, we need to make sure that the
                 * created user has valid first and last name.
                 *
                 * So this is kind of a hacky warkaround which is based on the assumption
                 * that the email address must be valid and given. We just take something
                 * meaningful from the email address and use it to create the missing name parts.
                 *
                 * This can be considered temporary and will be removed once the new login UI goes live
                 *   -> https://sablono.atlassian.net/browse/SAB-2501
                 */
                if (
                    !_isString(user.firstName) ||
                    user.firstName.trim().length === 0
                ) {
                    // find first name in email
                    const name = user.email.split("@")[0];
                    user.firstName = capitalize(name.split(".")[0]);
                }

                if (
                    !_isString(user.lastName) ||
                    user.lastName.trim().length === 0
                ) {
                    // find last name in email
                    const name = user.email.split("@")[0];
                    const names = name.split(".");
                    user.lastName = capitalize(names[names.length - 1]);
                }

                return Auth.signUp({
                    username: user.email,
                    password: user.password,
                    attributes: {
                        email: user.email,
                        name: user.firstName + " " + user.lastName,
                        "custom:company": user.company,
                        "custom:language_code": user.language,
                        "custom:signup_origin": user.signupOrigin,
                        "custom:job_title": user.otherJobTitle
                            ? user.otherJobTitle
                            : user.jobTitle,
                        "custom:company_type": user.companyType,
                        "custom:phone": user.phone,
                        "custom:marketing_consent_v2": user.marketingConsent,
                        "custom:hs_analytics_source": user.analyticsSourceData,
                        "custom:hs_what_tracking": user.whatTracking,
                        "custom:hs_why_tracking": user.whyTracking,
                    },
                }).then(resolve, reject);
            });
        }

        function confirmSignUp(username, code) {
            return $q(function (resolve, reject) {
                return Auth.confirmSignUp(username, code, {}).then(
                    resolve,
                    reject
                );
            });
        }

        /**
         * Get current authentiated user object. It will throw an error if there is no user logged in.
         * @returns {Promise<CognitoAuthenticatedUser>}
         */
        function currentAuthenticatedUser() {
            return $q(function (resolve, reject) {
                return Auth.currentAuthenticatedUser().then(resolve, reject);
            });
        }

        function updateUserAttributes(user, attributes) {
            return $q(function (resolve, reject) {
                return Auth.updateUserAttributes(user, attributes).then(
                    resolve,
                    reject
                );
            });
        }

        function changePassword(user, oldPassword, newPassword) {
            return $q(function (resolve, reject) {
                return Auth.changePassword(user, oldPassword, newPassword).then(
                    resolve,
                    reject
                );
            });
        }

        function resendVerificationEmail(user) {
            return Auth.resendSignUp(user);
        }
    };
}
