export default class PageNavigationService {
    constructor(
        $sbPageAccess,
        $state,
        SABLONO_STATES,
        $sbFeatureDecisions,
        FeatureFlags
    ) {
        "ngInject";

        this.$sbPageAccess = $sbPageAccess;
        this.$state = $state;
        this.PAGE = SABLONO_STATES;
        this.$sbFeatureDecisions = $sbFeatureDecisions;
        this.FeatureFlags = FeatureFlags;
    }

    goToLandingPage(project) {
        if (
            this.$sbFeatureDecisions.isFeatureEnabled(
                this.FeatureFlags.TrackerAsLandingPage
            )
        ) {
            return this.$state.go(this.PAGE.tracker, {
                projectId: project.id,
            });
        }

        const pagePriorities = [
            this.PAGE.projectOverviewPro,
            this.PAGE.projectOverview,
            this.PAGE.tracker,
            this.PAGE.leanboard, // this is basically the handle for INSPECTORS which only have access to the leanboard on web
            this.PAGE.requestAccessLeanPlanning, // in case this is not booked they see "request access" page
        ];

        let targetPage = pagePriorities.find((page) =>
            this.$sbPageAccess.isAccessible(page, project.privileges)
        );

        if (!targetPage) {
            // the ultimate fallback because the project settings page has currently no restrictions at all.
            //  should not happen but it prevents a navigation with undefined "target state"
            targetPage = this.PAGE.projectSettings;
        }

        return this.$state.go(targetPage, {
            projectId: project.id,
        });
    }
}
