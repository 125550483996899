import _ from "lodash";
import PresentableError from "./PresentableError";

/**
 * Basic error if a request fails.
 *
 * @constructor
 * @extends PresentableError
 *
 * @param {XMLHttpRequest} request - Used XMLHttpRequest object
 * @param {string} message - the error message
 */
function ParseableError(request, message) {
    this.code = "REQUEST";
    this.title = "ERROR_REQUEST_TITLE";
    this.message = message;
    this.request = request;
}

/**
 * try to parse a request error
 * @param {XMLHttpRequest} xhrRequest - the request this error should handle
 * @throws {Error}
 * @returns {boolean}
 */
ParseableError.isRequestParseable = function (xhrRequest) {
    return ParseableError.parseResponse(xhrRequest.response) !== false;
};

/**
 * Extract the response error message if exists
 *
 * @param {string} response
 * @return {string|boolean}
 */
ParseableError.parseResponse = function (response) {
    var parsed = _parseAnyKnownErrorFormat(response);
    if (parsed) {
        return parsed.code || false;
    }
    return false;
};

ParseableError.prototype = Object.create(PresentableError.prototype); // eslint-disable-line valid-jsdoc

/** @inheritdoc */ ParseableError.prototype.preferredPresentationStyle =
    function () {
        return PresentableError.presentationStyle.TOAST;
    }; // eslint-disable-line valid-jsdoc

/** @inheritdoc */ ParseableError.prototype.shouldAutoClose = function () {
    return true;
};

/**
 * parse the response in a common format
 *
 * @return {Object} Return the response as standard JSON having code as string and details as any
 */
ParseableError.prototype.parseResponseAsJSON = function () {
    var parsedObject = _parseAnyKnownErrorFormat(this.request.response) || {};
    return _.pickBy(parsedObject, function (value, key) {
        if (value) {
            return ["code", "details"].indexOf(key) > -1;
        } else {
            return false;
        }
    });
};

/**
 *  Our own backend is throwing a few known and exacted types of errors. This is the central point
 *  or parsing those into a common format. Just a code and extra "data".
 *
 *  If the format is unknown the function returns "false"
 *
 * @param {string} response
 * @return {{code:string, details:*}|boolean}
 * @private
 */
function _parseAnyKnownErrorFormat(response) {
    try {
        var responseParsed = JSON.parse(response);
        return {
            code: responseParsed.code || responseParsed.type,
            details: responseParsed.message,
        };
    } catch (error) {
        return false;
    }
}

export default ParseableError;
