import timelineTemplateHtml from "./timeline.html";
import timelineDetailTemplateHtml from "./states/timeline_detail.html";

export default function ($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.timeline", {
        templateUrl: timelineTemplateHtml,
        controller: "TimeLineCtrl",
        controllerAs: "timeline",
        url: "/timeline",
        gaPageTrack: "/inspections", // google analytics extension
        data: {
            title: "SECTION_INSPECTIONS_TITLE",
            subBarBackButtonHidden: true,
            subBarMini: true,
            category: "REVIEW",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(
                TRACKED_EVENTS.ENTERED_SITE_INSPECTION_TIMELINE_PAGE
            );
        },
    });

    $stateProvider.state("sablono.project.timeline.inspection", {
        templateUrl: timelineDetailTemplateHtml,
        controller: "TimeLineDetailCtrl",
        controllerAs: "inspection",
        url: "/{inspection}",
        gaPageTrack: "ignore",
    });
}
