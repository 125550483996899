/**
 * Class to handle the selection state of a set of objects
 *
 * -> you can select or deselect items (or just use selection change)
 *
 */

function ViewSelectionProvider() {
    this._selections = [];
    this.length = 0;
    this.possibleSelections = 1;
    this.selectAllActive = false;
}

/**
 * Set or Get
 * @param {Array} [newValue] - the new value or nothing
 * @returns {Array|ViewSelectionProvider} - as getter a string as setter this
 */
ViewSelectionProvider.prototype.selections = function (newValue) {
    if (arguments.length > 0) {
        this._selections = newValue;
        this.length = newValue.length;
        return this;
    } else {
        return this._selections;
    }
};

/**
 * Simple check if there are selected elements.
 * @returns {boolean} - true -> min one selected element
 */
ViewSelectionProvider.prototype.hasActiveSelections = function () {
    return this.length > 0;
};

ViewSelectionProvider.prototype.selectionChange = function (
    selectedObject,
    event
) {
    if (event && event.stopPropagation) {
        event.stopPropagation();
    }

    // check if already selected -> deselect
    //
    if (this.isSelected(selectedObject)) {
        this.deselect(selectedObject);
        return false;
    } else {
        this.select(selectedObject);
        return true;
    }
};

ViewSelectionProvider.prototype.deselect = function (selectedObject) {
    // remove from the list and the map
    var listIndex = this._selections.indexOf(selectedObject);
    // remove the element and return the removed element
    var splicedElement = this._selections.splice(listIndex, 1);
    // refresh the length
    this.length = this._selections.length;

    return splicedElement;
};

ViewSelectionProvider.prototype.select = function (selectedObject) {
    this._selections.push(selectedObject);
    // refresh the length
    this.length = this._selections.length;
};

/**
 * Check if the element is selected.
 * @param {Object|String|Number} selectedObject - the selected object or the selected key
 * @returns {boolean} - true if selected.
 */
ViewSelectionProvider.prototype.isSelected = function (selectedObject) {
    return this._selections.indexOf(selectedObject) > -1;
};

ViewSelectionProvider.prototype.selectAll = function () {
    this._selections = [];
    this.length = this.possibleSelections;
    this.selectAllActive = true;
};

ViewSelectionProvider.prototype.isSelectAllActive = function () {
    return this.selectAllActive === true;
};

/**
 *  Deselect all selected objects.
 */
ViewSelectionProvider.prototype.clearSelection = function () {
    // only if we touch every element explicit angular will notice it.
    //  -> So just setting both arrays to [] will not be enough.
    // Though there might be a better way. :D  @ MB
    //
    var clone = [].concat(this.selections());
    clone.forEach(this.deselect, this);
    this.selectAllActive = false;
    this.length = 0;
    return this._selections;
};

export default ViewSelectionProvider;
