import angular from "angular";
import htmlTemplate from "./issue_row.html";

export default {
    templateUrl: htmlTemplate,
    bindings: {
        issue: "<",
        timezone: "<",
        updateNote: "&",
        deleteNote: "&",
        shouldAllowEditing: "<",
        editNote: "&",
    },
    controllerAs: "row",
    controller: function IssueRowController(SbNote) {
        "ngInject";

        const vm = this;

        vm.isDisplayingEditButton = false;
        vm.onEditNote = onEditNote;
        vm.onDeleteNote = onDeleteNote;
        vm.toggleEditButtonDisplay = toggleEditButtonDisplay;
        vm.isNoteFinished = isNoteFinished;

        function onDeleteNote(event, row) {
            event.stopPropagation();
            vm.deleteNote({ row });
        }

        function isNoteFinished(row) {
            if (!row) {
                return;
            }

            return SbNote.isFinished({
                state: row.ISSUE_STATUS,
                type: row.ISSUE_TYPE,
            });
        }

        function toggleEditButtonDisplay(shouldShow) {
            if (!vm.shouldAllowEditing) {
                return;
            }

            vm.isDisplayingEditButton = shouldShow;
        }

        function onEditNote(event, row) {
            event.stopPropagation();
            vm.editNote(row);
        }
    },
};
