import htmlTemplate from "./sb_sidenav.html";
import _ from "lodash";

export default {
    name: "sbSidenav",
    templateUrl: htmlTemplate,
    controllerAs: "sidenav",
    controller: SidenavCtrl,
};

function SidenavCtrl(
    $scope,
    $state,
    $sbProjectApi,
    $sbMembership,
    $sbPageAccess,
    $sbCurrentProject,
    $mdSidenav,
    MenuContentService,
    LEFT_SIDE_NAV_ID
) {
    "ngInject";

    var vm = this;

    vm.menu = MenuContentService.generateMenu();
    vm.resourcesMenu = vm.menu.sections[4];
    vm.expandedMenus = [];
    vm.imageUrl;
    vm.$onInit = $onInit;
    vm.expandSidenavMenu = expandSidenavMenu;
    vm.collapseSidenavMenu = collapseSidenavMenu;
    vm.showSection = showSection;
    vm.showLink = showLink;
    vm.getHref = getHref;
    vm.goToStateAndCloseSidenav = goToStateAndCloseSidenav;

    $scope.state = $scope.$root.state;
    $scope.sablono = $scope.$parent.sablono;

    $scope.$on("$stateChangeSuccess", function () {
        vm.menu = MenuContentService.generateMenu();
        _setRoleAndProject();
        _fetchProjectImage();
    });

    function $onInit() {
        _setRoleAndProject();
        _fetchProjectImage();

        // Always expand "Dashboards & Trackers" menu by default
        const dashboardsAndTrackersMenuName = `sb-sidenav-list-menu-header-${vm.menu.sections[0].name}`;
        vm.expandedMenus.push(dashboardsAndTrackersMenuName);
    }

    function _setRoleAndProject() {
        $scope.role = _.get($sbMembership.currentRole(), "name", "default");
        $scope.project = $sbCurrentProject.get();
    }

    function _fetchProjectImage() {
        if ($scope.project) {
            const projectId = $scope.project.id;
            const imageId = $scope.project.imageId;

            $sbProjectApi.fetchLogo(projectId, imageId).then(function (image) {
                vm.imageUrl = image.dataUrl;
            });
        }
    }

    function expandSidenavMenu(id) {
        if (!vm.expandedMenus.includes(id)) {
            vm.expandedMenus.push(id);
        }
    }

    function collapseSidenavMenu(id) {
        vm.expandedMenus = vm.expandedMenus.filter(function (expandedMenuId) {
            return expandedMenuId !== id;
        });
    }

    /**
     * showSection - Display the menu section based on privileges and feature
     *               package of child pages.
     *
     * @param {Array} pages
     * @return {boolean}
     */
    function showSection(pages) {
        // Check if any of the child pages is accessible
        return pages.some(function (page) {
            return showLink(page.state);
        });
    }

    /**
     * showLink - Toggles the link based on privileges and feature package.
     *
     * @param {String | undefined} state can be undefined for direct external links for eula etc.
     * @return {boolean} true if has access, false if not
     */
    function showLink(state) {
        if (!state) {
            return true;
        }
        const { privileges } = $scope.project;

        return $sbPageAccess.isAccessible(state, privileges);
    }

    /**
     * getHref - Returns the url of the link, injecting the project id.
     *           If the link needs additional params it returns null.
     *
     * @param {String} state
     * @param {Object} stateParams
     * @return {String|null}
     */
    function getHref(state, stateParams) {
        if (!stateParams && $scope.project) {
            const newStateParams = {
                projectId: $scope.project.id,
            };

            return $state.href(state, newStateParams);
        } else {
            return $state.href(state);
        }
    }

    /**
     * goToStateAndCloseSidenav - If the link needs additional params it goes to the state with the
     *                            params and closes the sidenav otherwise it just closes the sidenav.
     *
     * @param {String} state
     * @param {Object} stateParams
     */
    function goToStateAndCloseSidenav(state, stateParams) {
        if (stateParams) {
            $state.go(state, stateParams);
        }

        $mdSidenav(LEFT_SIDE_NAV_ID).close();
    }
}
