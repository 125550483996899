import angular from "angular";
import _ from "lodash";
import {
    DocumentAttachment,
    ImageAttachment,
} from "../../../components/sbAttachmentGallery/gallery_attachment.model";

export default class DeliverablesNoteRowMapperService {
    constructor($sbIssue) {
        "ngInject";
        this.$sbIssue = $sbIssue;
    }

    /**
     * Map a domain model note to a deliverable note row view model
     *
     * @param {Object} note - server response
     */
    toViewModel(note) {
        return {
            ISSUE_ID: note.id,
            ISSUE_TYPE: note.type.toUpperCase(),
            ISSUE_STATUS: this.$sbIssue.mapApiToNoteState(note.state),
            ISSUE_CODE: _.get(note, "note_template.code"),
            ISSUE_NAME: this.determineName(note),
            ISSUE_TEXT: note.text,
            RESPONSIBLE_TEAM: note.assigned_team,
            CONFIRMATION_TEAM: note.confirmation_team,
            REF_COMPONENT_NAME: _.get(
                note,
                "activity.name",
                _.get(note, "deliverable.name", "-")
            ),
            REF_COMPONENT_CATEGORY: this.determineCategory(note),
            ATTACHMENTS: this._makeAttachments(note),
            LAST_UPDATE_TIME: _.get(note, "reported.at"),
            LAST_UPDATER_DISPLAY_NAME: _.get(note, "reported.by.name"),
            LAST_UPDATER_INITIALS: _.get(note, "reported.by.initials"),
            LAST_MODIFICATION_TIME: _.get(note, "modified.at"),
            LAST_MODIFICATION_DISPLAY_NAME: _.get(note, "modified.by.name"),
        };
    }

    _makeAttachments(note) {
        const imageAttachments = (note.image_ids || []).map(
            (imageId) => new ImageAttachment(imageId)
        );
        const documentAttachments = (note.attachments || []).map(
            (attachment) =>
                new DocumentAttachment(
                    attachment.id,
                    decodeURI(attachment.file.name)
                )
        );
        return imageAttachments.concat(documentAttachments);
    }

    determineCategory(note) {
        if (!_.has(note, "activity.id")) {
            return "DELIVERABLE";
        }
        if (Array.isArray(_.get(note, "activity.children"))) {
            return "GROUP";
        }
        return "ACTIVITY";
    }

    determineName(note) {
        let prefix;
        switch (note.type) {
            case "info":
                prefix = "IN";
                break;
            case "quality_issue":
                prefix = "CL";
                break;
            case "obstruction":
                prefix = "ON";
        }
        return `${prefix}-${note.deliverable.code}-${note.id}`;
    }
}
