import dashboardTemplateHtml from "./dashboard.html";

export default function ($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.dashboard", {
        templateUrl: dashboardTemplateHtml,
        controller: "DashboardCtrl",
        controllerAs: "dashboard",
        url: "/dashboard?structureId?teamId?templateId?deliverableType",
        params: {
            clear: false,
        },
        reloadOnSearch: false,
        gaPageTrack: "/dashboard", // google analytics extension
        data: {
            title: "SECTION_DASHBOARD_TITLE",
            category: "REVIEW",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_DASHBOARD_PAGE);
        },
    });
}
