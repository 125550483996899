import angular from "angular";
import moment from "moment";
import dialogTemplate from "./choose-project-source.tmpl.html";

export default angular
    .module("sbApp.sbChooseProjectSourceDialog", [])
    .config(function ($mdDialogProvider) {
        $mdDialogProvider.addPreset("chooseFile", {
            methods: [
                "title",
                "text",
                "ok",
                "cancel",
                "class",
                "projectSources",
                "availableSources",
                "showCreationDate",
                "showMergeHints",
                "showLastUpdatedDate",
                "isProjectAvailable",
                "ariaLabel",
                "showUsageNumbers",
            ],
            options: function selectDialog($mdDialog, $sbProject) {
                "ngInject";
                return {
                    templateUrl: dialogTemplate,
                    controller: ChooseProjectSourceDialogCtrl,
                    controllerAs: "chooseSource",
                    bindToController: true,
                };

                function ChooseProjectSourceDialogCtrl() {
                    "ngInject";
                    var vm = this;

                    // init with undefined. we use a !selectedSource for ng-disable
                    vm.selectedSource;

                    vm.selectSource = selectSource;
                    vm.getAvailableSources = getAvailableSources;
                    vm.closeDialog = closeDialog;
                    vm.resolve = resolve;

                    activate();

                    /**
                     * Activation method, to set proper date and times before the dialog
                     */
                    function activate() {
                        // enrich DATA object
                        //
                        vm.projectSources.forEach(function (source) {
                            source.data.image = _getSourceImage(source.type);
                        });

                        vm.projectName = $sbProject.getCurrent().name;
                    }

                    function selectSource(source) {
                        var isSourceSelected = vm.selectedSource === source;

                        if (isSourceSelected) {
                            // is selected. Deselect
                            vm.selectedSource;
                        } else {
                            vm.selectedSource = source;
                        }
                    }

                    function getAvailableSources() {
                        if (Array.isArray(vm.availableSources)) {
                            return vm.projectSources.filter((source) =>
                                vm.availableSources.includes(source.type)
                            );
                        } else {
                            return vm.projectSources;
                        }
                    }

                    function resolve(selectedSource) {
                        $mdDialog.hide(selectedSource);
                    }

                    function _getSourceImage(source) {
                        var imgPath = "";
                        switch (source) {
                            case "EXCEL":
                                imgPath = "images/excel.png";
                                break;
                            case "ASTA":
                                imgPath = "images/asta.png";
                                break;
                            //TODO [Feature-Flagged]  "deprecated-feature-to-remove-soon"
                            // case "MSP":
                            //     imgPath = "images/msp.png";
                            //     break;
                            // case "PRIMAVERA":
                            //     imgPath = "images/primavera.png";
                            //     break;
                        }
                        return imgPath;
                    }

                    function closeDialog() {
                        $mdDialog.cancel();
                    }
                }
            },
        });
    });
