import projectOverviewTemplateHtml from "./project_overview.html";

export default function ($stateProvider, SABLONO_STATES) {
    "ngInject";
    $stateProvider.state(SABLONO_STATES.projectOverview, {
        templateUrl: projectOverviewTemplateHtml,
        controller: "ProjectOverviewCtrl",
        controllerAs: "projectOverview",
        url: "/projectoverview",
        gaPageTrack: "/projectOverview", // google analytics extension
        data: {
            title: "SECTION_PROJECT_OVERVIEW_TITLE",
            category: "REVIEW",
        },
        resolve: {
            forwardTo: function (project, $sbPageAccess) {
                "ngInject";
                if (!project) {
                    return false;
                }

                const isOverviewAccessible = $sbPageAccess.isAccessible(
                    SABLONO_STATES.projectOverview,
                    project.privileges
                );

                if (isOverviewAccessible) {
                    return false;
                }

                const isLeanboardAccessible = $sbPageAccess.isAccessible(
                    SABLONO_STATES.leanboard,
                    project.privileges
                );

                if (isLeanboardAccessible) {
                    return SABLONO_STATES.leanboard;
                }
            },
        },
        onEnter: function (forwardTo, $state, intercomService, TRACKED_EVENTS) {
            "ngInject";
            if (forwardTo) {
                $state.transition.finally(() => $state.go(forwardTo));
            }
            intercomService.track(TRACKED_EVENTS.ENTERED_PROJECT_OVERVIEW_PAGE);
        },
    });
}
