import htmlTemplate from "./sbToolbarStateInfo.html";
export default {
    name: "sbToolbarStateInfo",
    templateUrl: htmlTemplate,
    controller: function (
        $rootScope,
        $window,
        $sbAuthStore,
        $mdPanel,
        $mdDialog,
        $sbErrorPresenter,
        $sbCurrentProject,
        $sbMembership,
        EVENTS,
        SABLONO_STATES
    ) {
        "ngInject";

        var vm = this;
        vm.project = null;

        vm.$onInit = $onInit;

        vm.isSchedulingRequired = isSchedulingRequired;

        vm.showSchedulingPanel = showSchedulingPanel;
        vm.showProfilePanel = showProfilePanel;

        $rootScope.$on("$stateChangeSuccess", function ($event, newState) {
            if (SABLONO_STATES.isProjectState(newState.name)) {
                vm.project = $sbCurrentProject.get();
                vm.role = $sbMembership.currentRole().name;
            } else {
                vm.project = null;
                vm.role = "default";
            }
        });

        function $onInit() {
            vm.project = $sbCurrentProject.get();
            vm.role = $sbMembership.currentRole().name;
            vm.session = $sbAuthStore.getLastSuccessfulSignInIdentity();
        }

        function isSchedulingRequired() {
            return vm.project && vm.project.isSchedulingNeeded();
        }

        function showSchedulingPanel($event) {
            $mdPanel.open("$sbSchedulingPanel", {
                id: "$sbSchedulingPanel",
                position: $mdPanel
                    .newPanelPosition()
                    .relativeTo($event.target)
                    .addPanelPosition(
                        $mdPanel.xPosition.CENTER,
                        $mdPanel.yPosition.BELOW
                    )
                    .withOffsetY("12px"),
                openFrom: $event,
            });
        }

        function showProfilePanel($event) {
            $mdPanel.open("$sbProfilePanel", {
                id: "$sbProfilePanel",
                position: $mdPanel
                    .newPanelPosition()
                    .relativeTo($event.target)
                    .addPanelPosition(
                        $mdPanel.xPosition.CENTER,
                        $mdPanel.yPosition.BELOW
                    )
                    .withOffsetY("8px"),
                openFrom: $event,
            });
        }

        ///////////////////////////////////////////////
        //          HELPDESK BLOCK SUPPORT           //
        //////////////////////////////////////////////
        //Check if some script is blocking help desk
        vm.initHelpDesk = function () {
            if ($window.helpDeskError) {
                $mdDialog.show(
                    $mdDialog
                        .alert()
                        .title("DIALOG_HELP_DESK_SCRIPT_BLOCKED_TITLE")
                        .content("DIALOG_HELP_DESK_SCRIPT_BLOCKED_CONTENT")
                );
                $window.helpDeskError = false;
            }
        };
        vm.initHelpDesk();
    },
};
