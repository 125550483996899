import _ from "lodash";
import {
    DocumentAttachment,
    ImageAttachment,
} from "../../ui-elements/components/sbAttachmentGallery/gallery_attachment.model";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default function (
    $q,
    $rootScope,
    $mdDialog,
    $sbDomain,
    $sbFileReader,
    $sbMembership,
    ISSUE_TEMPLATE_CONFIG,
    SbNote,
    SbTeam,
    EVENTS,
    $sbIssue,
    $sbErrorPresenter,
    TRAFFIC_LIGHT_MODES,
    $sbTracking,
    $sbProjectCurrentSettings,
    Analytics
) {
    "ngInject";
    const vm = this;

    vm.attachments = [];

    // maintain the saving state of the dialog
    vm.isSaving = false;
    vm.isUploadInProgress = false;
    vm.MAX_COMMENT_DESCRIPTION_LENGTH = 5000;

    vm.isGeolocationMessageDisplayed = false;
    vm.noteComment = {
        id: uuidv4(),
        noteId: vm.note.ISSUE_ID,
        text: "",
        createdAt: undefined,
        user: undefined,
        images: [],
        attachments: [],
    };

    vm.submit = submit;
    vm.closeDialog = closeDialog;

    vm.onAttach = onAttach;
    vm.onDetach = onDetach;

    vm.isUploading = (isUploading) => (vm.isUploadInProgress = isUploading);
    vm.isSubmitAvailable = isSubmitAvailable;

    vm.containsEncodedCharacterWeWantToPreserve =
        containsEncodedCharacterWeWantToPreserve;

    vm.filterExtensions = (params) =>
        params.filter((param) => param !== ".pdf");
    _init();

    function _init() {
        $sbProjectCurrentSettings
            .getGeolocationSetting()
            .then((geolocationPermission) => {
                vm.isGeolocationMessageDisplayed = geolocationPermission;
            });
    }

    function submit(noteComment) {
        if (!isSubmitAvailable()) {
            return;
        }
        Analytics.trackConversion("note comment and photo added");
        const user = $sbMembership.currentUser();
        noteComment.user = {
            name: user.userName,
            initials: user.initials,
        };

        noteComment.createdAt = moment().toISOString();

        noteComment.attachments = _.chain(vm.attachments)
            .filter((attachment) => attachment instanceof DocumentAttachment)
            .map((document) => document.id)
            .value();

        noteComment.images = _.chain(vm.attachments)
            .filter((attachment) => attachment instanceof ImageAttachment)
            .map((image) => ({ id: image.id }))
            .value();

        // To fix broken links with empty spaces. It seems expressjs is running a
        // decodeUri by default for such texts. So, we are doing the opposite function first.
        //
        if (containsEncodedCharacterWeWantToPreserve(noteComment.text)) {
            noteComment.text = encodeURI(noteComment.text);
        }

        return $mdDialog.hide({ noteComment });
    }

    function containsEncodedCharacterWeWantToPreserve(x) {
        try {
            return x !== decodeURI(x);
        } catch (_) {
            return false;
        }
    }

    function closeDialog() {
        $mdDialog.cancel();
    }

    /**
     * Callback for the gallery that will give you a file object.
     *
     * @param $event
     * @param {GalleryAttachment} attachment - image or file
     * @returns {*}
     */
    function onAttach($event, attachment) {
        vm.attachments = [attachment].concat(vm.attachments.concat());
    }

    /**
     * Remove the image reference from the note.
     * @param {number} index - position in the image array
     */
    function onDetach(index) {
        vm.attachments.splice(index, 1);
    }

    function isSubmitAvailable() {
        return (
            vm.newCommentForm.$valid &&
            !vm.isSaving &&
            !vm.isUploadInProgress &&
            (vm.noteComment.text || vm.attachments.length > 0)
        );
    }
}
