import html from "./tiles_chart.html";
export default {
    templateUrl: html,
    bindings: {
        onTileCardClick: "&",
        tilesData: "<",
        isLoading: "<",
    },
    controller: function SbTilesChart() {
        "ngInject";
        var vm = this;

        vm.cards = null;
        vm.cardsContainerClass = "";

        vm.$onChanges = function (changes) {
            if (changes.isLoading) {
                vm.isLoading = changes.isLoading.currentValue;
            }

            if (changes.tilesData) {
                _setDashboardCards(changes.tilesData.currentValue);
            }
        };

        vm.onCardClick = function (card) {
            vm.onTileCardClick({
                card: card,
            });
        };

        function _setDashboardCards(data) {
            var _gaTrackEvent = ["Dashboards", "click", "Tile"];

            if (data) {
                vm.cards = [
                    {
                        text: "DASHBOARD_DELIVERABLES_BEHIND",
                        number: data.behind.all,
                        class: data.behind.all === 0 ? "sb-good" : "sb-bad",
                        icon1: "mdi mdi-calendar-blank",
                        icon2: "mdi mdi-clock",
                        gaTrackEvent: _gaTrackEvent,
                        filters: {
                            schedule: "behind",
                        },
                    },
                    {
                        text: "DASHBOARD_DELIVERABLES_WITH_OBSTRU",
                        number: data.obstructions,
                        class:
                            data.obstructions === 0
                                ? "sb-good"
                                : "sb-warn-obstruction",
                        icon1: "sb-icon sb-icon-status-problem",
                        gaTrackEvent: _gaTrackEvent,
                        filters: {
                            notes: "obstruction",
                        },
                    },
                    {
                        text: "DASHBOARD_DELIVERABLES_WITH_CLAIMS",
                        number: data.claims,
                        class:
                            data.claims === 0
                                ? "sb-good"
                                : "sb-warn-quality-issue",
                        icon1: "sb-icon sb-icon-status_alert",
                        gaTrackEvent: _gaTrackEvent,
                        filters: {
                            notes: "claim",
                        },
                    },
                    {
                        text: "DASHBOARD_DELIVERABLES_WITH_INFO",
                        number: data.infos,
                        class: "sb-info",
                        icon1: "mdi mdi-information",
                        gaTrackEvent: _gaTrackEvent,
                        filters: {
                            notes: "info",
                        },
                    },
                ];
            }
        }
    },
};
