import angular from "angular";
import moment from "moment";
import _ from "lodash";
import FilterMenuListItem from "common/ui-elements/components/sbFilterMenuList/model/filter_menu_list_item.class";
import LeanBoardDisplayDatesDecorator from "./services/lean_board_display_dates.decorator";
import PresentableError from "common/errors/PresentableError";

export default function LeanBoardCtrl(
    $log,
    $q,
    $stateParams,
    $sbLeanBoard,
    $sbActivities,
    $sbDeliverables,
    EVENTS,
    $rootScope,
    $scope,
    $sbErrorPresenter,
    $sbDetailsOverlay,
    $mdDialog,
    DATE_TYPES,
    $sbTracking,
    SbActivity,
    $sbTeam,
    $sbDialog,
    $sbProject,
    WEEK_BOARD_SIZES,
    $sbMembership,
    $sbUserSettings,
    $sbLastPlanned,
    $state,
    SABLONO_STATES,
    $mdPanel,
    $translate,
    $sbLeanBoardFacade,
    $sbPagination,
    $sbFilterMenu,
    loadingToast,
    $timeout,
    $sbCalendar,
    $document,
    $mdToast,
    $sbTemplate,
    $sbLastPlannedSelectionStore,
    $sbLookAheadModification,
    $sbKeyboardWatcher,
    $sbLookAheadSessions,
    $sbCurrentProject,
    WEEK_BOARD_TIMESPAN_PER_COLUMN,
    WEEK_BOARD_VIEW_MODE,
    LANES_PER_PAGE,
    ITEM_COUNT_TO_PREFETCH,
    USER_INTERACTION,
    intercomService,
    TRACKED_EVENTS,
    $sbLeanBoardStore,
    Analytics
) {
    "ngInject";
    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    var vm = this;

    vm.isPageLoadComplete = false;
    vm.isPdfPrintingProgress = false;
    vm.hasAreaManagerRestrictions = false;
    vm.areaManagerFilterIsActive = false;
    vm.deliverablesOnCurrentBoard = [];
    vm.WEEK_BOARD_SIZES = WEEK_BOARD_SIZES;
    vm.zoomSize = WEEK_BOARD_SIZES.SMALL;
    vm.openSession = undefined;
    vm.bulkSelection = undefined;
    vm.isInSelectionMode = false;
    vm.projectId = undefined;
    vm.fetchingFromDeliverable = "";
    vm.WEEK_BOARD_TIMESPAN_PER_COLUMN = WEEK_BOARD_TIMESPAN_PER_COLUMN;
    vm.selectedColumnTimespan = WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY;
    vm.WEEK_BOARD_VIEW_MODE = WEEK_BOARD_VIEW_MODE;
    vm.selectedViewMode = WEEK_BOARD_VIEW_MODE.DELIVERABLE;
    vm.activeFilterCount = 0;
    vm.areaManagerUserName = "";
    vm.isSomethingForecasted = false;
    vm.wasLastDeliverableViewShowingActuals = false;
    vm.showBackButton = false;
    vm.showingSubbar = true;
    vm.allTeamsSelection = "ALL_TEAM_SELECTION";

    vm.showDeliverableDetails = showDeliverableDetails;
    vm.showAggregatedDeliverables = showAggregatedDeliverables;
    vm.forecastDatesFor = forecastDatesFor;
    vm.getWeekOfTimeFrame = $sbLeanBoard.getWeekOfTimeFrame;
    vm.updateUrlParameters = updateUrlParameters;
    vm.onActivityClicked = onActivityClicked;
    vm.changeSize = changeSize;
    vm.onShowLateActivities = onShowLateActivities;
    vm.onExitPageWithOpenSession = onExitPageWithOpenSession;
    vm.onToggleSession = onToggleSession;
    vm.onToggleBulkChanges = onToggleBulkChanges;
    vm.onCancelBulkChanges = onCancelBulkChanges;
    vm.onForecastDatesForAllDeliverables = onForecastDatesForAllDeliverables;
    vm.onSaveAndRelease = onSaveAndRelease;
    vm.onWeekChanged = onWeekChanged;
    vm.onSwitchColumnTimespan = onSwitchColumnTimespan;
    vm.onSwitchView = onSwitchView;
    vm.onAcceptAllForecasted = onAcceptAllForecasted;
    vm.toggleActuals = toggleActuals;
    vm.goBack = restoreStructureView;

    vm.optionsPanel = {
        isZoomSizeLarge: vm.zoomSize !== WEEK_BOARD_SIZES.SMALL,
        isShowingActuals: false,
    };

    vm.filterMenu = {
        selectedStructure: null,
        selectedTeam: null,
        selectedProcessTemplate: null,
        structures: undefined,
        teams: undefined,
        processTemplates: undefined,
        allProcessTemplatesSelection: null,
        searchTerm: "",

        // functions
        onToggleAreaManagerFilter: onToggleAreaManagerFilter,
        onViewModelSetupComplete: setAreaManagerFilterAndInitViewModel,
        onFilterByStructure: onFilterByStructure,
        onFilterByTeam: onFilterByTeam,
        onFilterByProcessTemplate: onFilterByProcessTemplate,
    };

    vm.pagination = {
        hasNext: false,
        hasPrevious: false,
        onNext: onNext,
        onPrevious: onPrevious,
        paginator: null,
    };

    vm.downloadLeanboard = downloadLeanboard;
    /////////////////////
    //
    //      WATCHER
    //
    /////////////////////

    var deliverableChangeWatcher = $sbDeliverables.createChangeWatcherFor(
        [$sbDeliverables.DELIVERABLE_CHANGE_TYPES.BASELINE],
        () => initDataStateFromVm()
    );

    var activityUpdateWatcher = $rootScope.$on(
        EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
        () => initDataStateFromVm()
    );

    var noteUpdateWatcher = $rootScope.$on(EVENTS.NOTE_CHANGED, () =>
        initDataStateFromVm()
    );

    // should be replaced by uiCanExit view hook when upgrading ui-router
    //
    var onStateChangeListener = $rootScope.$on(
        "$stateChangeStart",
        _onStateChangeStart
    );

    const searchListener = $scope.$on(
        EVENTS.GLOBAL_SEARCH_CHANGE,
        function (event, { searchTerm }) {
            $sbTracking.leanBoard.search();
            return _onFilter("searchTerm", searchTerm, "search");
        }
    );

    $scope.$on("$destroy", function () {
        deliverableChangeWatcher();
        activityUpdateWatcher();
        noteUpdateWatcher();
        onStateChangeListener();
        searchListener();
    });

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    function onInit() {
        _showFakeReload();
        intercomService.track(TRACKED_EVENTS.ENTERED_LEANBOARD_PAGE);
        $log.debug("LeanboardCtrl::onInit - Initialize page");

        /* The default parameter object indicates that the user navigated here
         *  through the menu --> check if there is a stored value
         *
         *  An object with parameters different than the defaults
         * */

        if (_isDefaultParamObject($state.params)) {
            const savedParams = $sbLeanBoardStore.getLastSelection();
            updateUrlParameters(savedParams);
        }

        vm.projectId = $sbCurrentProject.pick("id");
        vm.bulkSelection = $sbLastPlannedSelectionStore.getSelections(
            vm.projectId
        );
        vm.isInSelectionMode =
            $sbLastPlannedSelectionStore.hasActiveSelections();

        vm.timeframe = parseTimeFrameFromParameters($stateParams);
        vm.zoomSize = $stateParams.zoomSize
            ? $stateParams.zoomSize
            : vm.WEEK_BOARD_SIZES.SMALL;
        vm.optionsPanel.isZoomSizeLarge =
            vm.zoomSize === vm.WEEK_BOARD_SIZES.LARGE;

        vm.selectedColumnTimespan = $stateParams.columnTimespan
            ? $stateParams.columnTimespan
            : vm.WEEK_BOARD_TIMESPAN_PER_COLUMN.DAY;

        vm.selectedViewMode = $stateParams.viewBy
            ? $stateParams.viewBy
            : WEEK_BOARD_VIEW_MODE.DELIVERABLE;

        return $q
            .all({
                filterMenuLists: $sbFilterMenu.fetchTeamsAndStructures(),
                calendar: $sbProject.getCalendar(vm.projectId),
                session: _fetchAndSetOpenSession(),
                processTemplates: $sbTemplate.getTemplates(),
            })
            .then(function ({ filterMenuLists, calendar, processTemplates }) {
                vm.filterMenu.teams = filterMenuLists.teams.map((team) => {
                    return new FilterMenuListItem(team.getDisplayName(), team);
                });

                vm.filterMenu.structures = filterMenuLists.structures.map(
                    (structure) => {
                        return new FilterMenuListItem(
                            structure.PARENT_PATH.join("/"),
                            structure
                        );
                    }
                );

                vm.filterMenu.processTemplates = processTemplates
                    .filter(({ USED_IN_COMPONENTS }) => USED_IN_COMPONENTS > 0)
                    .map((processTemplate) => {
                        return new FilterMenuListItem(
                            processTemplate.NAME,
                            processTemplate
                        );
                    });

                calendar.exceptionDates = calendar.exceptionDates.map(
                    (exception) => {
                        return moment.isMoment(exception)
                            ? exception
                            : exception.date;
                    }
                );
                vm.calendar = calendar;

                const consumableCalendar =
                    $sbCalendar.ConsumableCalendar.createFrom({
                        days: vm.calendar.DAYS,
                        blocks: vm.calendar.BLOCKS,
                        exceptionDates: vm.calendar.exceptionDates,
                    });

                $sbLastPlanned.setConsumableCalendar(consumableCalendar);

                const nonWorkingDays = $sbLeanBoard.collectNonWorkingDays(
                    vm.calendar
                );
                vm.timeframe.setNonWorkingDays(
                    nonWorkingDays,
                    vm.calendar.exceptionDates
                );

                _setSelectedStructureFilterFromStateParams();
                _setSelectedTeamFilterFromStateParams();
                _setSelectedProcessTemplateFilterFromStateParams();
                _setShowActualsFromStateParams();
                _trackWasPageOpenedWithTeamFilterApplied();
                _updateActiveFilterCount();
                return initDataStateFromVm().then(function () {
                    vm.isPageLoadComplete = true;

                    // This is a hack to refresh the subbar.
                    // For some reason if not refreshed extra duplicate subbar elements are attached to the subbar.
                    // To reproduce remove this code and navigate back and forth to this page and another one through the menu.
                    // On first land on weekplan everything seem fine, after the first back and forth you can see the error
                    vm.showingSubbar = false;
                    $timeout(() => {
                        vm.showingSubbar = true;
                    });
                    _hideFakeReload();
                });
            });
    }

    function _setShowActualsFromStateParams() {
        if (vm.selectedViewMode === vm.WEEK_BOARD_VIEW_MODE.STRUCTURE) {
            vm.optionsPanel.isShowingActuals = false;
        } else {
            vm.optionsPanel.isShowingActuals =
                $stateParams.showActualDates === "true";
        }

        updateUrlParameters(
            {
                showActualDates: vm.optionsPanel.isShowingActuals,
            },
            false
        );
    }

    function _setSelectedStructureFilterFromStateParams() {
        const selectedStructure =
            _.find(
                vm.filterMenu.structures,
                _.matchesProperty("value.ID", $stateParams.structureId)
            ) || null;

        vm.filterMenu.selectedStructure =
            _.get(selectedStructure, "value") || null;
    }

    function _setSelectedTeamFilterFromStateParams() {
        if (!vm.filterMenu.selectedTeam) {
            const selectedTeam = _.find(
                vm.filterMenu.teams,
                _.matchesProperty("value.id", Number($stateParams.selectedTeam))
            );
            vm.filterMenu.selectedTeam = _.get(selectedTeam, "value");
        }

        if (!vm.filterMenu.selectedTeam) {
            // All teams
            vm.filterMenu.selectedTeam = vm.allTeamsSelection;
        }
    }

    function _setSelectedProcessTemplateFilterFromStateParams() {
        if (!vm.filterMenu.selectedProcessTemplate) {
            const selectedProcessTemplate = _.find(
                vm.filterMenu.processTemplates,
                _.matchesProperty(
                    "value.ID",
                    $stateParams.selectedProcessTemplate
                )
            );

            vm.filterMenu.selectedProcessTemplate = _.get(
                selectedProcessTemplate,
                "value"
            );
        }

        if (!vm.filterMenu.selectedProcessTemplate) {
            // All process templates
            vm.filterMenu.selectedProcessTemplate =
                vm.filterMenu.allProcessTemplatesSelection;
        }
    }

    function _trackWasPageOpenedWithTeamFilterApplied() {
        if (vm.filterMenu.selectedTeam === vm.allTeamsSelection) {
            $sbTracking.leanBoard.open.withShowAll();
        } else {
            $sbTracking.leanBoard.open.withTeamFilter();
        }
    }

    function _onStateChangeStart(event, toState, toParams, fromState) {
        var LEAN_BOARD_STATE_NAME = SABLONO_STATES.leanboard;

        var isLeavingLeanBoard =
            toState.name !== LEAN_BOARD_STATE_NAME &&
            fromState.name === LEAN_BOARD_STATE_NAME;
        if (isLeavingLeanBoard) {
            // he might need to sign in again
            //
            var isForcedRedirect =
                angular.isDefined(toState.data) && !toState.data.forLogin;
            if (isForcedRedirect && vm.openSession) {
                event.preventDefault(); // prevent state change
                vm.onExitPageWithOpenSession(event, toState, toParams);
            }
        }
    }

    function initDataStateFromVm() {
        const options = _makeOptionsForRequest({
            ignoreActuals: !vm.optionsPanel.isShowingActuals,
            paging: {
                pageNumber: $state.params.page,
                pageSize: LANES_PER_PAGE,
            },
        });

        return $sbLeanBoardFacade
            .getAggregatesInTimeFrame(options)
            .then((pager) => {
                vm.showBackButton = false;
                vm.pagination.paginator = $sbPagination.getNewVirtualPaginator(
                    pager,
                    {
                        pageSize: LANES_PER_PAGE,
                        prefetchDistance: ITEM_COUNT_TO_PREFETCH,
                    }
                );

                // add error listener to react to internal paging errors
                vm.pagination.paginator.addEventListener({
                    onError(err) {
                        $sbErrorPresenter.catch(err);
                    },
                });
            })
            .then(() => _initBoardAndPagination())
            .then(() => _calculateIsForecastEnabled())
            .then(() => _calculateIsAnActivityForecasted())
            .catch((err) => $sbErrorPresenter.catch(err));
    }

    function _calculateIsForecastEnabled() {
        vm.isForecastEnabled = $sbLastPlanned.calculateIsForecastEnabled(
            vm.pagination.paginator.getAllLocalItems()
        );
    }

    function _calculateIsAnActivityForecasted() {
        vm.isSomethingForecasted = $sbLastPlanned.isAnActivityForecasted(
            vm.pagination.paginator.getAllLocalItems()
        );
    }

    /**
     * Read the object and create a timeframe object
     *
     * @param {Object} parameters - state parameters like object
     * @returns {LeanboardTimeframe}
     */
    function parseTimeFrameFromParameters(parameters) {
        var from = parameters.from;
        var to = parameters.to;
        var dateFormat = parameters.format;

        $log.debug("parameters: ", from, to, dateFormat);

        // first second of the given day
        //
        var fromMoment = moment(from, dateFormat).startOf("day");

        // last second of given day
        //
        var toMoment = moment(to, dateFormat).endOf("day");

        if (from && fromMoment.isValid() && toMoment.isValid()) {
            $log.debug("given time frame");
            return $sbLeanBoard.getTimeFrameOf(fromMoment, toMoment);
        } else {
            $log.debug("default time frame");
            return $sbLeanBoard.getDefaultTimeFrame();
        }
    }

    function _makeOptionsForRequest(customOptions = {}) {
        const timeframe = vm.timeframe;
        const aggregateInto = vm.selectedViewMode;
        const isMyArea = vm.areaManagerFilterIsActive;
        const selectedStructure = vm.filterMenu.selectedStructure;
        const selectedProcessTemplate = vm.filterMenu.selectedProcessTemplate;
        const selectedTeam =
            vm.filterMenu.selectedTeam === vm.allTeamsSelection
                ? null
                : vm.filterMenu.selectedTeam;
        const searchTerm = vm.filterMenu.searchTerm;
        // Force include late in structure view so that the aggregates include them from the start:
        const includeLate =
            vm.selectedViewMode === vm.WEEK_BOARD_VIEW_MODE.STRUCTURE
                ? true
                : undefined;
        const columnTimespan = vm.selectedColumnTimespan;
        const isShowingActuals = vm.optionsPanel.isShowingActuals;
        return _.merge(customOptions, {
            timeframe,
            aggregateInto,
            includeLate,
            columnTimespan,
            isShowingActuals,
            filters: {
                isMyArea,
                selectedStructure,
                selectedProcessTemplate,
                selectedTeam,
                searchTerm,
            },
        });
    }

    function showDeliverableDetails(deliverable) {
        $sbDetailsOverlay.toggleView("deliverable", deliverable.id);
    }

    function _initBoardAndPagination() {
        _setNumberOfDeliverablesAvailableForBoard();
        _initDeliverablesForCurrentBoard();
        _determinePaginateButtonVisibility();
    }

    function _setNumberOfDeliverablesAvailableForBoard() {
        vm.count = _.get(vm.pagination, "paginator.total", 0);
    }

    function _initDeliverablesForCurrentBoard() {
        vm.deliverablesOnCurrentBoard = vm.pagination.paginator.getPageAt(
            $state.params.page
        );

        updateUrlParameters(
            {
                page: vm.pagination.paginator.pageNumber,
            },
            false
        );
    }

    function _onChangeActivityDates(activity, decisions) {
        if (vm.selectedViewMode === WEEK_BOARD_VIEW_MODE.DELIVERABLE) {
            return _onDeliverableViewChangeActivityDays(activity, decisions);
        } else {
            const activeFilters = _makeOptionsForRequest().filters;

            return _onStructureViewChangeActivityDays(
                activity,
                decisions,
                activeFilters,
                vm.areaManagerUserName
            );
        }
    }

    function _onDeliverableViewChangeActivityDays(activity, decisions) {
        if (decisions.updateDependants) {
            return _rescheduleLastPlannedDatesFrom(
                activity,
                decisions.userDefinedChanges
            );
        } else {
            return _updateLastPlannedDatesFor(
                activity,
                decisions.userDefinedChanges
            );
        }
    }

    function _onStructureViewChangeActivityDays(
        activity,
        decisions,
        activeFilters,
        areaManagerUserName
    ) {
        // deliverable is actually structure in this case
        const selectedStructure = _.find(
            vm.filterMenu.structures,
            (structure) => structure.value.ID === activity.deliverable.id
        );
        activeFilters.selectedStructure = selectedStructure.value;
        if (decisions.updateDependants) {
            return $sbLastPlanned.rescheduleLastPlannedDatesForStructureActivity(
                activity,
                decisions.userDefinedChanges,
                activeFilters,
                areaManagerUserName
            );
        } else {
            return $sbLastPlanned.updateLastPlannedDatesForStructureActivity(
                activity,
                decisions.userDefinedChanges,
                activeFilters,
                areaManagerUserName
            );
        }
    }

    function _updateLastPlannedDatesFor(activity, constraints) {
        return $sbLastPlanned.updateLastPlannedDatesFor(activity, constraints);
    }

    function _rescheduleLastPlannedDatesFrom(activity, constraints) {
        return $sbLastPlanned.rescheduleLastPlannedDatesFrom(
            activity,
            constraints
        );
    }

    function onFilterByTeam(team) {
        $sbTracking.filterMenu.byTeam("Week Plan");
        _showFakeReload();
        vm.filterMenu.selectedTeam = team;
        updateUrlParameters(
            {
                selectedTeam: team === vm.allTeamsSelection ? null : team.id,
            },
            false
        );
        _updateActiveFilterCount();
        return initDataStateFromVm().then(() => _hideFakeReload());
    }

    function onFilterByProcessTemplate(processTemplate) {
        $sbTracking.filterMenu.byProcessTemplate("Week Plan");

        return _onFilter(
            "selectedProcessTemplate",
            processTemplate,
            "selectedProcessTemplate"
        );
    }

    function onFilterByStructure(structure) {
        $sbTracking.filterMenu.byProjectStructure("Week Plan");

        return _onFilter("selectedStructure", structure, "structureId");
    }

    function _onFilter(menuEntry, menuValue, param) {
        _showFakeReload();

        vm.filterMenu[menuEntry] = menuValue;

        const paramUpdate = {};
        paramUpdate[param] = (menuValue && menuValue.ID) || null;

        updateUrlParameters(paramUpdate, false);
        _updateActiveFilterCount();

        return initDataStateFromVm().then(() => _hideFakeReload());
    }

    function onWeekChanged(from, to, format) {
        _showFakeReload();

        const nextTimeFrame = parseTimeFrameFromParameters({
            from,
            to,
            format,
        });
        this.timeframe.start = nextTimeFrame.start;
        this.timeframe.end = nextTimeFrame.end;
        updateUrlParameters(
            {
                from,
                to,
                format,
            },
            false
        );

        return initDataStateFromVm().then(() => _hideFakeReload());
    }

    function changeSize() {
        vm.zoomSize = vm.optionsPanel.isZoomSizeLarge
            ? vm.WEEK_BOARD_SIZES.LARGE
            : vm.WEEK_BOARD_SIZES.SMALL;

        updateUrlParameters({
            zoomSize: vm.zoomSize,
        });

        $sbTracking.leanBoard.change.zoomSize(vm.zoomSize);
    }

    function updateUrlParameters(parameters, notifyBrowser = true) {
        // we need to use $state.params instead of $stateParams
        // as $stateParams does keep the values from the initialisation
        //
        const params = { ...$state.params, ...parameters };

        $sbLeanBoardStore.setSelection(params);

        $state.go(SABLONO_STATES.leanboard, params, {
            location: "replace",
            notify: notifyBrowser,
        });
    }

    function onToggleAreaManagerFilter(areaManagerSettings) {
        $sbTracking.filterMenu.byConstructionManager("Week Plan");

        if (areaManagerSettings.hasAreaManagerRestrictions) {
            vm.areaManagerFilterIsActive = areaManagerSettings.isActive;
            _updateActiveFilterCount();
            _showFakeReload();

            initDataStateFromVm().then(() => _hideFakeReload());
        }
    }

    function setAreaManagerFilterAndInitViewModel(areaManagerSettings) {
        if (areaManagerSettings.hasAreaManagerRestrictions) {
            vm.areaManagerFilterIsActive = areaManagerSettings.isActive;
            vm.areaManagerUserName = areaManagerSettings.userName;
            _updateActiveFilterCount();
        }
        onInit();
    }

    function _updateActiveFilterCount() {
        vm.activeFilterCount = [
            vm.areaManagerFilterIsActive,
            vm.filterMenu.selectedTeam !== vm.allTeamsSelection,
            vm.filterMenu.selectedStructure,
            vm.filterMenu.selectedProcessTemplate &&
                vm.filterMenu.selectedProcessTemplate.ID,
        ].reduce((count, isTrue) => count + (isTrue ? 1 : 0), 0);
    }

    function onActivityClicked(activity, deliverable, panelRef) {
        if (vm.isInSelectionMode) {
            return _selectActivity(activity);
        }

        if (vm.selectedViewMode === WEEK_BOARD_VIEW_MODE.STRUCTURE) {
            return openStructureActivityDetailsPanel(
                activity,
                deliverable,
                panelRef
            );
        } else {
            return openActivityDetailsPanel(activity, deliverable, panelRef);
        }
    }

    function _selectActivity(activity) {
        if (!_isValidSelection(activity)) {
            return _showCompletedActivitySelectedWarning();
        }

        if (_isSelectSimilarActivitiesActive()) {
            _handleMultiSelect(activity);
        } else {
            _handleSingeSelect(activity);
        }
    }

    function _isValidSelection(activity) {
        return !_.isUndefined(activity) && !activity.state.isCompleted();
    }

    function _handleMultiSelect(activity) {
        if ($sbLastPlannedSelectionStore.isSelected(activity)) {
            $sbLastPlannedSelectionStore.clearCurrentSelection();
        } else {
            $sbLastPlannedSelectionStore.clearCurrentSelection();
            $sbTracking.leanBoard.selection.onActivities();
            _selectAllSimilarActivitiesOf(activity);
        }
    }

    function _handleSingeSelect(activity) {
        const { failure } = _guardMultipleSelectionPerDeliverable(activity);
        if (failure && !$sbLastPlannedSelectionStore.isSelected(activity)) {
            handleBlockedMultiSelectByDeliverable();
        } else {
            const isSelected =
                $sbLastPlannedSelectionStore.toggleSelection(activity);
            $sbTracking.leanBoard.selection.onActivity(isSelected);
        }
    }

    function _showCompletedActivitySelectedWarning() {
        return $mdDialog.show(
            $mdDialog
                .alert()
                .title(
                    "WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_TITLE"
                )
                .content(
                    "WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_MESSAGE"
                )
                .ok("DIALOG_ALERT_OK")
        );
    }

    function _isSelectSimilarActivitiesActive() {
        return (
            $sbKeyboardWatcher.isCurrentKey() &&
            $sbKeyboardWatcher.getCurrentKey().altKey
        );
    }

    function _guardMultipleSelectionPerDeliverable(activity) {
        const deliverable = activity.laneRecord;
        const isDeliverableAlreadySelected = deliverable
            .getActivities()
            .some((a) => $sbLastPlannedSelectionStore.isSelected(a));
        if (isDeliverableAlreadySelected) {
            return {
                failure: true,
            };
        }
        return {
            failure: false,
        };
    }

    function _selectAllSimilarActivitiesOf(activity) {
        const deliverableIdentity = _.pick(activity.laneRecord, "location");
        const activityIdentity = _.pick(activity, "templateId");

        _.filter(vm.pagination.paginator.getCurrentPage(), deliverableIdentity)
            .map((deliverable) =>
                _.find(deliverable.getActivities(), activityIdentity)
            )
            .filter((similarActivity) => _isValidSelection(similarActivity))
            .forEach($sbLastPlannedSelectionStore.toggleSelection);
    }

    function handleBlockedMultiSelectByDeliverable() {
        $sbTracking.leanBoard.selection.error();
        return $mdDialog.show(
            $mdDialog
                .alert()
                .title("WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_TITLE")
                .content("WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_MESSAGE")
                .ok("DIALOG_ALERT_OK")
        );
    }

    function openStructureActivityDetailsPanel(activity, deliverable) {
        const target = $document[0].getElementById(
            activity.templateId + activity.laneRecord.location
        );
        const activityToDisplayInDialog = _.clone(activity);
        decorateDisplayedDates(
            activityToDisplayInDialog,
            vm.optionsPanel.isShowingActuals
        );
        $sbTracking.leanBoard.activity.onClick();
        return $mdPanel
            .open("$sbStructureActivityDetailsPanel", {
                id: "$sbStructureActivityDetailsPanel",
                position: $mdPanel
                    .newPanelPosition()
                    .relativeTo(target)
                    .addPanelPosition("align-start", "below"),
                locals: {
                    activity: activityToDisplayInDialog,
                    deliverable: deliverable,
                    openSession: vm.openSession,
                    calendar: vm.calendar,
                    onCreateNewSession: createNewSession,
                    hasOpenSession: hasOpenSession,
                    onDateChange: onStructureActivityChangeDates,
                    closeSelectionMode: closeSelectionMode,
                },
            })
            .then(function (lastPanelRef) {
                // in case of session already open error - need this reference so we can
                // close the details panel and show a dialog with a meaningful message
                //
                vm.lastPanelRef = lastPanelRef;
            });
    }

    function openActivityDetailsPanel(activity, deliverable, panelRef) {
        const target = $document[0].getElementById(activity.id);
        const activityToDisplayInDialog = _.clone(activity);
        decorateDisplayedDates(
            activityToDisplayInDialog,
            vm.optionsPanel.isShowingActuals
        );

        $sbTracking.leanBoard.activity.onClick();
        return $mdPanel
            .open("$sbActivityDetailsPanel", {
                id: "$sbActivityDetailsPanel",
                position: $mdPanel
                    .newPanelPosition()
                    .relativeTo(target)
                    .addPanelPosition("align-start", "below"),
                locals: {
                    activity: activityToDisplayInDialog,
                    deliverable: deliverable,
                    openSession: vm.openSession,
                    calendar: vm.calendar,
                    onCreateNewSession: createNewSession,
                    hasOpenSession: hasOpenSession,
                    fetchAndExtendActivity: function (activity) {
                        return $sbLastPlanned
                            .fetchAndExtendActivity(activity)
                            .catch(function (error) {
                                $sbErrorPresenter.catch(error);
                                return activity;
                            });
                    },
                    onChangeActivityDates: function (
                        activityDateChangeRequest,
                        decisions
                    ) {
                        _toggleIsReloadingFor(deliverable);
                        activityDateChangeRequest.deliverable = deliverable;

                        // Close the parent panel if one exists
                        if (panelRef) {
                            panelRef.close();
                        }

                        return _onChangeActivityDates(
                            activityDateChangeRequest,
                            decisions
                        )
                            .then(() => _loadAndUpdateDeliverable(deliverable))
                            .then(() =>
                                _showSuccessToast(
                                    "ACTIVITY_DETAILS_SAVED_IN_SESSION"
                                )
                            )
                            .catch(function (error) {
                                _handleOnChangeActivityDatesError(error);
                                _toggleIsReloadingFor(deliverable);
                            });
                    },
                },
            })
            .then(function (lastPanelRef) {
                // in case of session already open error - need this reference so we can
                // close the details panel and show a dialog with a meaningful message
                //
                vm.lastPanelRef = lastPanelRef;
            });
    }

    function decorateDisplayedDates(activity, isShowingActuals) {
        LeanBoardDisplayDatesDecorator.attachOrigins(
            activity,
            isShowingActuals
        );

        LeanBoardDisplayDatesDecorator.deriveDisplayedDuration(activity);
    }

    function hasOpenSession() {
        return !_.isUndefined(vm.openSession);
    }

    function _toggleIsReloadingFor(deliverable) {
        const _deliverable = _.find(vm.deliverablesOnCurrentBoard, [
            "id",
            deliverable.id,
        ]);
        if (_deliverable) {
            _deliverable.toggleIsReloading();
        }
    }

    function createNewSession() {
        $sbTracking.leanBoard.session.start();
        Analytics.trackConversion("session started");

        if (!vm.optionsPanel.isShowingActuals) {
            return toggleActuals().then(
                $sbLookAheadSessions
                    .createNewSession()
                    .then(_setViewModelOpenSessionFromService)
                    .then(_updateToolbarColor)
                    .catch($sbErrorPresenter.catch)
            );
        }

        return $sbLookAheadSessions
            .createNewSession()
            .then(_setViewModelOpenSessionFromService)
            .then(_updateToolbarColor)
            .catch($sbErrorPresenter.catch);
    }

    function _handleOnChangeActivityDatesError(error) {
        if (
            error.message === "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION"
        ) {
            $sbTracking.leanBoard.session.concurrentSession(
                "Tried to change a deliverable that has been changed in another session"
            );

            showModifiedInConcurrentSessionDialog(error);
        } else {
            return $sbErrorPresenter.catch(error);
        }
    }

    function showModifiedInConcurrentSessionDialog(error) {
        if (vm.lastPanelRef) {
            vm.lastPanelRef.close();
        }

        return $sbDialog.openModifiedInConcurrentSessionDialog(error);
    }

    function closeOpenSession() {
        _showLoadingToast();
        closeSelectionMode();

        return $sbLookAheadSessions
            .closeOpenSession(vm.openSession.id)
            .then(_fetchAndSetOpenSession)
            .then(initDataStateFromVm)
            .catch($sbErrorPresenter.catch)
            .finally(_hideLoadingToast);
    }

    function closeOpenSessionAndReleaseChanges() {
        _showLoadingToast();
        closeSelectionMode();

        return $sbLookAheadSessions
            .closeOpenSessionAndReleaseChanges(vm.openSession.id)
            .then(_fetchAndSetOpenSession)
            .then(initDataStateFromVm)
            .catch(_handleOnChangeActivityDatesError)
            .finally(_hideLoadingToast);
    }

    function forecastDatesForAllDeliverables() {
        return _openConfirmationDialogForForecastingDates()
            .then(function () {
                _showLoadingToast();

                Analytics.trackConversion("dates forecasted");
                $sbTracking.leanBoard.session.onForecastAllDeliverables();

                const activeFilters = _makeOptionsForRequest().filters;
                return $sbLastPlanned
                    .forecastDatesForAllDeliverables(
                        vm.calendar,
                        activeFilters,
                        vm.areaManagerUserName
                    )
                    .then(_fetchAndSetOpenSession)
                    .then(initDataStateFromVm)
                    .catch($sbErrorPresenter.catch)
                    .finally(_hideLoadingToast);
            })
            .catch(function () {
                $sbTracking.leanBoard.session.onCancelForecast();
            });
    }

    function _openConfirmationDialogForForecastingDates($event) {
        return $mdDialog.show(
            $mdDialog
                .confirm()
                .title("FORECAST_DATES_CONFIRM_DIALOG_TITLE")
                .titleIcon("mdi mdi-auto-fix")
                .content("FORECAST_DATES_CONFIRM_DIALOG_TITLE_CONTENT")
                .cancel("NO")
                .ok("YES")
                .targetEvent($event)
        );
    }

    function _openConfirmationDialogForAcceptingAllForecastedDates($event) {
        return $mdDialog.show(
            $mdDialog
                .confirm()
                .title("FORECAST_DATES_ACCEPT_ALL_DIALOG_TITLE")
                .titleIcon("mdi mdi-auto-fix")
                .content("FORECAST_DATES_ACCEPT_ALL_DIALOG_CONTENT")
                .cancel("NO")
                .ok("YES")
                .targetEvent($event)
        );
    }

    function forecastDatesFor(aggregate) {
        _toggleIsReloadingFor(aggregate);

        let forecastDates;
        if (vm.selectedViewMode === WEEK_BOARD_VIEW_MODE.DELIVERABLE) {
            forecastDates = $sbLastPlanned.forecastDatesForDeliverable;
            $sbTracking.leanBoard.session.onForecastSingleDeliverable();
        } else {
            forecastDates = $sbLastPlanned.forecastDatesForStructure;
            $sbTracking.leanBoard.session.onForecastSingleStructure();
            aggregate = _.find(vm.filterMenu.structures, [
                "value.ID",
                aggregate.id,
            ]).value;
        }

        return forecastDates(aggregate, vm.calendar)
            .then(() => _loadAndUpdateDeliverable(aggregate))
            .then(() => _showSuccessToast("_DONE"))
            .catch(function (error) {
                _toggleIsReloadingFor(aggregate);
                return $sbErrorPresenter.catch(error);
            });
    }

    function _fetchAndSetOpenSession() {
        return $sbLookAheadSessions
            .fetchAndSetOpenSessions()
            .then(_setViewModelOpenSessionFromService)
            .then(_updateToolbarColor)
            .then(_updateSelection)
            .catch($sbErrorPresenter.catch);
    }

    function _setViewModelOpenSessionFromService() {
        vm.openSession = $sbLookAheadSessions.findAndGetOpenSessionFor(
            $sbMembership.currentUser()
        );
        $log.debug("Current open session running as: ", vm.openSession);
    }

    function _updateToolbarColor() {
        $rootScope.$emit(
            EVENTS.PLAN_WORK_REVIEW_SECTION_CHANGED,
            vm.openSession ? "PLAN" : "WORK"
        );
    }

    function _updateSelection() {
        if (!vm.openSession) {
            closeSelectionMode();
        }
    }

    function onExitPageWithOpenSession($event, toState, toStateParams) {
        // stops the toolbar changing color before leaving the page
        //
        _updateToolbarColor();

        $mdDialog.show(
            $mdDialog
                .exitRunningSession()
                .onStay($sbTracking.leanBoard.session.stayOnPage)
                .onDiscard(function () {
                    closeOpenSession().then(function () {
                        $sbTracking.leanBoard.session.exitPageWithDiscard();
                        $state.go(toState, toStateParams);
                    });
                })
                .onRelease(function () {
                    closeOpenSessionAndReleaseChanges().then(function () {
                        $sbTracking.leanBoard.session.exitPageWithRelease();
                        $state.go(toState, toStateParams);
                    });
                })
                .onKeepOpen(function () {
                    // on page leave we always check if there is an active session for the user.
                    // if they choose to keep it open - we don't want to close the session, but we need to clear it
                    // from the view model to prevent infinite loop of onExitPageWithOpenSession
                    //
                    vm.openSession = undefined;
                    $sbTracking.leanBoard.session.exitPageWithoutRelease();
                    $state.go(toState, toStateParams);
                })
        );
    }

    function _loadAndUpdateDeliverable(laneRecord) {
        const options = _makeOptionsForRequest({
            includeLate: true,
            filters: {
                searchTerm: laneRecord.name,
            },
            paging: {
                pageSize: 100,
            },
        });

        return $sbLeanBoardFacade
            .getAggregatesInTimeFrame(options)
            .then((pager) => {
                vm.pagination.paginator.pager.mergePagingData(pager, "id");
                _initBoardAndPagination();
                _calculateIsForecastEnabled();
                _calculateIsAnActivityForecasted();
                return _.find(pager.allLocalItems(), ["id", laneRecord.id]);
            })
            .catch((err) => $sbErrorPresenter.catch(err));
    }

    function onShowLateActivities($event, clickedDeliverable) {
        $sbTracking.leanBoard.deliverable.onLateActivitiesPanelClick();
        vm.fetchingFromDeliverable = clickedDeliverable.id;

        const loadDeliverableIncludingLateActivities = () => {
            if (clickedDeliverable.numberOfLateActivities === 0) {
                return $q.resolve(clickedDeliverable);
            } else {
                return _loadAndUpdateDeliverable(clickedDeliverable);
            }
        };

        loadDeliverableIncludingLateActivities().then((deliverable) => {
            vm.fetchingFromDeliverable = "";
            const lateActivities = _.sortBy(
                deliverable.getActivitiesInLateSection(),
                ["topologicalIndex", "name"]
            );
            showLateActivitiesPanel($event, {
                deliverable: deliverable,
                lateActivities,
                onActivityClicked: onActivityClicked,
                isActivitySelected: $sbLastPlannedSelectionStore.isSelected,
            });
        });
    }

    function showLateActivitiesPanel($event, locals) {
        const panelAnimation = $mdPanel
            .newPanelAnimation()
            .duration(100)
            .openFrom($event)
            .withAnimation($mdPanel.animation.SCALE);

        const panelPosition = $mdPanel
            .newPanelPosition()
            .relativeTo($event.target)
            .addPanelPosition(
                $mdPanel.xPosition.CENTER,
                $mdPanel.yPosition.BELOW
            )
            .withOffsetY("12px");

        return $mdPanel.open("$sbLateActivitiesPanel", {
            id: "$sbLateActivitiesPanel",
            position: panelPosition,
            animation: panelAnimation,
            locals: locals,
        });
    }

    function onNext() {
        _showFakeReload();

        vm.deliverablesOnCurrentBoard = vm.pagination.paginator.getNextPage();

        updateUrlParameters(
            {
                page: vm.pagination.paginator.pageNumber,
            },
            false
        );

        _determinePaginateButtonVisibility();

        _hideFakeReload();
    }

    function onPrevious() {
        _showFakeReload();

        vm.deliverablesOnCurrentBoard = vm.pagination.paginator.getPrevPage();

        updateUrlParameters(
            {
                page: vm.pagination.paginator.pageNumber,
            },
            false
        );

        _determinePaginateButtonVisibility();

        _hideFakeReload();
    }

    function _determinePaginateButtonVisibility() {
        vm.pagination.hasNext = vm.pagination.paginator.hasNextPage();
        vm.pagination.hasPrevious = vm.pagination.paginator.hasPrevPage();
    }

    function _showFakeReload() {
        vm.count = undefined;
    }

    function _hideFakeReload() {
        $timeout(function () {
            _setNumberOfDeliverablesAvailableForBoard();
        });
    }

    function _showLoadingToast() {
        vm.isFetchingData = true;
        loadingToast.show("INFO_LEANBOARD_UPDATING_SCHEDULE");
    }

    function _hideLoadingToast() {
        vm.isFetchingData = false;
        loadingToast.hide();
    }

    function downloadLeanboard() {
        if (vm.isPdfPrintingProgress) {
            return;
        }
        loadingToast.show("ACTION_PRINT_REPORT");
        vm.isPdfPrintingProgress = true;
        $sbTracking.leanBoard.print();

        return $sbLeanBoardFacade
            .getPDFDeliverablesInTimeFrame(_makeOptionsForRequest())
            .catch((err) => {
                if (err) {
                    if (
                        err.message ===
                        "ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF"
                    ) {
                        err.title =
                            "ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF_TITLE";
                    }

                    $sbErrorPresenter.catch(
                        err,
                        PresentableError.presentationStyle.DIALOG
                    );
                }
            })
            .finally(() => {
                vm.isPdfPrintingProgress = false;
                loadingToast.hide();
            });
    }

    function onToggleSession() {
        if (vm.openSession) {
            return closeOpenSession().then(function () {
                $sbTracking.leanBoard.session.cancel();
            });
        } else {
            return createNewSession();
        }
    }

    function onToggleBulkChanges() {
        if (!vm.isInSelectionMode) {
            $sbTracking.leanBoard.selection.started();
            vm.isInSelectionMode = true;
        } else {
            $sbTracking.leanBoard.bulkChange.started();
            return onBulkChangeStart(
                $sbLastPlannedSelectionStore.getSelectedObjects()
            );
        }
    }

    function onCancelBulkChanges() {
        closeSelectionMode();
        $sbTracking.leanBoard.selection.canceled();
    }

    function closeSelectionMode() {
        vm.isInSelectionMode = false;
        $sbLastPlannedSelectionStore.clearCurrentSelection();
    }

    function onSaveAndRelease() {
        return closeOpenSessionAndReleaseChanges().then(function () {
            $sbTracking.leanBoard.session.release();
        });
    }

    function onForecastDatesForAllDeliverables() {
        return forecastDatesForAllDeliverables();
    }

    function _showSuccessToast(message, content) {
        return $mdToast
            .show(
                $mdToast
                    .simple()
                    .content(message)
                    .contentValues(content)
                    .hideDelay(5000)
                    .position("top right")
            )
            .catch(() => {});
    }

    function _changeLookAheadDatesUsingDialog({
        beforeChange,
        applyChange,
        afterChange,
        onChangeError,
    }) {
        const bulkLookAheadDialog = $mdDialog
            .sbLookAheadDefinitionDialog()
            .calendar(vm.calendar);

        return $mdDialog
            .show(bulkLookAheadDialog)
            .then(function ({
                updateDependants,
                startDate,
                endDate,
                duration,
            }) {
                beforeChange();

                const definition = {
                    duration: duration,
                };
                if (moment.isMoment(startDate) && startDate.isValid()) {
                    definition.date = startDate;
                    definition.usedDate = USER_INTERACTION.START_DATE;
                } else if (moment.isMoment(endDate) && endDate.isValid()) {
                    definition.date = endDate;
                    definition.usedDate = USER_INTERACTION.END_DATE;
                }

                const userDefinedChanges =
                    $sbLookAheadModification.makeInteractions(definition);

                return applyChange({
                    definition,
                    updateDependants,
                    userDefinedChanges,
                    startDate,
                    endDate,
                    duration,
                })
                    .then(afterChange)
                    .catch((err) => {
                        onChangeError(err);
                        return _handleOnChangeActivityDatesError(err);
                    });
            })
            .catch((error) => {
                if (error) {
                    return $sbErrorPresenter.catch(error);
                }
            });
    }

    function onBulkChangeStart(selectedActivities) {
        return _changeLookAheadDatesUsingDialog({
            beforeChange: () => {
                selectedActivities.forEach((activity) =>
                    _toggleIsReloadingFor(activity.laneRecord)
                );
            },
            applyChange: ({
                updateDependants,
                userDefinedChanges,
                definition,
            }) => {
                const { applyModificationToActivities } =
                    $sbLookAheadModification.use(vm.calendar);
                const activities = applyModificationToActivities(
                    definition,
                    selectedActivities
                );
                activities.forEach(
                    $sbLastPlannedSelectionStore.updateStoreEntry
                );
                const allDateChangeRequests = activities.map((activity) => {
                    return _onChangeActivityDates(
                        {
                            id: activity.id,
                            templateId: activity.templateId,
                            deliverable: activity.laneRecord,
                            startDate: activity.startDate,
                            endDate: activity.endDate,
                            lastPlannedDuration: activity.duration,
                        },
                        {
                            updateDependants,
                            userDefinedChanges,
                        }
                    );
                });

                const busyDialog = $mdDialog
                    .busyIndication()
                    .allPromises(allDateChangeRequests)
                    .title("WORKFLOW_BULK_LOOK_AHEAD_BUSY_TITLE")
                    .stepText("WORKFLOW_BULK_LOOK_AHEAD_BUSY_PROGRESS");

                return $mdDialog
                    .show(busyDialog)
                    .then(initDataStateFromVm)
                    .then(() =>
                        _showSuccessToast(
                            "ACTIVITY_DETAILS_BULK_LOOK_AHEAD_SAVED",
                            {
                                all: selectedActivities.length,
                                changed: activities.length,
                            }
                        )
                    )
                    .then(() => activities);
            },
            afterChange: (activities) => {
                $sbTracking.leanBoard.bulkChange.finished(activities.length);
            },
            onChangeError: () => {
                selectedActivities.forEach((activity) =>
                    _toggleIsReloadingFor(activity.laneRecord)
                );
            },
        });
    }

    function onStructureActivityChangeDates(activity) {
        return _changeLookAheadDatesUsingDialog({
            beforeChange: () => {
                return _toggleIsReloadingFor(activity.laneRecord);
            },
            applyChange: ({
                updateDependants,
                userDefinedChanges,
                startDate,
                endDate,
                duration,
            }) => {
                const structureActivity = {
                    templateId: activity.templateId,
                    deliverable: activity.laneRecord,
                    lastPlannedDuration: duration,
                    startDate: startDate || activity.startDate,
                    endDate: endDate || activity.endDate,
                };

                const activeFilters = _makeOptionsForRequest().filters;
                return _onStructureViewChangeActivityDays(
                    structureActivity,
                    {
                        updateDependants,
                        userDefinedChanges,
                    },
                    activeFilters,
                    vm.areaManagerUserName
                )
                    .then(initDataStateFromVm)
                    .then(() =>
                        _showSuccessToast("ACTIVITY_DETAILS_SAVED_IN_SESSION")
                    );
            },
            afterChange: () => angular.noop(),
            onChangeError: () => {
                _toggleIsReloadingFor(activity.laneRecord);
            },
        });
    }

    function onSwitchColumnTimespan(newTimespan) {
        if (vm.selectedColumnTimespan === newTimespan) {
            return;
        }
        $sbTracking.leanBoard.change.columnTimespan(newTimespan);
        vm.selectedColumnTimespan = newTimespan;

        updateUrlParameters({
            columnTimespan: newTimespan,
        });
    }

    function onSwitchView(newView) {
        if (vm.selectedViewMode === newView) {
            return;
        }

        _showFakeReload();
        $sbTracking.leanBoard.change.viewAggregate(newView);

        setActualsStateBasedOnViewChange();

        vm.selectedViewMode = newView;

        updateUrlParameters({ viewBy: newView });

        return initDataStateFromVm().then(() => _hideFakeReload());
    }

    function setActualsStateBasedOnViewChange() {
        updateUrlParameters(
            {
                showActualDates: vm.wasLastDeliverableViewShowingActuals,
            },
            false
        );
    }

    function toggleActuals() {
        _showFakeReload();
        $sbTracking.leanBoard.change.actualDates(
            vm.optionsPanel.isShowingActuals
        );

        vm.wasLastDeliverableViewShowingActuals =
            !vm.wasLastDeliverableViewShowingActuals;
        updateUrlParameters(
            {
                showActualDates: vm.optionsPanel.isShowingActuals,
            },
            false
        );

        return initDataStateFromVm().then(() => _hideFakeReload());
    }

    function onAcceptAllForecasted() {
        return _openConfirmationDialogForAcceptingAllForecastedDates()
            .then(function () {
                _showLoadingToast();

                $sbTracking.leanBoard.session.onAcceptAllForecastedDeliverables();

                return $sbLastPlanned
                    .acceptAllForecastedDates()
                    .then(_fetchAndSetOpenSession)
                    .then(initDataStateFromVm)
                    .catch(_handleOnChangeActivityDatesError)
                    .finally(_hideLoadingToast);
            })
            .catch(function () {
                $sbTracking.leanBoard.session.onCancelAcceptAllForecastedDeliverables();
            });
    }

    /**
     *  Current implementation is you go back to the exact state you left from
     *  to see the deliverables that aggregate to the clicked structure.
     *  Improvement ideas are: only store filters and page
     */
    function showAggregatedDeliverables(structure) {
        $sbTracking.leanBoard.change.expandDeliverables();

        // persist view settings for back navigation
        //
        $sbLeanBoardFacade.saveViewSettings($state.params);

        // switch the view and filter by structure
        //
        const view = WEEK_BOARD_VIEW_MODE.DELIVERABLE;
        setActualsStateBasedOnViewChange();
        vm.selectedViewMode = view;

        const filterItemCorrespondingToStructure = _.find(
            vm.filterMenu.structures,
            (filterStructure) => filterStructure.value.ID === structure.id
        );

        vm.filterMenu
            .onFilterByStructure(filterItemCorrespondingToStructure.value)
            .then(() => {
                vm.showBackButton = true;
            });
    }

    function restoreStructureView() {
        $sbTracking.leanBoard.change.backToStructureView();
        const settings = $sbLeanBoardFacade.getViewSettings();
        $sbLeanBoardFacade.clearViewSettings();

        // close selection mode before going to structure
        closeSelectionMode();

        return $state.go(SABLONO_STATES.leanboard, settings, {
            reload: true,
            inherit: false,
        });
    }

    function _isDefaultParamObject(params) {
        const paramsWithValues = _.omitBy(params, _.isUndefined);
        return _.isEqual(paramsWithValues, {
            projectId: $sbProject.getCurrentProjectId(),
            page: 1,
        });
    }
}
