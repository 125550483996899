import angular from "angular";
import htmlTemplate from "./sb_deliverable_notes_row.html";
import NoteStateTransitionPermissionMatrixFactory from "../../../../../domain/sb_note_state_transition_permission_matrix_factory";

export default angular
    .module("sbApp.sbDeliverableNotesRow", [
        "sbApp.configs",
        "sbApp.services",
        "sbApp.dialog",
        "sbApp.directives",
    ])
    .directive("sbDeliverableNotesRow", function (TEMPLATE_CATEGORY_MAPPING) {
        return {
            restrict: "E",
            bindToController: true,
            controllerAs: "note",
            templateUrl: htmlTemplate,
            replace: true,
            scope: {
                row: "=",
                onStateChange: "&",
            },
            controller: function (
                $sbDeliverablesNoteStateService,
                SbNote,
                $sbProject,
                $sbMembership,
                $sbTracking
            ) {
                "ngInject";
                /////////////////////
                //
                //      Direct variables
                //
                /////////////////////

                var vm = this;

                vm.attachments = [];
                vm.issueId = vm.row.ISSUE_ID;
                vm.itemLabelMapping = TEMPLATE_CATEGORY_MAPPING;
                vm.STATE_OPTION_KEYS = {
                    OPEN: SbNote.STATE_OPEN_ENUM,
                    CLOSED: SbNote.STATE_CLOSED_ENUM,
                    REMOVED: SbNote.STATE_REMOVED_ENUM,
                    WAITING_FOR_CONFIRMATION:
                        SbNote.STATE_WAITING_FOR_CONFIRMATION_ENUM,
                    CONFIRMED: SbNote.STATE_CONFIRMED_ENUM,
                    REJECTED: SbNote.STATE_REJECTED_ENUM,
                };

                vm.isReviewAllowed =
                    vm.row.ISSUE_TYPE === "OBSTRUCTION" ||
                    vm.row.ISSUE_TYPE === "QUALITY_ISSUE";

                vm.onAddComment = onAddComment;
                vm.hasConfirmationTeam =
                    vm.row.CONFIRMATION_TEAM && vm.row.CONFIRMATION_TEAM.id;

                vm.isRowOpen = false;
                vm.isCommentBeingAttached = false;
                vm.isRowDynamicallyClosed = false;

                vm.isStatusTransitionValid = isStatusTransitionValid;

                vm.hasExpandableContent = () => true; //vm.row.ATTACHMENTS.length > 0; This is a temporary fix because at the time of writing we have no good way about knowing the comment number

                vm.toggleRow = toggleRow;
                vm.isAllowedToChangeState = isAllowedToChangeState;
                vm.onIssueStateChange = onIssueStateChange;
                vm._hasRightsToChangeNote = _hasRightsToChangeNote;
                vm._isAllowedToChangeState = _isAllowedToChangeState;
                vm.isNoteFinished = isNoteFinished;

                _activate();

                /////////////////////
                //
                //      SCOPE properties
                //
                /////////////////////

                /////////////////////
                //
                //      WATCHER
                //
                /////////////////////

                /////////////////////
                //
                //      IMPL
                //
                /////////////////////

                const workflowMatrix =
                    NoteStateTransitionPermissionMatrixFactory.createFor({
                        workflow:
                            NoteStateTransitionPermissionMatrixFactory.chooseWorkflowBasedOn(
                                {
                                    membership: $sbMembership.current(),
                                    note: vm.row,
                                }
                            ),
                    });

                function _activate() {
                    vm.project = $sbProject.getCurrent();
                }

                function isStatusTransitionValid(note, targetStatus) {
                    return workflowMatrix.isAllowed([
                        note.ISSUE_STATUS,
                        targetStatus,
                    ]);
                }

                function isNoteFinished(row) {
                    if (!row) {
                        return;
                    }

                    return SbNote.isFinished({
                        state: row.ISSUE_STATUS,
                        type: row.ISSUE_TYPE,
                    });
                }

                function _isAllowedToChangeState(note) {
                    return (
                        note.ISSUE_TYPE !== SbNote.INFORMATION &&
                        note.ISSUE_STATUS !== SbNote.STATE_CONFIRMED_ENUM &&
                        note.ISSUE_STATUS !== SbNote.STATE_CLOSED_ENUM
                    );
                }

                function _hasRightsToChangeNote() {
                    return $sbMembership.currentRole().mask > 1;
                }

                function isAllowedToChangeState(note) {
                    return (
                        _isAllowedToChangeState(note) &&
                        _hasRightsToChangeNote(note.ISSUE_TYPE)
                    );
                }

                function onAddComment($event) {
                    $event.stopPropagation();
                    $sbTracking.note.update(
                        "sb_details_overlay",
                        "add comment and photo"
                    );

                    closeRowAndShowLoading();
                    $sbDeliverablesNoteStateService
                        .createIssueCommentDialog(vm.row, vm.project.id)
                        .finally(() => {
                            openRowAndHideLoadingAfterForceClosing();
                        });
                }

                function onIssueStateChange(oldValue, newValue) {
                    closeRowAndShowLoading();
                    $sbDeliverablesNoteStateService
                        .promptIssueCommentDialog(
                            vm.row,
                            vm.project.id,
                            oldValue,
                            newValue
                        )
                        .finally(() => {
                            openRowAndHideLoadingAfterForceClosing();
                        });
                }

                function toggleRow() {
                    if (vm.hasExpandableContent()) {
                        vm.isRowOpen = !vm.isRowOpen;
                    }
                }

                function closeRowAndShowLoading() {
                    if (vm.isRowOpen) {
                        vm.isCommentBeingAttached = true;
                        vm.isRowDynamicallyClosed = true;
                    }
                }

                function openRowAndHideLoadingAfterForceClosing() {
                    if (vm.isRowDynamicallyClosed) {
                        vm.isCommentBeingAttached = false;
                        vm.isRowDynamicallyClosed = false;
                    }
                }
            },
        };
    });
