import issuesTemplateHtml from "./issues.html";

export default function ($stateProvider) {
    "ngInject";
    var allowedFilter = [
        "search",
        "key",
        "type",
        "status",
        "templateKey",
        "issueAuthor",
        "inspection",
        "createdAt",
        "structure",
        "responsibleTeam",
        "confirmationTeam",
        "deliverableType",
        "deliverableWorkflow",
        "deliverableSearch",
        "deliverableName",
        "deliverableCode",
        "deliverableDesc",
        "activitySearch",
        "activityName",
        "activityDesc",
        "removed",
    ];

    $stateProvider.state("sablono.project.issues", {
        templateUrl: issuesTemplateHtml,
        controller: "IssuesCtrl",
        controllerAs: "issues",
        url: "/notes?" + allowedFilter.join("&") + "&sort&open&selectedTab",
        reloadOnSearch: false,
        gaPageTrack: "/notes", // google analytics extension
        data: {
            title: "SECTION_ISSUES_PAGE_TITLE",
            category: "REVIEW", // PLAN or WORK
            searchEnabled: true,
            searchPlaceholder: "_FILTER_SEARCH_NOTES_PLACEHOLDER",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_NOTES_PAGE);
        },
    });
}
