import angular from "angular";
import htmlTemplate from "./sb_count_info.html";

export default function sbCountInfoCardDirective() {
    return {
        restrict: "E",
        templateUrl: htmlTemplate,
        replace: true,
        controllerAs: "countInfo",
        transclude: true,
        scope: {
            number: "=", // two-way-binding
            text: "<", // will be passed in as string
            icon1: "<",
            icon2: "<",
        },
        controller: function CountInfoController() {
            var vm = this;

            // flags
            vm.isLoading = true;
        },
        link: function postLink(scope, element, attrs, controller) {
            // wait until there is valid data available. As soon as it is set
            //  -> apply it to the card controller
            //
            scope.$watch("number", function handleLoadingFinished(newValue) {
                if (newValue > -1) {
                    controller.isLoading = false;
                    controller.number = newValue;
                }
            });
        },
    };
}
