import angular from "angular";
import SelectScurveActivitiesCtrl from "./select_scurve_activities.controller";
import dialogTemplate from "./select_scurve_activities.html";

export default function SelectScurveActivitiesConfig($mdDialogProvider) {
    "ngInject";

    $mdDialogProvider.addPreset("selectScurveActivities", {
        methods: ["templateOptions", "projectTeams", "initialSelection"],
        options: function () {
            return {
                controller: SelectScurveActivitiesCtrl,
                controllerAs: "dialog",
                templateUrl: dialogTemplate,
                bindToController: true,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                hasBackdrop: true,
                preserveScope: true,
            };
        },
    });
}
