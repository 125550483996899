import moment from "moment";

export default function TimeRangeSelectorDialogCtrl(
    $log,
    $mdDialog,
    $sbTracking
) {
    "ngInject";
    var vm = this;

    vm.closeDialog = closeDialog;
    vm.acceptCurrentChanges = acceptCurrentChanges;
    vm.onCalendarDatesChanged = onCalendarDatesChanged;
    vm.predefinedRange = predefinedRange;

    function closeDialog() {
        $mdDialog.cancel();
    }

    function acceptCurrentChanges() {
        $sbTracking.leanBoard.change.timeRange(
            moment(vm.endDate).diff(moment(vm.startDate), "days")
        );
        $mdDialog.hide({
            startDate: vm.startDate,
            endDate: vm.endDate,
        });
    }

    /**
     * Executed on every date or time change in the dialog.
     *
     * Validates if the given combination is valid and sets the validity
     * of endbeforestart to given boolean.
     */
    function onCalendarDatesChanged() {
        vm.form.$setValidity(
            "endbeforestart",
            !endDateBeforeStartDate(moment(vm.endDate), moment(vm.startDate))
        );

        vm.form.$setValidity(
            "maximumrange",
            !maximumRange(moment(vm.startDate), moment(vm.endDate))
        );
    }

    /**
     * Checks if given end date is before the start date
     * @param {moment} endDate - end date of  range
     * @param {moment} startDate - start date of range
     * @returns {boolean} - (True if it's before start date, false otherwise)
     */
    function endDateBeforeStartDate(endDate, startDate) {
        return endDate.isBefore(startDate);
    }

    function predefinedRange(amount, units) {
        $sbTracking.leanBoard.change.predefinedRange(amount, units);
        vm.form.$setDirty();
        vm.endDate = moment(vm.startDate).add(amount, units).toDate();
        onCalendarDatesChanged();
    }

    function maximumRange(startDate, endDate) {
        return endDate.diff(startDate, "days") > 365;
    }
}
