import { isEmpty } from "lodash";

export default function TrackerCtrl(
    visualizationService,
    $sbExternalService,
    $sbLegacyExcelImport,
    $location,
    $rootScope,
    EVENTS
) {
    "ngInject";

    /////////////////////
    //
    //      Direct variables
    //
    /////////////////////

    const vm = this;

    const stateLegend = [
        {
            color: visualizationService.STAGE_COLORS.notApplicableStroke,
            stroke: true,
            key: "VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_APPLICABLE",
            state: "not_applicable",
        },
        {
            color: visualizationService.STAGE_COLORS.notStarted,
            key: "VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_STARTED",
            state: "not_started",
            completionPercentage: "(0%)",
        },
        {
            color: visualizationService.STAGE_COLORS.available,
            hatch: true,
            key: "VISUALIZATION_LEGEND_ENTRY_STAGE_AVAILABLE",
            state: "is_available",
            completionPercentage: "(0%)",
        },
        {
            color: visualizationService.STAGE_COLORS.started,
            key: "VISUALIZATION_LEGEND_ENTRY_STAGE_STARTED",
            state: "started",
            completionPercentage: "(50%)",
        },
        {
            color: visualizationService.STAGE_COLORS.waitingForConfirmation,
            hatch: true,
            key: "_WAITING_FOR_CONFIRMATION",
            state: "waiting_for_confirmation",
            completionPercentage: "(75%)",
        },
        {
            color: visualizationService.STAGE_COLORS.rejected,
            crossHatch: true,
            key: "_REJECTED",
            state: "rejected",
            completionPercentage: "(50%)",
        },
        {
            color: visualizationService.STAGE_COLORS.completed,
            key: "VISUALIZATION_LEGEND_ENTRY_STAGE_COMPLETED",
            state: ["confirmed", "done"],
            completionPercentage: "(100%)",
        },
    ];

    const colors = stateLegend.reduce((acc, entry) => {
        if (Array.isArray(entry.state)) {
            entry.state.forEach((state) => {
                acc.set(state, entry);
            });
        } else if (entry.state) {
            acc.set(entry.state, entry);
        }

        return acc;
    }, new Map());

    /////////////////////
    //
    //      SCOPE properties
    //
    /////////////////////

    vm.ISSUE_CONTENT = "ISSUE_CONTENT";
    vm.DATES_CONTENT = "DATES_CONTENT";
    vm.COMMERCIAL_VALUATION_CONTENT = "COMMERCIAL_VALUATION_CONTENT";

    vm.legendEntries = [];
    vm.isLegendVisible = false;

    /////////////////////
    //
    //      WATCHERS
    //
    /////////////////////
    const activityUpdateWatcher = $rootScope.$on(
        EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
        (event, activityId, newState, deliverableId) =>
            $sbExternalService
                .onTrackerDataChange()
                .emitActivityStateChange(activityId, deliverableId)
    );

    const noteAddedWatcher = $rootScope.$on(
        EVENTS.COMPONENT_DETAIL__NOTE_ADDED,
        (event, deliverableId) =>
            $sbExternalService
                .onTrackerDataChange()
                .emitNoteAddition(deliverableId)
    );

    const noteUpdateWatcher = $rootScope.$on(
        EVENTS.NOTE_CHANGED,
        (event, changeEvent, deliverableId) =>
            $sbExternalService
                .onTrackerDataChange()
                .emitNoteChange(deliverableId)
    );

    // cleanup
    vm.$onDestroy = () => {
        activityUpdateWatcher();
        noteUpdateWatcher();
        noteAddedWatcher();
    };

    /////////////////////
    //
    //      IMPL
    //
    /////////////////////

    activate();

    async function activate() {
        const el = document.getElementById("trackerGrid");
        el.colors = colors;
        el.excelImportLegacyCodeRequiredPieces = $sbLegacyExcelImport;
        el.legendBuilder = {
            showLegendForContentType,
            hideLegend,
            showLegend,
        };
        el.queryString = _convertParamsToQueryString($location.search());
    }

    function _convertParamsToQueryString(params) {
        const searchParams = new URLSearchParams();

        Object.keys(params).forEach((key) => {
            const value = params[key];

            if (Array.isArray(value)) {
                value.forEach((item) => searchParams.append(key, item));
            } else {
                searchParams.append(key, value);
            }
        });

        return searchParams.toString();
    }

    function hideLegend() {
        vm.isLegendVisible = false;
        $rootScope.$apply();
    }

    function showLegend() {
        vm.isLegendVisible = true;
        $rootScope.$apply();
    }

    function showLegendForContentType(toContent) {
        switch (toContent) {
            case vm.ISSUE_CONTENT:
                vm.legendEntries = [
                    ...stateLegend,
                    {
                        divider: true,
                    },
                    {
                        trackerLines: true,
                    },
                    {
                        divider: true,
                    },
                    {
                        issueRow: true,
                    },
                ];
                break;

            case vm.DATES_CONTENT:
                vm.legendEntries = [
                    ...stateLegend,
                    {
                        divider: true,
                    },
                    {
                        trackerLines: true,
                    },
                    {
                        issueRow: false,
                    },
                ];
                break;
            case vm.COMMERCIAL_VALUATION_CONTENT:
                // TODO might need adjustment
                vm.legendEntries = [
                    ...stateLegend,
                    {
                        divider: true,
                    },
                    {
                        trackerLines: true,
                    },
                    {
                        issueRow: false,
                    },
                ];
                break;
            default:
                break;
        }
        showLegend();
    }
}
