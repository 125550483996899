import angular from "angular";

const module = angular.module("sbApp.AngularBridge", [
    "sbApp.services",
    "sbApp.sbIssueEditorDialog",
]);

module.run(
    function (
        $sbIssueEditorService,
        $sbDeliverablesNoteStateService,
        $sbExternalService,
        $sbDetailsOverlay,
        $rootScope,
        $sbTeam,
        $sbProject,
        $state,
        $sbErrorPresenter
    ) {
        "ngInject";

        $rootScope.$on("$stateChangeSuccess", (event, state, params) => {
            const routeService = $sbExternalService?.router();
            if (routeService) {
                routeService.setState(state.name, params);
            }
        });
        // $stateChangeSuccess was not triggering every time to update the router service state.
        // So, we are using $stateChangeSuccess and $rootScope.$on("sb-params-changed") to achieve the same effect.
        // $rootScope.$on("sb-params-changed") is triggered every time we change the params.
        $rootScope.$on("sb-params-changed", () => {
            const routeService = $sbExternalService?.router();
            if (routeService) {
                routeService.setState($state.current.name, $state.params);
            }
        });

        $sbExternalService
            .activityTrackerState()
            ?.selectDeliverable$.subscribe((deliverable) => {
                void $sbDetailsOverlay.toggleView(
                    "deliverable",
                    deliverable.id
                );
                $rootScope.$apply();
            });

        $sbExternalService
            .activityTrackerState()
            ?.activitySideNavOpening$.subscribe(() => {
                void $sbDetailsOverlay.close();
                $rootScope.$apply();
            });

        $sbExternalService
            ?.router()
            ?.goTo$.subscribe(({ state, params, options }) => {
                $state.go(state || $state.$current.name, params, options);
                $rootScope.$apply();
                $rootScope.$broadcast("sb-params-changed");
            });

        $sbExternalService
            .showDetailsEventEmitter()
            ?.navigateToDetails$.subscribe(({ viewType, id }) => {
                void $sbDetailsOverlay.toggleView(viewType, id);
                $rootScope.$apply();
            });

        $sbExternalService
            .noteCreationService()
            ?.createNote$.subscribe((context) => {
                $sbTeam
                    .getTeams($sbProject.getCurrentProjectId())
                    .then((teams) => {
                        return $sbIssueEditorService.showForActivity(
                            undefined,
                            teams,
                            function (extensions) {
                                const extensionsCopy = [...extensions];
                                return extensionsCopy.filter(function (x) {
                                    return x.toLowerCase() !== ".pdf";
                                });
                            }
                        );
                    })
                    .then(function ({ note }) {
                        note.projectId = $sbProject.getCurrentProjectId();
                        $sbExternalService
                            .noteCreationService()
                            ?.noteCreated$.next({
                                context,
                                note,
                            });
                    })
                    .catch(function (error) {
                        // selecting cancel from the dialog throws an undefined
                        if (error) {
                            throw error;
                        }
                    });
            });

        $sbExternalService
            .noteCommentCreationService()
            ?.createNoteComment$.subscribe((context) => {
                /**
                 * createIssueCommentDialog expects a AngularJS note, but
                 * only three properties are needed for adding a comment.
                 */

                const angularJSNote = {
                    ISSUE_ID: context.note.id,
                    ISSUE_TYPE: context.note.type.toUpperCase(),
                    ISSUE_TEXT: context.note.text,
                };

                $sbDeliverablesNoteStateService
                    .createIssueCommentDialog(angularJSNote, context.projectId)
                    .then((createdNoteComment) => {
                        $sbExternalService
                            .noteCommentCreationService()
                            ?.noteCommentCreation$.next({
                                context,
                                result: createdNoteComment,
                            });
                    })
                    .catch((err) => {
                        if (!err) {
                            // dialog cancelled
                            $sbExternalService
                                .noteCommentCreationService()
                                ?.noteCommentCreation$.next({
                                    context,
                                    result: "cancelled",
                                });
                        } else {
                            $sbErrorPresenter.catch(err);
                        }
                    });
            });
    }
);

export default module;
