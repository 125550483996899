import angular from "angular";
import _ from "lodash";
import Chart from "chart";
import {
    activity_scheduling_behind,
    actual_completed,
    actual_confirmed,
    actual_work_finished,
    planed_as_completed,
} from "../../../../colorpalette";

export default function TemplateBarChartCtrl(
    $rootScope,
    $element,
    $sbColor,
    $sbBarChart,
    $filter
) {
    "ngInject";

    var vm = this;
    vm.isEmpty = false;

    var i18n = {};
    var dataSetIndexes = {};
    var canvas = $element.children()[0];
    var context = canvas.getContext("2d");
    var windowWasResized = false;

    var OPACITY = 80;
    var BORDER_COLORS = {
        PLANNED: planed_as_completed,
        FINISHED: actual_work_finished,
        CONFIRMED: actual_confirmed,
        COMPLETED: actual_completed,
        BEHIND: activity_scheduling_behind,
    };
    var COLORS = {
        PLANNED: $sbColor.hexToRgba(BORDER_COLORS.PLANNED, OPACITY),
        FINISHED: $sbColor.hexToRgba(BORDER_COLORS.FINISHED, OPACITY),
        CONFIRMED: $sbColor.hexToRgba(BORDER_COLORS.CONFIRMED, OPACITY),
        COMPLETED: $sbColor.hexToRgba(BORDER_COLORS.COMPLETED, OPACITY),
        BEHIND: $sbColor.hexToRgba(BORDER_COLORS.BEHIND, OPACITY),
    };
    var DATA_SETS = {
        planned: "planned",
        finished: "finished",
        confirmed: "confirmed",
        completed: "completed",
        behind: "behind",
    };

    // state machine
    //
    vm.machine = {
        state: {
            init: angular.noop,
            translateChart: angular.noop,
            updateChartDataFrom: angular.noop,
        },
        setStateBasedOn: function (stats) {
            if (hasValidRecords(stats.confirmed)) {
                this.changeState("CONFIRM", {
                    // Always display the completed bar, fix for SAB-5134
                    // withCompleted: !_.isEqual(stats.confirmed, stats.completed),
                    withCompleted: true,
                });
            } else {
                this.changeState("DEFAULT");
            }
        },
        changeState: function (state, options) {
            this.state = this.states[state];
            this.state.init(options);
        },
        states: {
            DEFAULT: {
                init: function () {
                    const dataSetNames = [
                        DATA_SETS.finished,
                        DATA_SETS.planned,
                        DATA_SETS.behind,
                    ];

                    _setDataSetIndexesFor(dataSetNames);
                },
                translateChart: function () {
                    _translateChart(vm.chart, i18n);
                },
                updateChartDataFrom: function (stats) {
                    vm.chart.data.datasets = createDataSetsFromIndex(
                        stats,
                        dataSetIndexes
                    );
                },
            },
            CONFIRM: {
                init: function ({ withCompleted }) {
                    const dataSetNames = [DATA_SETS.confirmed];
                    if (withCompleted) {
                        dataSetNames.push(DATA_SETS.completed);
                    }
                    dataSetNames.push(DATA_SETS.finished);
                    dataSetNames.push(DATA_SETS.planned);
                    dataSetNames.push(DATA_SETS.behind);

                    _setDataSetIndexesFor(dataSetNames);
                },
                translateChart: function () {
                    _translateChart(vm.chart, i18n);
                },
                updateChartDataFrom: function (stats) {
                    vm.chart.data.datasets = createDataSetsFromIndex(
                        stats,
                        dataSetIndexes
                    );
                },
            },
        },
    };

    // lifecycle hooks
    //
    vm.$onInit = $onInit;
    vm.$onChanges = $onChanges;

    // translation change watcher
    //
    $rootScope.$on("$translateChangeSuccess", function () {
        _updateTranslations(i18n);
        vm.machine.state.translateChart();
        vm.chart.update();
    });

    function $onInit() {
        _updateTranslations(i18n);

        vm.chart = _createDefaultChart(vm.options || {});

        if (Array.isArray(vm.model) && vm.model.length > 0) {
            vm.isEmpty = false;
            _updateChartBasedOn(vm.model);
        } else {
            vm.isEmpty = true;
        }
    }

    function _createDefaultChart(optionsOverwrite) {
        return new Chart(context, {
            type: "horizontalBar",
            data: {
                datasets: [],
            },
            options: _getChartOptions(optionsOverwrite),
        });
    }

    function $onChanges(changes) {
        if (vm.chart) {
            if (_dataHasChanged(changes.model)) {
                vm.isEmpty = false;
                _updateTranslations(i18n);
                _updateChartBasedOn(changes.model.currentValue);
                vm.machine.state.translateChart();
            } else {
                vm.isEmpty = true;
            }
        }
    }

    function _dataHasChanged(model) {
        return (
            !_.isEmpty(model) &&
            !_.isEmpty(model.currentValue) &&
            _.isArray(model.currentValue)
        );
    }

    function _updateChartBasedOn(model) {
        var stats = _transformModelToData(model);
        vm.machine.setStateBasedOn(stats);
        _updateChartLayoutFrom(stats);
        vm.machine.state.updateChartDataFrom(stats);
        vm.chart.update();
    }

    function _updateChartLayoutFrom(stats) {
        const chartHeight =
            $sbBarChart.getChartHeightByLabels(stats.labels) + 90;

        context.canvas.parentNode.style.height = chartHeight + "px";

        vm.chart.data.labels = stats.labels;
        vm.chart.data.ids = stats.ids;
    }

    function createDataSetsFromIndex(stats, dataSetIndexes) {
        return Object.keys(dataSetIndexes)
            .map((dataSetType) => {
                return {
                    type: dataSetType,
                    index: dataSetIndexes[dataSetType],
                };
            })
            .sort((a, b) => a.index - b.index)
            .map((dataSet) => {
                switch (dataSet.type) {
                    case DATA_SETS.completed: {
                        return _createDataSetFrom(
                            dataSetIndexes.completed,
                            i18n.completed,
                            {
                                background: COLORS.COMPLETED,
                                border: BORDER_COLORS.COMPLETED,
                            },
                            stats.completed.slice()
                        );
                    }
                    case DATA_SETS.confirmed: {
                        return _createDataSetFrom(
                            dataSetIndexes.confirmed,
                            i18n.confirmed,
                            {
                                background: COLORS.CONFIRMED,
                                border: BORDER_COLORS.CONFIRMED,
                            },
                            stats.confirmed.slice()
                        );
                    }
                    case DATA_SETS.behind: {
                        return _createBehindDataSet(stats);
                    }
                    case DATA_SETS.finished: {
                        return _createDataSetFrom(
                            dataSetIndexes.finished,
                            i18n.finished,
                            {
                                background: COLORS.FINISHED,
                                border: BORDER_COLORS.FINISHED,
                            },
                            stats.finished.slice()
                        );
                    }
                    case DATA_SETS.planned: {
                        return _createDataSetFrom(
                            dataSetIndexes.planned,
                            i18n.planned,
                            {
                                background: COLORS.PLANNED,
                                border: BORDER_COLORS.PLANNED,
                            },
                            stats.planned.slice()
                        );
                    }
                }
            });
    }

    function _createBehindDataSet(stats) {
        return _createDataSetFrom(
            dataSetIndexes.behind,
            i18n.behind,
            {
                background: COLORS.BEHIND,
                border: BORDER_COLORS.BEHIND,
            },
            stats.behind.slice()
        );
    }

    function _createDataSetFrom(index, label, colors, data) {
        const dataSet = {
            index: index,
            label: label,
            data: data || [],
            backgroundColor: colors.background,
            borderColor: colors.border,
            borderWidth: 1,
            barPercentage: 1,
            categoryPercentage: data && data.length === 1 ? 1 : 0.85,
        };
        if (vm.isClickDisabled) {
            dataSet.hoverBackgroundColor = colors.background;
            dataSet.hoverBorderColor = colors.border;
        } else {
            dataSet.hoverBackgroundColor = colors.border;
            dataSet.hoverBorderColor = colors.background;
        }

        return dataSet;
    }

    // Called when we click on a bar
    function _handleBarClick(bar) {
        vm.onBarChartClick({
            chart: {
                params: {
                    stage: _createStageParamForClickedBar(bar),
                },
                label: bar._model.datasetLabel,
            },
        });
    }

    function _createStageParamForClickedBar(bar) {
        var stageParam = bar._chart.data.ids[bar._index];
        var additional = "";

        switch (bar._datasetIndex) {
            case dataSetIndexes.planned:
                additional = ",planned";
                break;
            case dataSetIndexes.finished:
                additional = ",work_finished";
                break;
            case dataSetIndexes.confirmed:
                additional = ",confirmed";
                break;
            case dataSetIndexes.completed:
                additional = ",completed";
                break;
            case dataSetIndexes.behind:
                additional = ",behind";
                break;
        }

        return stageParam + additional;
    }

    function _updateTranslations(i18n) {
        return $sbBarChart.updateTranslations(
            i18n,
            _.merge(
                {
                    planned: "DASHBOARD_CHARTS_LEGEND_PLANNED",
                    finished: "DASHBOARD_CHARTS_LEGEND_FINISHED",
                    // confirmed: "DASHBOARD_CHARTS_LEGEND_CONFIRMED",
                    completed: "DASHBOARD_CHARTS_LEGEND_COMPLETED",
                    behind: "_BEHIND_SCHEDULE",
                    xScaleLabel: "_ACTIVITY_NUMBER",
                    yScaleLabel: "_STATE",
                },
                _.get(vm, "options.i18n", {})
            )
        );
    }

    function _translateChart(chart, i18n) {
        _.forOwn(dataSetIndexes, (val, key) => {
            chart.data.datasets[val].label = i18n[key];
        });
        chart.options.scales.xAxes[0].scaleLabel.labelString = i18n.xScaleLabel;
        chart.options.scales.yAxes[0].scaleLabel.labelString = i18n.yScaleLabel;
        vm.chart.update();
    }

    function _setDataSetIndexesFor(dataSetNames) {
        dataSetIndexes = {};

        dataSetNames
            .filter((name) => name !== DATA_SETS.confirmed) // to remove confirmed line (https://sablono.atlassian.net/browse/SAB-4568)
            .forEach(function (name, index) {
                dataSetIndexes[name] = index;
            });
    }

    /**
     * Transform input model to chart data points
     *
     * @param {Array<{planned: number, finished: number, confirmed: number, behind: number, id: string, name: string}>} model
     *
     * @returns {{
     *      completed: number[],
     *      planned: number[],
     *      finished: number[],
     *      confirmed: number[],
     *      behind: number[],
     *      labels: Array<string|Array<string>>,
     *      ids: string[]
     * }}
     */
    function _transformModelToData(model) {
        return $sbBarChart.transformModelToData(model, {
            planned: "planned",
            finished: "finished",
            confirmed: "confirmed",
            completed: "completed",
            behind: "behind",
            ids: "id",
            labels: function (entry) {
                return $sbBarChart.breakIntoMultiline(entry.name);
            },
        });
    }

    function hasValidRecords(entries) {
        return Array.isArray(entries) && !_.every(entries, _.isNil);
    }

    function _getChartOptions(optionsOverwrite) {
        return {
            fontSize: 14,
            fontFamily: "Work Sans, Helvetica Neue, sans-serif",
            title: {
                display: false,
            },
            responsive: true,
            onClick: function clickHandler(event) {
                var clickedElement = this.getElementAtEvent(event);
                if (clickedElement.length > 0) {
                    _handleBarClick(clickedElement[0]);
                }
            },
            maintainAspectRatio: false,
            padding: 5,
            scales: {
                type: "linear",
                xAxes: [
                    {
                        scaleLabel: {
                            display: _.get(
                                optionsOverwrite,
                                "scales.xAxis.displayLabels",
                                true
                            ),
                            labelString: i18n.xScaleLabel,
                        },
                        ticks: {
                            autoSkip: true,
                            type: "linear",
                            beginAtZero: true,
                            userCallback: function (label) {
                                // when the floored value is the same as the value we have a whole number
                                if (Math.floor(label) === label) {
                                    return $filter("number")(Math.abs(label));
                                }
                            },
                        },
                    },
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: _.get(
                                optionsOverwrite,
                                "scales.yAxis.displayLabels",
                                true
                            ),
                            labelString: i18n.yScaleLabel,
                        },
                        ticks: {
                            autoSkip: false,
                        },
                    },
                ],
            },
            tooltips: {
                enabled: false,
            },
            legend: {
                display: _.get(optionsOverwrite, "legend.display", true),
                fullWidth: true,
                position: _.get(optionsOverwrite, "legend.position", "top"),
                onClick: angular.noop,
                labels: {
                    boxWidth: 14,
                },
            },
            hover: {
                // set to 0 to avoid flickering of labels
                animationDuration: 0,
                // important, to show hover only on bars
                mode: "point",
                onHover: function (mouseEvent, hoveredElements) {
                    angular.element(canvas).css("cursor", "default");

                    const isHoverEnabled = !vm.isClickDisabled;
                    const hasElementsToHover =
                        hoveredElements && hoveredElements.length > 0;

                    if (isHoverEnabled && hasElementsToHover) {
                        // Show pointer on hover of bars
                        angular.element(canvas).css("cursor", "pointer");
                    }
                },
            },
            animation: {
                duration: 0,
                onComplete: function onAnimationComplete() {
                    $sbBarChart.drawCountsOnBar(this.chart, i18n);

                    if (windowWasResized && vm.chart) {
                        windowWasResized = false;
                        vm.chart.update();
                    }
                },
            },
            onResize: function () {
                windowWasResized = true;
            },
        };
    }
}
