import _ from "lodash";
import Activity from "../../../../domain/sb_activity.class";

export default function SelectScurveActivitiesCtrl(
    $scope,
    $sbActivities,
    $sbTemplate,
    $sbErrorPresenter,
    $mdDialog,
    SbTeam
) {
    "ngInject";

    var vm = this;

    // activities to select from
    vm.availableActivities = [];
    vm.selectedActivities = [];

    // UI callbacks
    vm.submit = submit;
    vm.closeDialog = $mdDialog.cancel;
    vm.onActivitySelectionChange = onActivitySelectionChange;
    vm.getCurrentSelection = getCurrentSelection;
    vm.isAllSelected = isAllSelected;
    vm.isNothingSelected = isNothingSelected;

    activate();

    function activate() {
        vm.selectedActivities = _mapToActivities(vm.initialSelection);
        vm.availableActivities = _mapToActivities(vm.templateOptions);
    }

    function submit() {
        if (vm.isAllSelected() || vm.isNothingSelected()) {
            return $mdDialog.hide([]);
        }

        return $mdDialog.hide(vm.getCurrentSelection());
    }

    function isAllSelected() {
        return vm.selectedActivities.length === vm.templateOptions.length;
    }

    function isNothingSelected() {
        return vm.selectedActivities.length === 0;
    }

    function onActivitySelectionChange(selectedActivities) {
        vm.selectedActivities = selectedActivities;
    }

    function getCurrentSelection() {
        const selectedIds = vm.selectedActivities.map(
            (selectedActivity) => selectedActivity.id
        );
        return vm.templateOptions.filter(
            (templateOption) => selectedIds.indexOf(templateOption.id) !== -1
        );
    }

    //////
    //  internals
    ///

    function _mapToActivities(templateOptions) {
        return templateOptions.map((option) => {
            const activityNameLevels = option.name.split("|");
            const processTemplateName = activityNameLevels.shift();
            const groupedName = activityNameLevels.join(" > ");

            const activity = new Activity(option.id, groupedName, "");
            activity.processTemplateName = processTemplateName;
            activity.setResponsibleTeam(
                _.defaultTo(
                    vm.projectTeams.find((team) => team.id === option.teamId),
                    SbTeam.createUnrestrictedTeam()
                )
            );

            return activity;
        });
    }
}
