import _ from "lodash";

export default function ProjectOverviewCtrl(
    $sbPermission,
    $sbInspectionsApi,
    $sbCurrentProject,
    $sbSiteInspections,
    $sbErrorPresenter,
    $stateParams,
    $sbProjectOverview,
    $state,
    $sbTracking,
    $q,
    Analytics,
    $sbStructureNodesApi,
    $sbLocale,
    downloadCenterService,
    SABLONO_STATES,
    $sbExternalService,
    $scope
) {
    "ngInject";

    const vm = this;
    const MAX_INSPECTIONS = 12;

    vm.typeStats = [];
    /**
     * Options to customize appearance of project-level bar chart.
     */
    vm.projectBarChartOptions = {
        legend: { position: "bottom" },
        scales: {
            yAxis: { displayLabels: false },
        },
        i18n: {
            xScaleLabel: "_ACTIVITY_NUMBER",
        },
    };

    /**
     * Options to customize appearance of tiles bar charts.
     */
    vm.tilesBarChartOptions = {
        legend: { display: false },
        scales: {
            yAxis: { displayLabels: false },
        },
        i18n: {
            xScaleLabel: "_ACTIVITY_NUMBER",
        },
    };

    vm.isMaxInspectionsLimitExceeded = isMaxInspectionsLimitExceeded;
    vm.navigateToDashboard = navigateToDashboard;
    vm.navigateToDashboardWithTemplateId = navigateToDashboardWithTemplateId;
    vm.navigateToDashboardWithDeliverableType =
        navigateToDashboardWithDeliverableType;
    vm.hasData = hasData;
    vm.printReports = printReports;
    vm.hasAdminPermissions = false;

    initialize();

    function initialize() {
        vm.project = $sbCurrentProject.get();
        vm.hasAdminPermissions = $sbPermission.hasAdminPermissions(
            $sbCurrentProject.pick("privileges")
        );

        _fetchInspectionDataForTimeline();
        _loadBarChartContent().then(() => {
            if (hasData() && vm.hasAdminPermissions) {
                const projectSetupEl = document.getElementById("projectSetup");
                if (projectSetupEl) {
                    projectSetupEl.projectId = vm.project.id;
                    projectSetupEl.displayHideButton = true;
                }
            } else if (vm.hasAdminPermissions) {
                const projectSetupOnEmptyStateEl = document.getElementById(
                    "projectSetupOnEmptyState"
                );
                projectSetupOnEmptyStateEl.projectId = vm.project.id;
            }
        });
        $sbExternalService
            .projectOverviewSettingsStore()
            ?.hideFirstStepsCardForProject$.subscribe(function (projectId) {
                if (projectId === vm.project.id) {
                    vm.showFirstStepsCard = false;
                    $scope.$apply();
                }
            });
        vm.showFirstStepsCard = !isFirstStepsCardHidden();
    }

    function isMaxInspectionsLimitExceeded(num) {
        return num > MAX_INSPECTIONS;
    }

    function _fetchInspectionDataForTimeline() {
        vm.isLoadingTimeline = true;
        vm.inspections = null;
        vm.totalAmountOfInspections = null;
        return $sbInspectionsApi
            .getCollection(vm.project.id, { top: MAX_INSPECTIONS })
            .then((response) => {
                return {
                    count: response.meta.count_all,
                    items: response.inspections.map(
                        $sbSiteInspections.parseServerInspection
                    ),
                };
            })
            .then(function (results) {
                vm.totalAmountOfInspections = results.count;
                return results.items;
            })
            .then((data) => (vm.inspections = data))
            .catch($sbErrorPresenter.catch)
            .finally(function () {
                vm.isLoadingTimeline = false;
            });
    }

    /**
     * Load data for template and project-level bar charts.
     *
     * @returns {Promise<boolean>}
     */
    function _loadBarChartContent() {
        vm.isLoadingProjectStats = true;

        return $q
            .all([
                $sbProjectOverview.fetchScheduleStatsGroupedByProcessTemplate(
                    $stateParams.projectId
                ),
                $sbProjectOverview.fetchScheduleStatsGroupedByDeliverableType(
                    $stateParams.projectId
                ),
            ])
            .then(([templateStats, typeStats]) => {
                setTemplateMetrics(templateStats);
                setTypesMetrics(typeStats);
                return templateStats;
            })
            .then((stats) =>
                $sbProjectOverview.aggregateScheduleStatsForProject(stats)
            )
            .then(setProjectLevelMetrics)
            .catch($sbErrorPresenter.catch)
            .finally(() => (vm.isLoadingProjectStats = false));
    }

    function setTemplateMetrics(templateStats) {
        vm.templateStats = templateStats.map((stats) => {
            const { name, ...rest } = stats;
            return {
                id: rest.id,
                name,
                data: [rest],
            };
        });
    }

    function setTypesMetrics(typeStats) {
        vm.typeStats = typeStats.map((stats) => {
            const { name, ...rest } = stats;
            return {
                id: rest.id,
                name,
                data: [rest],
            };
        });
    }

    function setProjectLevelMetrics(projectStats) {
        projectStats.id = $stateParams.projectId;
        projectStats.name = "";
        vm.projectStats = [projectStats];
    }

    function navigateToDashboard() {
        $sbTracking.projectOverview.barChart.click.onFullProject();
        Analytics.trackConversion("all tile clicked");
        return $state.go(SABLONO_STATES.dashboard, {
            clear: true,
        });
    }

    function navigateToDashboardWithTemplateId(templateId) {
        Analytics.trackConversion("template tile clicked");
        $sbTracking.projectOverview.barChart.click.onTemplateTile();
        return $state.go(SABLONO_STATES.dashboard, {
            templateId,
        });
    }

    function navigateToDashboardWithDeliverableType(deliverableType) {
        Analytics.trackConversion("type tile clicked");
        $sbTracking.projectOverview.barChart.click.onTypeTile();
        return $state.go(SABLONO_STATES.dashboard, {
            deliverableType,
        });
    }

    function hasData() {
        return _.get(vm, "projectStats[0].total", 0) > 0;
    }

    function printReports() {
        if (vm.isLoadingDashboardReport) {
            return;
        }

        Analytics.trackEvent("PDF Reports", "click", "Structure");

        vm.isLoadingDashboardReport = true;
        printDashboardReport(vm.project.id)
            .finally(() => {
                vm.isLoadingDashboardReport = false;
            })
            .catch($sbErrorPresenter.catch);
    }

    function printDashboardReport(sProjectId) {
        return $sbStructureNodesApi
            .printCollection(sProjectId, {
                language: $sbLocale.current(),
                as: "report",
            })
            .then((xhr) => {
                downloadCenterService.downloadFrom(xhr);
                return xhr;
            });
    }

    function isFirstStepsCardHidden() {
        return $sbExternalService
            .projectOverviewSettingsStore()
            ?.isFirstStepsCardHiddenForProject(vm.project.id);
    }
}
