import requestAccessHtml from "./request_access.html";

export default function ($stateProvider, SABLONO_STATES) {
    "ngInject";

    const routeDetails = {
        templateUrl: requestAccessHtml,
        controller: "RequestAccessCtrl",
        controllerAs: "requestAccess",
        url: "/request-access",
        data: {
            title: "ACTION_NAV_TO_REQUEST_ACCESS",
        },
    };

    $stateProvider.state(
        SABLONO_STATES.requestAccessProjectOverview,
        Object.assign({
            ...routeDetails,
            url: "/request-access-project-overview",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessPerformanceDashboard,
        Object.assign({
            ...routeDetails,
            url: "/request-access-performance-dashboard",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessDeliverables,
        Object.assign({
            ...routeDetails,
            url: "/request-access-deliverables",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessNotes,
        Object.assign({
            ...routeDetails,
            url: "/request-access-notes",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessLeanPlanning,
        Object.assign({
            ...routeDetails,
            url: "/request-access-lean-planning",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessCommercialDashboard,
        Object.assign({
            ...routeDetails,
            url: "/request-access-commercial-dashboard",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessVisualTrackers,
        Object.assign({
            ...routeDetails,
            url: "/request-access-visual-trackers",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessQAChecklists,
        Object.assign({
            ...routeDetails,
            url: "/request-access-qa-checklists",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessCommercialProDashboard,
        Object.assign({
            ...routeDetails,
            url: "/request-access-commercial-valuation",
        })
    );
    $stateProvider.state(
        SABLONO_STATES.requestAccessPerformanceDashboardPro,
        Object.assign({
            ...routeDetails,
            url: "/request-access-performance-dashboard-pro",
        })
    );
}
