import angular from "angular";
import UnknownError from "common/errors/UnknownError";
import PresentableError from "common/errors/PresentableError";
import AuthenticationError from "common/errors/AuthenticationError";
import RequestError from "common/errors/RequestError";
import "angular-material";
import "common/services/services";

export default function errorPresenterPresenterProvider() {
    //real service
    this.$get = function $get(
        $mdDialog,
        $mdToast,
        $state,
        $log,
        $stateParams,
        $sbAuth,
        $exceptionHandler
    ) {
        "ngInject";
        function ErrorPresenter(config) {
            this.config = config;
            this.catch = this.showWithPreferredPresentationStyle.bind(this);
        }

        ErrorPresenter.prototype.transformToPresentableError = function (
            error
        ) {
            if (error instanceof PresentableError) {
                return error;
            } else {
                return new UnknownError(error);
            }
        };

        ErrorPresenter.prototype.showWithPreferredPresentationStyle = function (
            error,
            presentationStyle
        ) {
            var presentableError = this.transformToPresentableError(error);

            // auto redirect to login page
            //
            if (presentableError instanceof AuthenticationError) {
                $sbAuth.goToLoginState($state.current.name, $state.params);
            }

            if (error instanceof Error) {
                $exceptionHandler(error);
            }

            var preferredPresentationStyle =
                presentationStyle ||
                presentableError.preferredPresentationStyle();

            switch (preferredPresentationStyle) {
                case PresentableError.presentationStyle.TOAST:
                    return this.showToast(error);
                case PresentableError.presentationStyle.NONE:
                    return;
                case PresentableError.presentationStyle.DIALOG:
                default:
                    return this.showDialog(error);
            }
        };

        ErrorPresenter.prototype.showToast = function (error) {
            var presentableError = this.transformToPresentableError(error);
            return $mdToast.show(
                $mdToast
                    .error()
                    .content(presentableError.message)
                    .actions(presentableError.getActions())
                    .autoClose(!!presentableError.shouldAutoClose())
                    .hideDelay(
                        presentableError.shouldAutoClose() ? 3000 : false
                    )
                    .icon(presentableError.getIcon())
            );
        };

        ErrorPresenter.prototype.showDialog = function (error, targetEvent) {
            var presentableError = this.transformToPresentableError(error);
            return $mdDialog.show(
                $mdDialog
                    .error()
                    .title(presentableError.title)
                    .ariaLabel(presentableError.title)
                    .content(presentableError.message)
                    .actions(presentableError.getActions())
                    .clickOutsideToClose(!!presentableError.shouldAutoClose())
                    .escapeToClose(!!presentableError.shouldAutoClose())
                    .autoClose(!!presentableError.shouldAutoClose())
                    .icon(presentableError.getIcon())
                    .targetEvent(targetEvent)
            );
        };

        ErrorPresenter.prototype.isAuthError = function (error) {
            return error instanceof AuthenticationError;
        };

        ErrorPresenter.prototype.isUnknownError = function (error) {
            return (
                error instanceof UnknownError || error instanceof RequestError
            );
        };

        return new ErrorPresenter({});
    };
}
