import angular from "angular";
import projectOverviewRouteConfig from "./project_overview.route";
import projectOverviewCtrl from "./project_overview.controller";
import "angular-ui-router";
import "common/intercom/intercomModule";
import "common/services/services";
import ProjectOverviewService from "./project_overview.service";
import sbTimeline from "./components/sbTimeline/sb_timeline.component";

export default angular
    .module("sbApp.projectOverviewModule", [
        "ngMaterial",
        "ui.router",
        "sbApp.services",
        "sbApp.intercom",
        "sbApp.directives",
    ])
    .config(projectOverviewRouteConfig)
    .service("$sbProjectOverview", ProjectOverviewService)
    .controller("ProjectOverviewCtrl", projectOverviewCtrl)
    .component("sbTimeline", sbTimeline);
/* declaration */
