import projectOverviewProTemplateHtml from "./project-overview-pro.html";

export default function ($stateProvider) {
    "ngInject";
    $stateProvider.state("sablono.project.projectOverviewPro", {
        templateUrl: projectOverviewProTemplateHtml,
        controller: "ProjectOverviewProCtrl",
        controllerAs: "projectOverviewPro",
        url: "/project-overview-pro",
        gaPageTrack: "/project-overview-pro", // google analytics extension
        data: {
            title: "SECTION_PROJECT_OVERVIEW_TITLE",
            category: "CONTROL",
        },
        onEnter: function (intercomService, TRACKED_EVENTS) {
            "ngInject";
            intercomService.track(TRACKED_EVENTS.ENTERED_DASHBOARD_PRO_PAGE);
        },
    });
}
