import angular from "angular";
import redirects from "./redirects.route";
import dashboardModule from "./dashboard/dashboard.module";
import dashboardProModule from "./dashboard-pro/dashboard-pro.module";
import projectOverviewModule from "./projectOverview/project_overview.module";
import importModule from "./import/import.module";
import userProfileModule from "./profile/user_profile.module";
import requestAccessModule from "./requestAccess/request_access.module";
import projectSelectionModule from "./projectSelection/project_selection.module";
import wbsModule from "./wbs/wbs.module";
import projectSettingsModule from "./projectSettings/project_settings.module";
import templatesModule from "./templates/templates.module";
import templateEditorModule from "./templateEditor/template_editor.module";
import noteTemplatesModule from "./noteTemplates/noteTemplates.module";
import accountManagementModule from "./accountManagement/account_management.module";
import authModule from "./auth/auth.module";
import exportModule from "./export/export.module";
import toolsModule from "./tools/tools.module";
import mergeModule from "./merge/merge.module";
import visualizationsModule from "./visualizations/visualizations.module";
import deliverablesModule from "./deliverables/deliverables.module";
import projectOverviewProModule from "./project-overview-pro/project-overview-pro.module";
import teamsModule from "./teams/teams.module";
import leanBoardModule from "./leanboard/lean_board.module";
import inspectRequestModule from "./inspectRequest/inspect_request.module";
import issuesModule from "./issues/issues.module";
import inspectDeliverablesModule from "./inspectDeliverables/inspect_deliverables.module";
import timelineModule from "./timeline/timeline.module";
import processViewerModule from "./processViewer/process_viewer.module";
import trackerModule from "./tracker/tracker.module";
import commercialValuationModule from "./commercial-valuation/commercial-valuation.module";
import commercialModule from "./commercial/commercial.module";
import checklistTemplatesModule from "./checklistTemplates/checklist_templates.module";
import siteDiaryModule from "./siteDiary/site_diary.module";
import freemiumLandingModule from "./freemiumLanding/freemium_landing.module";
import pageAccessService from "./page_access.service";
import pageNavigationService from "./page_navigation.service";

const moduleNames = [
    freemiumLandingModule,
    dashboardModule,
    importModule,
    userProfileModule,
    requestAccessModule,
    projectSelectionModule,
    wbsModule,
    projectSettingsModule,
    templatesModule,
    templateEditorModule,
    noteTemplatesModule,
    accountManagementModule,
    exportModule,
    toolsModule,
    mergeModule,
    visualizationsModule,
    deliverablesModule,
    teamsModule,
    leanBoardModule,
    inspectRequestModule,
    issuesModule,
    inspectDeliverablesModule,
    timelineModule,
    processViewerModule,
    commercialModule,
    checklistTemplatesModule,
    siteDiaryModule,
    projectOverviewModule,
    trackerModule,
    authModule,
    commercialValuationModule,
    dashboardProModule,
    projectOverviewProModule,
].map(function (module) {
    return module.name;
});

export default angular
    .module("sbApp.states", ["ngMaterial"].concat(moduleNames))
    .config(redirects)
    .service("$sbPageAccess", pageAccessService)
    .service("$sbPageNavigation", pageNavigationService)
    .config(function ($mdAriaProvider) {
        "ngInject";
        $mdAriaProvider.disableWarnings();
    });
