import angular from "angular";
import _ from "lodash";

export default function ActivitySetConfigurationCtrl(
    $mdDialog,
    $q,
    $rootScope,
    SbTeam,
    $sbActivities,
    $sbErrorPresenter,
    $sbPermission,
    Analytics,
    $sbBulkProcessOperationsService,
    $mdToast,
    $sbProject,
    EVENTS,
    $sbTracking,
    $sbDialog,
    $sbTeam,
    $sbMembership
) {
    "ngInject";
    var vm = this;

    var _selectedActivities = [];

    vm.activities = [];

    vm.modeSelection = modeSelection;
    vm.getDynamicTitle = getDynamicTitle;
    vm.getDynamicSubmitText = getDynamicSubmitText;
    vm.selectActivity = selectActivity;
    vm.onDateChange = onDateChange;
    vm.isMultiSelectionEnabled = isMultiSelectionEnabled;
    vm.isDateStep = isDateStep;
    vm.isDurationStep = isDurationStep;
    vm.isSetTeamStep = isSetTeamStep;
    vm.isUnsetTeamStep = isUnsetTeamStep;
    vm.closeDialog = $mdDialog.cancel;
    vm.submit = submit;
    vm.canSubmit = canSubmit;
    vm.onActivitySelectionChange = onActivitySelectionChange;
    vm.onNewWorkTeamSelected = onNewWorkTeamSelected;
    vm.onNewConfirmingTeamSelected = onNewConfirmingTeamSelected;
    vm.hasSelectedActivities = hasSelectedActivities;

    vm.teamTypeSelect = {
        workTeam: {
            text: "DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_RESPONSIBLE",
            value: "work_team",
        },
        confirmationTeam: {
            text: "DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_CONFIRMATION",
            value: "confirmation_team",
        },
        bothTeams: {
            text: "DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_BOTH",
            value: "bothTeams",
        },
    };
    vm.teamToChange = vm.teamTypeSelect.workTeam.value;

    vm.steps = {
        STEP_1__SELECT_FROM_OPTIONS: 1,
        STEP_2__CONFIGURE_OPTION: 2,
    };
    vm.step = vm.steps.STEP_1__SELECT_FROM_OPTIONS;
    vm.types = {
        BULK_DURATION: {
            icon1: "mdi mdi-arrow-expand-horizontal smaller blue",
            title: "DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_TITLE",
            header: "DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SELECT",
            submit: "DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SUBMIT",
            toast: "ACTION_SET_DURATION_ACTIVITY_SUCCESS",
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .setDuration(numOfActivities);
            },
            isMultiSelectEnabled: true,
            submitAction: _bulkSetDuration,
        },
        BULK_UNSET_DURATION: {
            icon1: "mdi mdi-calendar-remove smaller blue",
            title: "DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_TITLE",
            header: "DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SELECT",
            submit: "DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SUBMIT",
            toast: "ACTION_UNSET_DURATION_ACTIVITY_SUCCESS",
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .unsetDuration(numOfActivities);
            },
            isMultiSelectEnabled: true,
            submitAction: () =>
                $sbBulkProcessOperationsService.bulkUnsetDuration(
                    _selectedActivities,
                    vm.deliverableSelection
                ),
        },
        BULK_TEAM: {
            icon1: "mdi mdi-account-arrow-left smaller blue",
            title: "DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_TITLE",
            header: "DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SELECT",
            submit: "DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SUBMIT",
            toast: "DIALOG_ACTIVITY_SET_SUCCESS_TEXT",
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .setDuration(numOfActivities);
            },
            isMultiSelectEnabled: true,
            submitAction: _bulkSetTeam,
        },
        BULK_UNSET_TEAM: {
            icon1: "mdi mdi-account-remove smaller blue",
            title: "DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_TITLE",
            header: "DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SELECT",
            submit: "DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SUBMIT",
            toast: "DIALOG_ACTIVITY_SET_SUCCESS_TEXT",
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .unsetTeam(numOfActivities);
            },
            isMultiSelectEnabled: true,
            submitAction: _bulkUnsetTeam,
        },
        BULK_DELETE: {
            icon1: "mdi mdi-delete smaller blue",
            title: "DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_TITLE",
            header: "DIALOG_ACTIVITY_SET_OPTION_DELETE_SELECT_ACTIVITIES",
            submit: "DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_SUBMIT",
            toast: "ACTION_DELETE_BULK_ACTIVITY_SUCCESS",
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .notApplicable(numOfActivities);
            },
            isMultiSelectEnabled: true,
            submitAction: function () {
                return $sbBulkProcessOperationsService.bulkActivityDelete(
                    _selectedActivities,
                    vm.deliverableSelection
                );
            },
        },
        BULK_BRING_BACK: {
            icon1: "mdi mdi-undo smaller blue",
            title: "DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_TITLE",
            header: "DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES",
            submit: "DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUBMIT",
            toast: "ACTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUCCESS",
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .bringBackNotApplicable(numOfActivities);
            },
            isMultiSelectEnabled: true,
            submitAction: function () {
                return $sbBulkProcessOperationsService.bulkBringBackNotApplicable(
                    _selectedActivities,
                    vm.deliverableSelection
                );
            },
        },
        START_DATE: {
            image: "/images/dates/dates-milestone.svg",
            title: "DIALOG_ACTIVITY_SET_OPTION_STAGE_START_TITLE",
            header: "DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY",
            submit: "DIALOG_CONFIRM_CREATE",
            toast: "ACTION_SET_ACTIVITY_START_DATES_SUCCESS_N",
            triggersEvent: EVENTS.DELIVERABLES_LIST__TRIGGER_RELOAD,
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .startDate(numOfActivities);
            },
            submitAction: _bulkDateChange,
        },
        DUE_DATE: {
            image: "/images/dates/dates-milestone.svg",
            title: "DIALOG_ACTIVITY_SET_OPTION_STAGE_END_TITLE",
            header: "DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_END_DATE",
            submit: "DIALOG_CONFIRM_CREATE",
            toast: "ACTION_SET_ACTIVITY_DUE_DATES_SUCCESS_N",
            triggersEvent: EVENTS.DELIVERABLES_LIST__TRIGGER_RELOAD,
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .dueDate(numOfActivities);
            },
            submitAction: _bulkDateChange,
        },
        UNSET_DATE: {
            icon1: "mdi mdi-calendar-remove smaller blue",
            title: "DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_TITLE",
            header: "DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_UNSET",
            submit: "DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_SUBMIT",
            toast: "ACTION_UNSET_ACTIVITY_DATES_SUCCESS_N",
            triggersEvent: EVENTS.DELIVERABLES_LIST__TRIGGER_RELOAD,
            isMultiSelectEnabled: true,
            analytics: function (numOfActivities) {
                $sbTracking.deliverablesList.bulk.configure
                    .activities()
                    .unsetDates(numOfActivities);
            },
            submitAction: () =>
                $sbBulkProcessOperationsService.bulkUnsetUserDates(
                    _selectedActivities,
                    vm.deliverableSelection
                ),
        },
        ...(hasFullAccessTeamPermissions() && {
            BULK_RESET_ACTIVITIES: {
                icon1: "mdi mdi-delete-outline smaller blue",
                title: "BULK_RESET_ACTIVITIES_TITLE",
                header: "BULK_RESET_ACTIVITIES_HEADER",
                submit: "BULK_RESET_ACTIVITIES_SUBMIT",
                toast: "BULK_RESET_ACTIVITIES_TOAST",
                triggersEvent: EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
                isMultiSelectEnabled: true,
                analytics: function (numOfActivities) {
                    $sbTracking.deliverablesList.bulk.configure
                        .activities()
                        .bulkResetActivities(numOfActivities);
                },
                submitAction: () =>
                    $sbBulkProcessOperationsService.bulkResetActivities(
                        _selectedActivities,
                        vm.deliverableSelection
                    ),
            },
        }),
    };

    const ACTIVITY_CONF_TYPES = Object.keys(vm.types).reduce((map, key) => {
        map[key] = key;
        return map;
    }, {});

    $onInit();

    function $onInit() {
        // if the project's scheduling mode is edge (Connected scheduling) then the hint text will be showed.
        vm.isInEdgeSchedulingMode = $sbProject
            .getCurrent()
            .isInEdgeSchedulingMode();
        return $q
            .all([
                $sbActivities.getAllActivitiesByDeliverableTemplateID({
                    templateId: vm.templateId,
                }),
                $sbTeam.getTeams($sbProject.getCurrentProjectId()),
            ])
            .then(function ([activities, teams]) {
                vm.activities = activities;
                vm.teams = teams;
            })
            .catch(function (error) {
                $sbErrorPresenter.catch(error);
                $mdDialog.cancel();
            });
    }

    function hasSelectedActivities() {
        return !!_selectedActivities.length;
    }

    function hasFullAccessTeamPermissions() {
        const currentTeam = $sbMembership.currentTeam();
        const isFullAccessTeam = $sbTeam.findProjectTeam([currentTeam]);

        return isFullAccessTeam ? true : false;
    }

    function canSubmit() {
        if (isSetTeamStep()) {
            return (
                hasSelectedActivities() &&
                (vm.newWorkTeam || vm.newConfirmingTeam)
            );
        }

        return hasSelectedActivities();
    }

    function submit() {
        vm.loading = true;

        let totalActivitiesForBulkOperation;

        if (vm.deliverableSelection.selectionProvider.isSelectAllActive()) {
            totalActivitiesForBulkOperation =
                vm.deliverableSelection.size * _selectedActivities.length;
        } else {
            const amountOfSelectedDeliverables =
                vm.deliverableSelection.selectionProvider.selections().length;

            totalActivitiesForBulkOperation =
                amountOfSelectedDeliverables * _selectedActivities.length;
        }

        return _sendSubmitRequest()
            .then(_emitComponentStateChangeEvent)
            .then(_emitDeliverableListReloadEvent)
            .then(_extractNumOfActivitiesFromResponse)
            .then(_trackAction)
            .then((content) => {
                return _hideDialogAndShowSuccessToast(
                    content,
                    totalActivitiesForBulkOperation
                );
            })
            .then($sbProject.refreshCurrent)
            .finally(function () {
                vm.loading = false;
            })
            .catch(function (error) {
                $mdDialog.hide();
                try {
                    if (
                        error.message ===
                        "ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION"
                    ) {
                        $sbTracking.leanBoard.session.concurrentSession(
                            "Tried to change a deliverable that has been changed in another session"
                        );
                        return $sbDialog.openModifiedInConcurrentSessionDialog(
                            error
                        );
                    } else {
                        return $sbErrorPresenter.catch(error);
                    }
                } catch (e) {
                    return $sbErrorPresenter.catch(error);
                }
            });
    }

    function isDateStep() {
        return _isStep(vm.steps.STEP_2__CONFIGURE_OPTION) && _isDateSelection();
    }

    function isDurationStep() {
        return (
            _isStep(vm.steps.STEP_2__CONFIGURE_OPTION) &&
            _isType(ACTIVITY_CONF_TYPES.BULK_DURATION)
        );
    }

    function isSetTeamStep() {
        return (
            _isStep(vm.steps.STEP_2__CONFIGURE_OPTION) &&
            _isType(ACTIVITY_CONF_TYPES.BULK_TEAM)
        );
    }

    function isUnsetTeamStep() {
        return (
            _isStep(vm.steps.STEP_2__CONFIGURE_OPTION) &&
            _isType(ACTIVITY_CONF_TYPES.BULK_UNSET_TEAM)
        );
    }

    function selectActivity(activityData) {
        // Unselect other when it's date change (one selection allowed)
        if (_isDateSelection()) {
            vm.selectedActivity = activityData.selectedActivity;
        }
    }

    function modeSelection(key) {
        if (_isStep(vm.steps.STEP_1__SELECT_FROM_OPTIONS)) {
            vm.step = vm.steps.STEP_2__CONFIGURE_OPTION;
            vm.type = key;

            Analytics.trackEvent(
                "Dialog | Change Dates",
                "Select",
                "Option | " + key
            );

            if (_isType(ACTIVITY_CONF_TYPES.BULK_DURATION)) {
                vm.customDuration = {};
            }

            if (
                _isType(ACTIVITY_CONF_TYPES.BULK_TEAM) ||
                _isType(ACTIVITY_CONF_TYPES.BULK_UNSET_TEAM)
            ) {
                vm.customHeaders = {
                    workTeam: "ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_TEAM",
                    confirmingTeam:
                        "ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_CONFIRMING_TEAM",
                };
            }
        }
    }

    function onDateChange(start, end) {
        if (_isType(ACTIVITY_CONF_TYPES.DUE_DATE)) {
            vm.endDate = end;
        }

        if (_isType(ACTIVITY_CONF_TYPES.START_DATE)) {
            vm.startDate = start;
        }
    }

    function getDynamicSubmitText() {
        return _getBySelectedType("submit");
    }

    function getDynamicTitle() {
        return _getBySelectedType("header");
    }

    function isMultiSelectionEnabled() {
        return _getBySelectedType("isMultiSelectEnabled", false);
    }

    function onActivitySelectionChange(selectedActivities) {
        _selectedActivities = selectedActivities;
    }

    function _emitComponentStateChangeEvent(result) {
        const triggersEvent = _getBySelectedType("triggersEvent");

        if (triggersEvent === EVENTS.COMPONENT_DETAIL__STATE_CHANGED) {
            _selectedActivities.forEach((activity) => {
                $rootScope.$emit(
                    EVENTS.COMPONENT_DETAIL__STATE_CHANGED,
                    activity.id,
                    activity.state.current
                );
            });
        }

        return result;
    }

    function _emitDeliverableListReloadEvent(result) {
        // after bulk date change - update deliverables list
        const triggersEvent = _getBySelectedType("triggersEvent");

        if (triggersEvent) {
            $rootScope.$emit(EVENTS.DELIVERABLES_LIST__TRIGGER_RELOAD);
        }

        return result;
    }

    function _extractNumOfActivitiesFromResponse(result) {
        return _.get(result, "length", 0);
    }

    function _sendSubmitRequest() {
        var submitFn = _getSubmitAction();
        return submitFn();
    }

    function _getSubmitAction() {
        return _getBySelectedType("submitAction", function () {
            return $q.reject(
                new Error("No submit request available for chosen action.")
            );
        });
    }

    function _isDateSelection() {
        return (
            _isType(ACTIVITY_CONF_TYPES.DUE_DATE) ||
            _isType(ACTIVITY_CONF_TYPES.START_DATE)
        );
    }

    function _isType(type) {
        return vm.type === type;
    }

    function _isStep(step) {
        return vm.step === step;
    }

    function _getBySelectedType(property, defaultTo) {
        return _.get(vm.types, vm.type + "." + property, defaultTo);
    }

    function _bulkDateChange() {
        return $sbBulkProcessOperationsService.bulkUserDates(
            _selectedActivities,
            vm.deliverableSelection,
            {
                startDate: vm.startDate ? vm.startDate : undefined,
                endDate: vm.endDate ? vm.endDate : undefined,
            }
        );
    }

    function _bulkSetDuration() {
        return $sbBulkProcessOperationsService.bulkDurationUpdate(
            _selectedActivities,
            vm.deliverableSelection,
            {
                value: vm.customDuration.plannedDuration,
                unit: vm.customDuration.plannedDurationUnit,
            }
        );
    }

    function _bulkSetTeam() {
        return $sbBulkProcessOperationsService.bulkSetTeam(
            _selectedActivities,
            vm.deliverableSelection,
            {
                workTeamId: vm.newWorkTeam ? vm.newWorkTeam.id : undefined,
                confirmingTeamId: vm.newConfirmingTeam
                    ? vm.newConfirmingTeam.id
                    : undefined,
            }
        );
    }

    function _bulkUnsetTeam() {
        return $sbBulkProcessOperationsService.bulkUnsetTeam(
            _selectedActivities,
            vm.deliverableSelection,
            vm.teamToChange
        );
    }

    function _trackAction(numOfActivities) {
        var fnTrack = _getBySelectedType("analytics", angular.noop);
        fnTrack(numOfActivities);

        return numOfActivities;
    }

    function _hideDialogAndShowSuccessToast(
        numOfActivities,
        totalActivitiesForBulkOperation
    ) {
        $mdDialog.hide();

        if (numOfActivities > 0) {
            return _showToast({
                activities: numOfActivities,
                totalActivitiesForBulkOperation,
            });
        }
    }

    function _showToast(content) {
        const activities = content.activities;
        const totalActivitiesForBulkOperation =
            content.totalActivitiesForBulkOperation;

        return $mdToast.show(
            $mdToast
                .simple()
                .content(_getBySelectedType("toast"))
                .contentValues({
                    activities,
                    totalActivitiesForBulkOperation,
                })
                .hideDelay(5000)
                .position("top right")
        );
    }

    function onNewWorkTeamSelected(team) {
        vm.newWorkTeam = team;
    }

    function onNewConfirmingTeamSelected(team) {
        vm.newConfirmingTeam = team;
    }
}
