import angular from "angular";
import { isNumber } from "lodash";

export default function issueService(
    SbNote,
    $sbReportingNotesApi,
    $sbActivityJobsApi,
    $sbCurrentProject,
    $q,
    $sbErrorHandler,
    $sbDomain,
    $rootScope,
    $sbNotesApi,
    EVENTS
) {
    "ngInject";
    // API
    //
    return {
        getIssueCount: getIssueCount,
        createIssuesSummary: createIssuesSummary,
        createIssue: createIssue,
        updateState: updateState,
        createNoteAndAssignTo: createNoteAndAssignTo,
        mapApiToNoteState: mapApiToNoteState,
        mapNoteStateToApi: mapNoteStateToApi,
    };

    function getIssueCount() {
        return $sbReportingNotesApi
            .getCollection($sbCurrentProject.pick("id"), {
                select: "ISSUE_ID",
                top: 1,
            })
            .then(({ meta }) => meta.count_all);
    }

    /**
     * createIssuesSummary - Creates an object that contains the statistics of the issues from the given issueList
     *
     * @param  {Array} issueList - An array containing
     * @return {type}           description
     */
    function createIssuesSummary(issueList) {
        const startSummary = {
            claims: {
                open: 0,
                closed: 0,
                total: 0,
            },
            obstructions: {
                open: 0,
                closed: 0,
                total: 0,
            },
            infos: {
                total: 0,
            },
            total: 0,
        };

        return issueList.reduce(function (summary, issue) {
            if (issue.ISSUE_TYPE === SbNote.TYPE_INFO_ENUM) {
                summary.infos.total += 1;

                return summary;
            }

            if (
                SbNote.isFinished({
                    state: issue.ISSUE_STATUS,
                    type: issue.ISSUE_TYPE,
                })
            ) {
                if (issue.ISSUE_TYPE === SbNote.TYPE_QUALITY_ISSUE_ENUM) {
                    summary.claims.closed += 1;
                } else if (issue.ISSUE_TYPE === SbNote.TYPE_OBSTRUCTION_ENUM) {
                    summary.obstructions.closed += 1;
                }
            } else {
                if (issue.ISSUE_TYPE === SbNote.TYPE_QUALITY_ISSUE_ENUM) {
                    summary.claims.open += 1;
                } else if (issue.ISSUE_TYPE === SbNote.TYPE_OBSTRUCTION_ENUM) {
                    summary.obstructions.open += 1;
                }
            }

            summary.open = summary.claims.open + summary.obstructions.open;

            summary.closed =
                summary.claims.closed + summary.obstructions.closed;

            summary.total = summary.open + summary.closed;

            summary.obstructions.total =
                summary.obstructions.open + summary.obstructions.closed;

            summary.claims.total = summary.claims.open + summary.claims.closed;

            return summary;
        }, startSummary);
    }

    function buildNoteCreationPayload(note) {
        const data = {
            type: note.type,
            text: note.text,
            image_ids: note.getImages().map((image) => image.id),
            attachment_ids: note
                .getAttachments()
                .map((attachment) => attachment.id),
        };

        const activity = note.getAssignedComponent();
        if (activity && activity.id) {
            data.activity_id = activity.id;
        }

        const assigned_team_id = note.findResponsibleTeamId();
        if (isNumber(assigned_team_id)) {
            data.assigned_team_id = assigned_team_id;
        }

        const confirmation_team_id = note.findConfirmationTeamId();
        if (isNumber(confirmation_team_id)) {
            data.confirmation_team_id = confirmation_team_id;
        }

        return data;
    }

    /**
     * createIssue - Send a POST request to create a new issue
     *
     * @param {Note} note
     *
     * @return {Promise}
     */
    function createIssue(note) {
        _checkNoteValidity(note);
        const data = buildNoteCreationPayload(note);
        return $sbNotesApi
            .create($sbCurrentProject.pick("id"), data)
            .then(function (serverNote) {
                var newSbNote = _createNoteFromServerNote(serverNote);
                _emitNewNoteEvent(newSbNote, note);
                return serverNote;
            })
            .catch(function (error) {
                return $sbErrorHandler.rethrowAsInterpretableDomainError(error);
            });
    }

    /**
     * createNoteAndAssignTo - Send a POST request to bulk create issues
     *
     *   @param {Note} note
     *
     *   @param {Object} components
     *   @param {string[]} components.deliverableSet
     *   @param {string[]} components.activityTemplateIds
     *
     * @returns {Promise}
     */
    function createNoteAndAssignTo(
        note,
        { deliverableSet, activityTemplateIds }
    ) {
        _checkNoteValidity(note);
        const data = buildNoteCreationPayload(note);
        data.attachment_ids = undefined; // Not supported for bulk

        return bulkCreateNotes(data, {
            deliverables: deliverableSet,
            activityTemplates: activityTemplateIds,
        }).catch(function (error) {
            return $sbErrorHandler.rethrowAsInterpretableDomainError(error);
        });
    }

    function bulkCreateNotes(note, { deliverables, activityTemplates }) {
        return $sbActivityJobsApi.bulkCreateNotes(
            $sbCurrentProject.pick("id"),
            {
                note,
                deliverable_set: deliverables,
                activity_templates: activityTemplates,
            }
        );
    }

    function _checkNoteValidity(note) {
        if (!note.type || !note.text) {
            throw Error("Parameter is missing");
        }
        if (!angular.isString(note.type)) {
            throw Error("issue type is not a string");
        }
        if (!angular.isString(note.text)) {
            throw Error("issue text is not a string");
        }
    }

    function _createNoteFromServerNote(serverNote) {
        return $sbDomain.noteFactory.create({
            ISSUE_ID: serverNote.id,
            ISSUE_TEXT: serverNote.text,
            ISSUE_TYPE: serverNote.type,
            ISSUE_TIME_CREATED: serverNote.reported.at,
            ROOT_COMPONENT_ID: serverNote.deliverable_id,
            REF_COMPONENT_ID:
                serverNote.activity_id || serverNote.deliverable_id,
        });
    }

    /**
     * Map to old interface.
     *
     * @param {Object} note
     * @param {string} note.id
     * @param {string} note.text
     * @param {string} note.type
     * @param {string} note.created_at
     * @param {string} note.activity_id
     * @private
     */
    function _createNoteFromBatchServerNote(note) {
        return $sbDomain.noteFactory.create({
            ISSUE_TEXT: note.text,
            ISSUE_ID: note.id,
            ISSUE_TIME_CREATED: note.created_at,
            REF_COMPONENT_ID: note.activity_id,
            ISSUE_TYPE: note.type,
        });
    }

    function _emitNewNoteEvent(newSbNote, note) {
        // add team assignment and image references to the new note
        // because they are not by default in the response.
        newSbNote.setResponsibleTeam(note.getResponsibleTeam());
        newSbNote.images = note.images;

        // inform the world about a new issues has been born
        $rootScope.$emit(
            EVENTS.NOTE_CHANGED,
            new EVENTS.SbChangeEvent(null, newSbNote),
            newSbNote.ROOT_COMPONENT_ID
        );
    }

    /**
     * Send an update request to server of the state [note]
     */
    function updateState(note) {
        _validateNoteIsIssueType(note);

        return $sbNotesApi.updateState($sbCurrentProject.pick("id"), note.id, {
            state: mapNoteStateToApi(note.state),
        });
    }

    function _validateNoteIsIssueType(note) {
        if (!(note instanceof SbNote)) {
            throw new TypeError(
                'IllegalArgumentException: The argument is not of type "Note".'
            );
        }

        if (!note.isIssue()) {
            throw new Error(
                'ValueError: Inappropriate value for "type" in note. Only "OBSTRUCTION" or "CLAIM" are allowed for this operation.'
            );
        }
    }

    /**
     * Maps the [state] which comes within a note from server to the one of [Note.STATE_*_ENUM] flags
     */
    function mapApiToNoteState(state) {
        switch (state.name) {
            case "A":
            case "open":
                return SbNote.STATE_OPEN_ENUM;

            case "D":
            case "closed":
                return SbNote.STATE_CLOSED_ENUM;

            case "waiting_for_confirmation":
                return SbNote.STATE_WAITING_FOR_CONFIRMATION_ENUM;

            case "confirmed":
                return SbNote.STATE_CONFIRMED_ENUM;

            case "rejected":
                return SbNote.STATE_REJECTED_ENUM;

            default:
                throw new Error(`Unexpected note state ${state}`);
        }
    }

    /**
     * Maps the [state] which is one of [Note.STATE_*_ENUM] to server state name
     */
    function mapNoteStateToApi(state) {
        switch (state) {
            case SbNote.STATE_OPEN_ENUM:
                return "open";
            case SbNote.STATE_CLOSED_ENUM:
                return "closed";
            case SbNote.STATE_WAITING_FOR_CONFIRMATION_ENUM:
                return "waiting_for_confirmation";
            case SbNote.STATE_CONFIRMED_ENUM:
                return "confirmed";
            case SbNote.STATE_REJECTED_ENUM:
                return "rejected";
            default:
                throw new Error(`Unexpected note state ${state}`);
        }
    }
}
