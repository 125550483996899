export default function FileInputCtrl($scope, $element, $sbFileReader) {
    "ngInject";
    var vm = this;

    var fileInput = $element[0].querySelector('input[type="file"]');
    var lastEvent;

    // listen to an open event to enable other places then the one button in the directive
    // to open the file chooser.
    // Pay attention that multiple file chooser will conflict on the event!
    $scope.$on("sbFileInput.open", function (event) {
        vm.openFileChooser(event);
    });

    function fileChanged(file) {
        var name = file.name;

        //TODO import and use a model or util method to extract the filename extension
        var extension = "." + name.split(".").pop();

        if (
            !$sbFileReader.hasAllowedFileExtension(
                extension,
                vm.allowedExtensions
            )
        ) {
            vm.sbWrongExtension({
                $event: lastEvent,
                file: file,
                name: name,
                extension: extension,
            });
            return false;
        }

        vm.sbFileChanged({
            $event: lastEvent,
            file: file,
            name: name,
            extension: extension,
        });

        return true;
    }

    vm.openFileChooser = function ($event) {
        lastEvent = $event;
        // added this to avoid the need of a refresh button and improve UX
        // now if you reselect the same file, it will refresh.
        fileInput.value = null;
        fileInput.click();
    };

    vm.onFileInputChanged = function (event) {
        vm.target = undefined;

        //no files
        if (
            !event ||
            !event.target ||
            !event.target.files ||
            event.target.files.length <= 0
        ) {
            return;
        }
        var target = event.target;

        const isAdded = Array.from(event.target.files).every((file) => {
            return fileChanged(file, event);
        });
        if (isAdded) {
            vm.target = target;
        }
    };

    vm.isDisabled = function isDisabled() {
        // if value is not present set enabled
        //
        if (angular.isUndefined(vm.options)) {
            return false;
        } else {
            return !!vm.options;
        }
    };
}
